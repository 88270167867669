
class URLUtils {
    static getParamsMap(search: string): Map<string, string> {
        const urlParams = new URLSearchParams(search);

        const paramsMap = new Map<string, string>();
        urlParams.forEach((value, key) => {
            paramsMap.set(key, value);
         });

        return paramsMap;
    }
}

export default URLUtils;