import {PostData} from "@domain/models/post/PostResponseV1";
import {useCallback, useState} from "react";
import {GetCurrentLocationUseCase} from "@domain/usecases/search/GetCurrentLocationUseCase";
import {LocationDomain} from "@domain/models/search/SugestionType";


export interface CurrentLocationViewStateViewState {
    locationDomain?: LocationDomain
    isLoading?: boolean;
    error?: string | null;
}

export const useGetCurrentLocationViewModel = (getCurrentLocationUseCase: GetCurrentLocationUseCase) => {
    const [stateGetCurrentLocation, setStateGetCurrentLocation] = useState<CurrentLocationViewStateViewState>({isLoading: false});

    const getCurrentLocation = useCallback(() => {
        setStateGetCurrentLocation({isLoading: true});

        setStateGetCurrentLocation({locationDomain :getCurrentLocationUseCase.execute(),isLoading: false, error: null});

    }, [getCurrentLocationUseCase]);

    return {
        stateGetCurrentLocation,
        getCurrentLocation
    };
};