import R from "@strings/R";

var ONE_DAY = 60 * 60 * 1000 * 24;
var ONE_HOUR = 60 * 60 * 1000;
var ONE_MINUTE = 60 * 1000;


export class TimeUtils {



    /**
     * Function wich given a time in ms returns pretty time following next rules
     *  timeInMs < 30 min -> just Now 
     *  timeInMs >= 30 min and <= 60 min  -> x minutes ago
     *  timeInMs >= 60 min and <= 24 hours-> x hours ago
     *  timeInMs > 24 hours -> x days ago
     * 
     * @param timeInMs 
     * @return tuple [amount, pretty dic_key as string ]
     * **/

    static getPrettyTime(timeInMs: number): [number, string] {
        var currentTimeInMilliseconds=Date.now(); 
        var timeDiff = currentTimeInMilliseconds - timeInMs
        if (  timeDiff > ONE_DAY) {
            return [Math.round(timeDiff/ONE_DAY) , R.cardView_publishTime_daysAgo]
        } else if (timeDiff >= ONE_HOUR) {
            return [Math.round(timeDiff/ONE_HOUR) , R.cardView_publishTime_hourAgo]
        } else if (timeDiff >= 30 * ONE_MINUTE) {
            return [Math.round(timeDiff/ONE_MINUTE) ,  R.cardView_publishTime_minutesAgo]
        }
        return [-1, R.cardView_publishTime_justNow]
    }

    /**
     * Function that convert a date in a givent format GMT to ms
     * @param dateAsString ex: 2022-08-21T16:07:00.000000Z
     * @return ms 
     */

    static convertDataToMs(dateAsString: string| undefined): number {
        if(dateAsString == undefined){
            return  1
        }
         var result = new Date(dateAsString)
        return result.getTime()
    }

    static convertTimeToPrettyTime(timeInGmt: string|undefined){
 
       let a = new Date(timeInGmt!!).toLocaleDateString('en-EN', { year: 'numeric', month: 'long', day: 'numeric'})
 
       console.log("[prettytime] " + a)

       return a
    }

}