import SearchRepository from "@domain/repository/search/ISearchRepository";
import {LocationDomain} from "@domain/models/search/SugestionType";

export class GetCurrentLocationUseCase {

    private searchRepository: SearchRepository

    public constructor(searchRepository: SearchRepository) {
        this.searchRepository = searchRepository
    }

    execute(): LocationDomain | undefined {
      return  this.searchRepository.getCurrentLocation();
    }
}