import { PostData } from "@domain/models/post/PostResponseV1";
import { useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./Map.scss";
import * as React from "react";

interface MapProps {
  showMap: boolean;
  callback(show: boolean): void;
  post: PostData;
}

export const Map: React.FC<MapProps> = ({
  showMap,
  post,
  callback,
}: MapProps): JSX.Element => {
  const mapRef = useRef(null);

  useEffect(() => {
    if (!showMap || !mapRef.current) {
      return;
    }

    if (
      typeof google !== "undefined" &&
      typeof google.maps !== "undefined" &&
      mapRef.current
    ) {
      const map = new google.maps.Map(mapRef.current, {
        center: {
          lat: post?.place?.lat as number,
          lng: post?.place?.lon as number,
        },
        zoom: 11,
      });

      const marker = new google.maps.Marker({
        position: {lat: post?.place?.lat as number, lng: post?.place?.lon as number},
        map: map,
        title: post?.place?.address
      });

    } else {
      console.error("Google Maps library not found or not available");
    }
  }, [showMap, post]);

  return (
    <Modal
      show={showMap}
      onHide={() => {
        callback(false);
      }}
      style={{ left: "0" }}
    >
      <Modal.Header closeButton />
      <Modal.Body>
          <div ref={mapRef} className="modal-map"/>
      </Modal.Body>
    </Modal>
  );
};
