import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import CostInput from "@presentation/views/components/CostInput/CostInput";
import RadioListInput from "@presentation/views/components/RadioListInput/RadioListInput";
import CheckListInput from "@presentation/views/CheckListInput/CheckListInput";
import getCurrencyConfig from "@utils/remote_config/CurrencyConfig";

export interface Attribute {
    name: string;
    search_key: string;
    name_dict_key: string;
    type: string;
    mandatory: boolean;
    value_type: string;
    type_for_search?: string;
    values?: Array<{
        qualifier?: string;
        value: any;
        name_dict_key: string;
    }>;
}

export interface JsonData {
    catalog: Array<{
        id: string;
        name: string;
        name_dict_key: string;
        attributes: Attribute[];
    }>;
}

const jsonData: JsonData = {
    catalog: [
        {
            id: "CAT-1",
            name: "Real estate",
            name_dict_key: "category_name_real_estate",
            attributes: [
                {
                    name: "Price attribute",
                    search_key: "sk_price_key",
                    name_dict_key: "catalog_attribute_name_price",
                    type: "COST",
                    mandatory: true,
                    value_type: "number",
                },
                {
                    name: "Real estate property type",
                    search_key: "sk_real_estate_type",
                    name_dict_key: "catalog_attribute_name_real_estate_type",
                    type: "RADIO_LIST",
                    type_for_search: "CHECKED_LIST",
                    values: [
                        {
                            value: "RET_STUDIO",
                            name_dict_key:
                                "catalog_attribute_name_value_studio",
                        },
                        {
                            value: "RET_APARTMENT",
                            name_dict_key:
                                "catalog_attribute_name_value_apartment",
                        },
                        {
                            value: "RET_VILLA",
                            name_dict_key: "catalog_attribute_name_value_villa",
                        },
                        {
                            value: "RET_TOWNHOUSE",
                            name_dict_key:
                                "catalog_attribute_name_value_townhouse",
                        },
                        {
                            value: "RET_HOTEL",
                            name_dict_key: "catalog_attribute_name_value_hotel",
                        },
                        {
                            value: "RET_GUEST_HOUSE",
                            name_dict_key:
                                "catalog_attribute_name_value_guesthouse",
                        },
                        {
                            value: "RET_DETACHED_HOUSE",
                            name_dict_key:
                                "catalog_attribute_name_value_detached_house",
                        },
                    ],
                    mandatory: true,
                    value_type: "text",
                },
                {
                    name: "Room amount",
                    search_key: "sk_room_amount",
                    name_dict_key: "catalog_attribute_name_room_amount",
                    type: "RADIO_LIST",
                    type_for_search: "RADIO_LIST",
                    values: [
                        {
                            value: 1,
                            qualifier: "eq",
                            name_dict_key: "attribute_value_generic_amount_1",
                        },
                        {
                            value: 2,
                            qualifier: "eq",
                            name_dict_key: "attribute_value_generic_amount_2",
                        },
                        {
                            value: 3,
                            qualifier: "eq",
                            name_dict_key: "attribute_value_generic_amount_3",
                        },
                        {
                            value: 4,
                            qualifier: "eq",
                            name_dict_key: "attribute_value_generic_amount_4",
                        },
                        {
                            value: 5,
                            qualifier: "gte",
                            name_dict_key:
                                "attribute_value_generic_amount_more_than_4",
                        },
                    ],
                    mandatory: true,
                    value_type: "number",
                },
                {
                    name: "Bedrooms's number",
                    search_key: "sk_bedrooms",
                    name_dict_key: "catalog_attribute_name_bedrooms_amount",
                    type: "RADIO_LIST",
                    type_for_search: "RADIO_LIST",
                    values: [
                        {
                            value: 1,
                            qualifier: "eq",
                            name_dict_key: "attribute_value_generic_amount_1",
                        },
                        {
                            value: 2,
                            qualifier: "eq",
                            name_dict_key: "attribute_value_generic_amount_2",
                        },
                        {
                            value: 3,
                            qualifier: "eq",
                            name_dict_key: "attribute_value_generic_amount_3",
                        },
                        {
                            value: 4,
                            qualifier: "eq",
                            name_dict_key: "attribute_value_generic_amount_4",
                        },
                        {
                            value: 5,
                            qualifier: "gte",
                            name_dict_key:
                                "attribute_value_generic_amount_more_than_4",
                        },
                    ],
                    mandatory: true,
                    value_type: "number",
                },
                {
                    name: "Living space in square meteres",
                    search_key: "sk_living_space",
                    name_dict_key: "catalog_attribute_name_living_space",
                    type: "RADIO_LIST",
                    type_for_search: "RADIO_LIST",
                    values: [
                        {
                            value: 20,
                            qualifier: "gte",
                            name_dict_key:
                                "attribute_value_generic_amount_gte20",
                        },
                        {
                            value: 30,
                            qualifier: "gte",
                            name_dict_key:
                                "attribute_value_generic_amount_gte30",
                        },
                        {
                            value: 40,
                            qualifier: "gte",
                            name_dict_key:
                                "attribute_value_generic_amount_gte40",
                        },
                        {
                            value: 50,
                            qualifier: "gte",
                            name_dict_key:
                                "attribute_value_generic_amount_gte50",
                        },
                        {
                            value: 60,
                            qualifier: "gte",
                            name_dict_key:
                                "attribute_value_generic_amount_gte60",
                        },
                    ],
                    mandatory: false,
                    value_type: "number",
                },
                {
                    name: "Type of place",
                    search_key: "sk_flats_place_type",
                    name_dict_key: "catalog_attribute_name_type_of_place",
                    type: "RADIO_LIST",
                    type_for_search: "CHECKED_LIST",
                    values: [
                        {
                            value: "TOP_ENTIRE",
                            name_dict_key:
                                "catalog_attribute_name_type_of_place_value_entire_place",
                        },
                        {
                            value: "TOP_PRIVATE_ROOM",
                            name_dict_key:
                                "catalog_attribute_name_type_of_place_value_private_room",
                        },
                        {
                            value: "HOTEL_ROOM",
                            name_dict_key:
                                "catalog_attribute_name_type_of_place_value_hotel_room",
                        },
                        {
                            value: "SHARED_ROOM",
                            name_dict_key:
                                "catalog_attribute_name_type_of_place_value_shared_room",
                        },
                    ],
                    mandatory: true,
                    value_type: "text",
                },
                {
                    name: "Bed's number",
                    search_key: "sk_beds",
                    name_dict_key: "catalog_attribute_name_beds_amount",
                    type: "RADIO_LIST",
                    type_for_search: "RADIO_LIST",
                    values: [
                        {
                            value: 1,
                            qualifier: "eq",
                            name_dict_key: "attribute_value_generic_amount_1",
                        },
                        {
                            value: 2,
                            qualifier: "eq",
                            name_dict_key: "attribute_value_generic_amount_2",
                        },
                        {
                            value: 3,
                            qualifier: "eq",
                            name_dict_key: "attribute_value_generic_amount_3",
                        },
                        {
                            value: 4,
                            qualifier: "eq",
                            name_dict_key: "attribute_value_generic_amount_4",
                        },
                        {
                            value: 5,
                            qualifier: "gte",
                            name_dict_key:
                                "attribute_value_generic_amount_more_than_4",
                        },
                    ],
                    mandatory: true,
                    value_type: "number",
                },
                {
                    name: "Bathrooms amount",
                    search_key: "sk_bathrooms",
                    name_dict_key: "catalog_attribute_name_bathrooms_amount",
                    type: "RADIO_LIST",
                    type_for_search: "RADIO_LIST",
                    values: [
                        {
                            value: 1,
                            qualifier: "eq",
                            name_dict_key: "attribute_value_generic_amount_1",
                        },
                        {
                            value: 2,
                            qualifier: "eq",
                            name_dict_key: "attribute_value_generic_amount_2",
                        },
                        {
                            value: 3,
                            qualifier: "eq",
                            name_dict_key: "attribute_value_generic_amount_3",
                        },
                        {
                            value: 4,
                            qualifier: "eq",
                            name_dict_key: "attribute_value_generic_amount_4",
                        },
                        {
                            value: 5,
                            qualifier: "gte",
                            name_dict_key:
                                "attribute_value_generic_amount_more_than_4",
                        },
                    ],
                    mandatory: false,
                    value_type: "number",
                },
                {
                    name: "Amenities",
                    search_key: "sk_catalog_amenities_type",
                    name_dict_key: "catalog_amenities_type",
                    type: "CHECKED_LIST",
                    type_for_search: "CHECKED_LIST",
                    values: [
                        {
                            value: "am_wifi",
                            name_dict_key:
                                "catalog_attribute_name_wifi_allowance",
                        },
                        {
                            value: "am_kitchen",
                            name_dict_key: "catalog_attribute_name_kitchen",
                        },
                        {
                            value: "am_air_conditioner",
                            name_dict_key:
                                "catalog_attribute_name_air_conditioning",
                        },
                        {
                            value: "am_free_washer",
                            name_dict_key: "catalog_attribute_name_washer",
                        },
                        {
                            value: "am_free_parking",
                            name_dict_key:
                                "catalog_attribute_name_free_parking",
                        },
                        {
                            value: "am_pool",
                            name_dict_key: "catalog_attribute_name_pool",
                        },
                        {
                            value: "am_smoking",
                            name_dict_key:
                                "catalog_attribute_name_smoking_allowed",
                        },
                    ],
                    mandatory: false,
                    value_type: "text",
                },
            ],
        },
    ],
};

enum InputTypes {
    COST = "COST",
    RADIO_LIST = "RADIO_LIST",
    CHECKED_LIST = "CHECKED_LIST",
}

interface DynamicInputProps {
    attribute: Attribute;
    formData: Record<string, any>;
    currency: Record<string, number>;
    onInputChange: (attribute: string, value: any) => void;
    onValidation: (fail: boolean) => void;
}

function DynamicInput({
    attribute,
    formData,
    currency,
    onInputChange,
    onValidation,
}: DynamicInputProps) {
    const validateCostInput = (value: string) => {
        const trimmedValue = value.trim();
        // Your validation logic here
        if (trimmedValue === "" && attribute.mandatory) {
            onValidation(true);
            return {
                isValid: false,
                errorMessage: "The price must not be empty",
            };
        }

        if (+trimmedValue < currency.minValue) {
            onValidation(true);
            return {
                isValid: false,
                errorMessage: `The price must not be below ${currency.minValue}`,
            };
        }

        if (+trimmedValue > currency.maxValue) {
            onValidation(true);
            return {
                isValid: false,
                errorMessage: `The price must not be above ${currency.maxValue}`,
            };
        }

        onValidation(false);
        return { isValid: true, errorMessage: "" };
    };

    switch (attribute.type) {
        case InputTypes.COST:
            return (
                <CostInput
                    validateInput={validateCostInput}
                    attribute={attribute}
                    minValue={currency.minValue}
                    maxValue={currency.maxValue}
                    formData={formData}
                    onInputChange={onInputChange}
                />
            );
        case InputTypes.RADIO_LIST:
            return (
                <RadioListInput
                    attribute={attribute}
                    formData={formData}
                    onInputChange={onInputChange}
                />
            );
        case InputTypes.CHECKED_LIST:
            return (
                <CheckListInput
                    attribute={attribute}
                    formData={formData}
                    onInputChange={onInputChange}
                />
            );
        default:
            return null;
    }
}

function DynamicForm({
    onFormChange,
    onValidation,
}: {
    onFormChange: (json: string, priceKey: string) => void;
    onValidation: (failed: boolean) => void;
}) {
    const [formData, setFormData] = useState<Record<string, any>>({});
    const [currency, setCurrency] = useState<Record<string, number>>({
        minValue: 0,
        maxValue: 0,
    });

    useEffect(() => {
        const currencyConfig = getCurrencyConfig();
        if (currencyConfig) {
            setCurrency(currencyConfig);
        }
    }, []);

    const handleInputChange = (fieldName: string, value: any) => {
        setFormData({
            ...formData,
            [fieldName]: value,
        });
    };

    const json = jsonData.catalog[0].attributes
        .filter((attribute) => formData.hasOwnProperty(attribute.search_key))
        .map((attribute) => ({
            name: attribute.name,
            name_dict_key: attribute.name_dict_key,
            search_key: attribute.search_key,
            type: attribute.type,
            value_type: attribute.value_type,
            values: formData[attribute.search_key],
            qualifier: formData[attribute.search_key].qualifier,
        }));

    onFormChange(
        JSON.stringify(json),
        formData.hasOwnProperty("price_period")
            ? formData["price_period"]
            : "year"
    );

    return (
        <div className="mt-4">
            <Row>
                {jsonData.catalog[0].attributes.map((attribute) => (
                    <Col md={6} key={attribute.search_key}>
                        <DynamicInput
                            onValidation={onValidation}
                            key={attribute.search_key}
                            attribute={attribute}
                            currency={currency}
                            formData={formData}
                            onInputChange={handleInputChange}
                        />
                    </Col>
                ))}

                {/*{*/}
                {/*    jsonData.catalog[0].attributes.map((attribute) => {*/}
                {/*            return <div>*/}
                {/*                {*/}
                {/*                    JSON.stringify({*/}
                {/*                        name: attribute.name,*/}
                {/*                        name_dict_key: attribute.name_dict_key,*/}
                {/*                        search_key: attribute.search_key,*/}
                {/*                        type: attribute.type,*/}
                {/*                        value_type: attribute.value_type,*/}
                {/*                        values: formData[attribute.search_key],*/}
                {/*                        qualifier: formData[attribute.search_key]?.qualifier*/}
                {/*                    })}*/}
                {/*            </div>*/}
                {/*        })*/}

                {/*}*/}
            </Row>
        </div>
    );
}

export default DynamicForm;
