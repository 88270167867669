import "./EmptySearchResult.scss"
import React from "react";
import heart from "@assets/ic_heart_.svg";
import "./EmptySearchResult.scss"
import {useTranslation} from "react-i18next";

export interface EmptySearchResultProps {
    message: string
}
const EmptySearchResult: React.FC<EmptySearchResultProps> = ({message} ) => {
    return (
        <div className='empty-search-result  main'>
            <img className="empty-search-result  heart" src={heart} alt="empty_icon"/>
            <p className="empty-search-result title">{message}</p>
        </div>
    );
};


export default EmptySearchResult;