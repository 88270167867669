import IPostRepository from "@domain/repository/post/IPostRepository"
import {PostData} from "@domain/models/post/PostResponseV1";
import {PostCreateV1} from "@domain/models/post/PostCreateV1";

export default class CreatePostUseCase {

    private postRepository: IPostRepository

    public constructor(postRepository: IPostRepository) {
        this.postRepository = postRepository
    }


    /**
     * Delete a post
     * @param postCreateV1 post data.
     * @throws {Error} in case of getting non 2xx response or empty query
     */
    public async createPost(postCreateV1: PostCreateV1): Promise<number> {
        return new Promise(async (resolve, reject) => {
            await this.postRepository.createPost(postCreateV1).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    }
}