import {
    AUTH_TOKEN_API,
    USER_ACCOUNT_KEY,
    USER_INFO_API_ID
} from "@domain/models/startup/ServiceId";
import {Startup} from "@domain/models/startup/Startup";
import {UserV1} from "@domain/models/user/UserV1";
import IUserRepository from "@domain/repository/user/IUserRepository";
import {UserToken} from "@domain/models/user/UserToken";
import {TOKEN_STORE_KEY} from "@utils/Constants";
import {ApiUtilsUtils} from "@utils/ApitUtils";
import api from "@data/Api";

export default class UserApi implements IUserRepository {

    private startupConfig: Startup | undefined


    constructor(startupConfig: Startup | undefined) {
        this.startupConfig = startupConfig
    }

    getUserToken(firebaseTokenId: string): Promise<UserToken> {
        const url = ApiUtilsUtils.getServiceEndPointByServiceId(this.startupConfig, AUTH_TOKEN_API);

        return api.post(url!!, {
            token: firebaseTokenId
        }).then(response => {
            console.log("[authenticateToken] response success");
            return response.data; // Axios automatically parses JSON and puts it in response.data
        })
            .catch(error => {
                console.error("[authenticateToken] error in response", error);
                throw new Error(error.message); // Pass the error message
            });
    }


    getOtherUserAccount(userId: number): Promise<UserV1> {
        let url = ApiUtilsUtils.getServiceEndPointByServiceId(this.startupConfig, USER_ACCOUNT_KEY)!! + '/' + +userId

        return api.get(url)
            .then(response => {
                console.log("[getUserAccount] response success");
                return response.data; // Axios automatically parses JSON and puts it in response.data
            })
            .catch(error => {
                console.error("[getUserAccount] error in response", error);
                throw new Error(error.message); // Pass the error message
            });
    }

    getMyUserAccount(): Promise<UserV1> {
        const url = ApiUtilsUtils.getServiceEndPointByServiceId(this.startupConfig, USER_INFO_API_ID);
        const token = localStorage.getItem(TOKEN_STORE_KEY) ?? '';

        return api.get(url!!, {
            headers: {
                'Content-Type': 'application/json'}
        })
            .then(response => {
                console.log("[getUserInfo] response success");
                return response.data; // Axios automatically parses JSON and puts it in response.data
            })
            .catch(error => {
                console.error("[getUserInfo] error in response", error);
                throw new Error(error.message); // Pass the error message
            });

    }

}