import React from "react";
import {PostData} from "@domain/models/post/PostResponseV1";
import CatalogUtils from "@utils/CatalogUtils";
import {useTranslation} from "react-i18next";
import location from "@assets/location.svg";
import "./PostInfoRow.scss"

export interface RecommendationsItemProps {
    postData: PostData
}

const PostInfoRow: React.FC<RecommendationsItemProps> = ({postData}) => {
    const {t} = useTranslation();

    return <div className="post-info-row">
        <span className="post-info-row type">{t(CatalogUtils.getPrettyType(postData.post_meta))} | </span>
        <span className="post-info-row title">{postData.title}</span>
        <img  className="post-info-row  location-icon" src={location} alt="location icon"/>
        <span className="post-info-row  address">{postData?.place?.address}</span>
        <span className="post-info-row  catalog" >{t(CatalogUtils.getPrettyCategory(postData))}</span>
    </div>
}

export default PostInfoRow