import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import SearchViewModel from "@viewmodels/SearchViewModel";
import {Button, Card, Container} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {UserV1} from "@domain/models/user/UserV1";
import searchResults from "@presentation/views/pages/Search/SearchResults";
import {SavedSearch, SavedSearchResult} from "@domain/models/search/SavedSearch";

interface SavedProps {
    searchViewModel: SearchViewModel;
}

const Saved: React.FC<SavedProps> = observer(({searchViewModel}: SavedProps) => {

    const [searchResults, setSearchResults] = useState<SavedSearchResult | undefined>(undefined);

    const handleDelete = (searchId: string | undefined) => {
        // Implement the delete logic here using your view model.

    };


    useEffect(() => {
        searchViewModel.handleSavedSearch();
    }, []);

    useEffect(() => {
        const mySavedResults = searchViewModel.getMySavedSearches()
        if(mySavedResults){
            setSearchResults(mySavedResults)
        }

    }, [searchViewModel.getMySavedSearches()]);

    return (

        <Container>
            <h1 className='mt-4 mb-4'>My saves</h1>

            {searchResults?.data?.map((savedSearch) => (
                <Card key={savedSearch.id} style={{backgroundColor: 'lightgray', marginBottom: '10px'}}>
                    <Card.Body style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Card.Title>{savedSearch.query}</Card.Title>
                        <Button
                            variant="danger"
                            onClick={() => handleDelete(savedSearch.id)}
                            className="ml-auto"
                        >
                            Delete
                        </Button>
                    </Card.Body>
                </Card>
            ))}
        </Container>
    );

});

export default Saved;

