import {PostData} from "@domain/models/post/PostResponseV1";
import {useCallback, useState} from "react";
import {DeletePostFromHistoryUseCase} from "@domain/usecases/search/DeletePostFromHistoryUseCase";


export interface SearchPostHistoryViewState {
    postResultData?: PostData[] | null,
    isLoading?: boolean;
    error?: string | null;
}

export const useDeletePostFromHistoryViewModel = (deletePostInHistoryUseCase: DeletePostFromHistoryUseCase) => {
    const [stateDeletePostHistory, setState] = useState<SearchPostHistoryViewState>({isLoading: false});

    const deletePostInHistory = useCallback((postData: PostData) => {
        setState({isLoading: true});
        const postResultData = deletePostInHistoryUseCase.execute(postData)
        setState({postResultData: postResultData, isLoading: false, error: null});

    }, [deletePostInHistoryUseCase]);

    return {
        stateDeletePostHistory,
        deletePostInHistory
    };
};