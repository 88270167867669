import React, {useEffect, useState} from "react";
import {Form, InputGroup, Row} from "react-bootstrap";

import SearchViewModel from "@viewmodels/SearchViewModel";

import getImagesConfig from "@utils/remote_config/ImagesConfig";
import SearchExperienceContainer
    from "@presentation/views/components/Search/SearchExperience/SearchExperienceContainer/SearchExperienceContainer";
import R from "@strings/R";
import {useTranslation} from "react-i18next";
import searchIcon from "@assets/search_icon.svg"

import "./SearchBar.scss";
 import {useLocation, useNavigate} from "react-router-dom";
import UrlUtils from "@utils/UrlUtils";
import {SearchKeys} from "@enums/SearchKeys";

export interface SearchBarProps {
    //Nothing for now
    updateQueryParam : (params: any) => void
}

export function SearchBar({updateQueryParam}: SearchBarProps) {
    const [showSearchExperience, setShowSearchExperience] = useState(false)
    const [currentQuery, setCurrentQuery] = useState("")


    let landingImage = getImagesConfig()?.landingSearchResults;
    const {t} = useTranslation();

    const location = useLocation();
    const navigate = useNavigate()


    const onSearchInputClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setShowSearchExperience(true);
    };

    const onHideSearchExperience = () => {
        setShowSearchExperience(false)
    }



    useEffect(() => {
        const paramsMap = UrlUtils.getParamsMap(location.search)
        const query = paramsMap.get(SearchKeys.QUERY_SEARCH_KEY) ?? ""
        setCurrentQuery(query)
    }, [location]);

    return (
        <Row className="bg-white ">
            <Form className="form-inline p-0 ">
                <div className='search-bar-result-page main'>
                    <div
                        className="search-bar-result-page background-container"
                        style={{backgroundImage: `url('${landingImage}')`}}
                    >
                        <InputGroup
                            className={`gx-0  search-bar-result-page search-container-input  `}
                            onClick={onSearchInputClick}
                        >
                            <div className="search-bar-result-page  input-bg ps-sm-3  pe-sm-3 ">
                                <div className="search-bar-result-page input-wrapper">
                                    <p className="search-bar-result-page input-text p-2"> {currentQuery ? currentQuery : t(R.landingPage_search_hint)}</p>
                                    <img src={searchIcon} className="search-input  search-icon"
                                         alt="search-icon"/>
                                </div>
                            </div>

                        </InputGroup>

                        <SearchExperienceContainer updateQueryParam={updateQueryParam} show={showSearchExperience}
                                                   onHide={onHideSearchExperience}
                        />
                    </div>
                </div>
            </Form>
        </Row>
    );
}

export default SearchBar;