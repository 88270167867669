import { TopCatalogModel } from "@domain/models/catalog/TopCatalogModel"
import ICatalogRepository from "@domain/repository/catalog/ICatalogRepository"

export default class GetTopCategoriesUseCase {
    
 
        private categoryRepository: ICatalogRepository
    
        public constructor(categoryRepository: ICatalogRepository) {
            this.categoryRepository = categoryRepository
        }
    
    
        /**
         * Get top categories
         * @throws {Error} in case of getting non 2xx response or empty query 
         */
        public async getTopCategories(): Promise<TopCatalogModel[]| undefined> {
            return new Promise(async (resolve, reject) => {
                await this.categoryRepository.getTopCatalog().then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
 
}