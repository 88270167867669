import { getRemoteConfig, getString } from "firebase/remote-config";
import { REMOTE_CONFIG_PRIVACY_POLICY_CONTENT_KEY } from "./RemoteConfigKeys";

export default function getPrivacyRemoteConfig(): string  {

    const remoteConfig = getRemoteConfig();
  
    const privacyString = getString(remoteConfig, REMOTE_CONFIG_PRIVACY_POLICY_CONTENT_KEY)
  
    return privacyString;
    
  }
  