import React, {useEffect, useState} from 'react';
import './SearchMobile.scss'; // SCSS for styling
import arrowBack from "@assets/arrow_back_ic.svg"
import ScrollableButtonGroup
    from "@presentation/views/components/Search/SearchExperience/ScrollableButtonGroup/ScrollableButtonGroup";
import {LocationDomain} from "@domain/models/search/SugestionType";
import {useSearchLocationViewModel} from "@root/framework/hooks/useSearchLocationViewModel";
import {useInjection} from "@root/ioc.react";
import {IProvider} from "@di/viewmodels/ViewModelProvider";
import SearchLocationUseCase from "@domain/usecases/location/SearchLocationUseCase";
import TYPES from "@di/viewmodels/ViewModelTypes";
import {useGetCurrentLocationViewModel} from "@root/framework/hooks/useGetCurrentLocationViewModel";
import {GetCurrentLocationUseCase} from "@domain/usecases/search/GetCurrentLocationUseCase";
import {useSearchViewModel} from "@root/framework/hooks/useSearchViewModel";
import SearchUseCase from "@domain/usecases/search/SearchUseCase";
import {SearchKeys} from "@enums/SearchKeys";
import UrlUtils from "@utils/UrlUtils";
import CatalogUtils from "@utils/CatalogUtils";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {SaveCurrentLocationUseCase} from "@domain/usecases/search/SaveCurrentLocationUseCase";
import {useSaveCurrentLocationViewModel} from "@root/framework/hooks/useSaveCurrentLocationViewModel";
import Cities from "@presentation/views/components/Search/SearchExperience/Cities/Cities";
import R from "@strings/R";
import {PostData} from "@domain/models/post/PostResponseV1";
import NavigationUtils, {NavigationParams} from "@utils/NavigationUtils";
import {Path} from "@enums/Path";

const SearchMobile: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const searchLocationUseCase = useInjection<IProvider<SearchLocationUseCase>>(TYPES.SearchLocationUseCase).provide()
    const getCurrentLocationUseCase = useInjection<IProvider<GetCurrentLocationUseCase>>(TYPES.GetCurrentLocationUseCase).provide()
    const searchUseCase = useInjection<IProvider<SearchUseCase>>(TYPES.SearchUseCase).provide()

    const {stateLocation, searchLocation} = useSearchLocationViewModel(searchLocationUseCase);
    const [selectedCity, setSelectedCity] = useState<string | undefined>(undefined)
    const {stateGetCurrentLocation, getCurrentLocation} = useGetCurrentLocationViewModel(getCurrentLocationUseCase);
    const location = useLocation();
    const saveCurrentLocationUseCase = useInjection<IProvider<SaveCurrentLocationUseCase>>(TYPES.SaveCurrentLocationUseCase).provide()
    const {stateSaveCurrentLocation, saveCurrentLocation} = useSaveCurrentLocationViewModel(saveCurrentLocationUseCase);

    const {stateSearch, searchPosts} = useSearchViewModel(searchUseCase);

    const [showSearchCitiesContainer, setShowSearchCitiesContainer] = useState(false)

    const catalogId = "CAT-1";
    const {t} = useTranslation();
    const navigate = useNavigate()

    function handleRemoveCity() {
        setSelectedCity(undefined)
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        const paramsMap = UrlUtils.getParamsMap(location.search)

        const currentLocation = stateGetCurrentLocation.locationDomain
        //Set the query
        paramsMap.set(SearchKeys.QUERY_SEARCH_KEY, event.target.value)

        if (currentLocation?.lat && currentLocation?.lon) {
            paramsMap.set(SearchKeys.LAT_SEARCH_KEY, currentLocation?.lat.toString())
            paramsMap.set(SearchKeys.LON_SEARCH_KEY, currentLocation?.lon.toString())
        }

        searchPosts(catalogId, paramsMap);
    };

    const toggleDialog = () => {
        setIsOpen(!isOpen);
    };

    function onAnyLocationButtonClick() {
        setShowSearchCitiesContainer(true)

    }

    function searchLocationOnList(locationDomain: LocationDomain) {
        const paramsMap = UrlUtils.getParamsMap(location.search)
        //Set the query
        paramsMap.set(SearchKeys.LON_SEARCH_KEY, locationDomain.lon.toString())
        paramsMap.set(SearchKeys.LAT_SEARCH_KEY, locationDomain.lat.toString())
        searchPosts(catalogId, paramsMap);
    }

    function onLocationClick(isFromAnyLocation: boolean, locationDomain: LocationDomain | undefined) {
        if (!locationDomain) {
            onAnyLocationButtonClick()
            return
        }
        searchLocationOnList(locationDomain);

        saveCurrentLocation(isFromAnyLocation, locationDomain)

        getCurrentLocation()
    }


    useEffect(() => {
        //Get main cities
        searchLocation("")
        getCurrentLocation()
    }, []);

    useEffect(() => {
        if (isOpen) {
            // Disable background scrolling when the sheet is open
            document.body.classList.add("search-mobile__dialog-open");

        } else {
            document.body.classList.remove("search-mobile__dialog-open");
        }
    }, [isOpen]);

    function onBackPressed(event: React.MouseEvent) {
        event.stopPropagation()
        setShowSearchCitiesContainer(false)
    }


    function handleClickLocation(isFromAnyLocation: boolean, locationDomain: LocationDomain) {
        setShowSearchCitiesContainer(false)
        setSelectedCity(locationDomain.name)
        saveCurrentLocation(isFromAnyLocation, locationDomain)
        searchLocationOnList(locationDomain);
        getCurrentLocation()
    }

    function onPostClicked(  postData: PostData) {

        updateQueryParam({
            [SearchKeys.QUERY_SEARCH_KEY]: postData.title,
            [SearchKeys.LAT_SEARCH_KEY]: postData.place?.lat,
            [SearchKeys.LON_SEARCH_KEY]: postData.place?.lon,
        })
    }

    function updateQueryParam(body: any) {
        const newParams: NavigationParams = body
        const updatedSearchParams = NavigationUtils.buildSearchParams(newParams);
        navigate(Path.SEARCH + "/" + updatedSearchParams);
    }

    return (
        <>
            {/* Search Input that opens the dialog */}
            <input
                type="text"
                placeholder="Search..."
                onFocus={toggleDialog}
                className="search-mobile__search-input"
            />

            {/* Full-Screen Overlay Dialog */}
            {isOpen && (

                <div className="search-mobile__overlay">

                    {!showSearchCitiesContainer ? (<div className="search-mobile__dialog">
                            <header className="search-mobile__dialog__search-header">
                                <img className="search-mobile__dialog__search-header__close-button" src={arrowBack}
                                     onClick={toggleDialog}/>
                                <h2>{t(R.searchResults_search_searchMobileTitle)}</h2>
                            </header>

                            <div className="search-mobile__dialog__recommendations-list">
                                {stateSearch.postResultData?.data.map((postData, index) => (
                                    <div key={index} onClick={(e: React.MouseEvent) => onPostClicked(postData)}
                                         className="search-mobile__dialog__recommendations-list__recommendation-item">
                                        <div
                                            className="search-mobile__dialog__recommendations-list__recommendation-item__recommendation-type">{t(CatalogUtils.getPrettyType(postData.post_meta))}</div>
                                        <div
                                            className="search-mobile__dialog__recommendations-list__recommendation-item__recommendation-description ">{postData.title}</div>
                                        <div
                                            className="search-mobile__dialog__recommendations-list__recommendation-item__recommendation-location">{postData?.place?.address}</div>
                                    </div>
                                ))}
                            </div>

                            <>
                                <ScrollableButtonGroup
                                    onLocationClick={onLocationClick}
                                    locations={stateLocation.locationData ?? []}
                                    selectedCity={selectedCity}
                                    handleRemoveCity={handleRemoveCity}
                                    stateGetCurrentLocation={stateGetCurrentLocation}
                                />
                            </>
                            <div className="search-mobile__dialog__search-bar">

                                <input
                                    type="text"
                                    placeholder="e.g. Dubai 3 bed apartment"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                            </div>
                        </div>) :
                        (<div className="search-mobile__cities">
                            <Cities onBackPressed={onBackPressed} handleClickLocation={handleClickLocation}/></div>)}
                </div>)

            }
        </>)
};

export default SearchMobile;
