import React from "react";
 import {StyledFirebaseAuth} from "react-firebaseui";
import FirebaseApp from "../../../../../firebaseApp";
import { GoogleAuthProvider,EmailAuthProvider } from "firebase/auth";
import {Path} from "@enums/Path";

export function Login(): React.ReactElement {

    // Firebase UI configuration
    const uiConfig = {
        signInFlow: 'popup',
        signInSuccessUrl: Path.HOME, // Redirect URL after successful sign-in
        signInOptions: [
            GoogleAuthProvider.PROVIDER_ID,
            EmailAuthProvider.PROVIDER_ID
        ],
    };

    return (
        <>
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-md-6 ">
                        <div className="features">
                            <h2>Manage your:</h2>
                            <ul>
                                <li>Posts</li>
                                <li>Favorites</li>
                                <li>Alerts</li>
                            </ul>
                        </div>
                        <p>With Rentallo, you can:</p>
                        <ul className="features-list">
                            <li>Easily manage your posts</li>
                            <li>Keep track of your favorite listings</li>
                            <li>Stay updated with alerts for new listings in your area</li>
                        </ul>
                    </div>
                </div>
            </div>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={FirebaseApp.getInstance().getFirebaseAuth()}/>
        </>

    );

}