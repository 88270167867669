import { RailData } from "@domain/models/rail/RailData"
import IRailRepository from "@domain/repository/rail/IRailRepository"

export default class GetRailUseCase {

    private railRepository: IRailRepository

    public constructor(railRepository: IRailRepository) {
        this.railRepository = railRepository
    }


    /**
     * Get a rail given a rail_id
     * @param railId 
     * @throws {Error} in case of getting non 2xx response or empty query 
     */
    public async getRail(railId: String): Promise<RailData> {
        return new Promise(async (resolve, reject) => {
            await this.railRepository.getRail(railId).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    }
}