import React from "react";
import { createRoot } from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import { App } from "./App";
import { fetchAndActivate, getAll, Value } from "firebase/remote-config";
import FirebaseApp from "./firebaseApp";
import "./index.scss";
import rcDefaultsJson from "./framework/res/json/remote_config_defaults.json";

const firebaseInstance = FirebaseApp.getInstance();
const remoteConfig = firebaseInstance.getRemoteConfig();

//TODO THIS SHOULD BE ONLY IN DEV ENV !! REMOVE IN PROD...
//  remoteConfig.settings.minimumFetchIntervalMillis = 1000*60*60
remoteConfig.defaultConfig = rcDefaultsJson as any;

const rootElement = document.getElementById("root") as Element;
const root = createRoot(rootElement);

fetchAndActivate(remoteConfig)
    .then((fetchedRemotely) => {
        if (!fetchedRemotely) {
            console.log("[rc] Not fetched");
        }
    })
    .catch((err) => {
        console.log("There was an error while retrieving remote config");
    });

root.render(<App />);
