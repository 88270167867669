import React, { useState } from "react";
import { Form, FormControl, Row } from "react-bootstrap";
import { Attribute } from "@presentation/views/components/DynamicForm/DynamicForm";

interface DynamicInputProps {
    attribute: Attribute;
    formData: Record<string, any>; // Adjust the type as needed
    minValue: number;
    maxValue: number;
    onInputChange: (key: string, value: any) => void;
    validateInput: (value: string) => {
        isValid: boolean;
        errorMessage: string;
    };
}

const CostInput: React.FC<DynamicInputProps> = ({
    attribute,
    formData,
    minValue,
    maxValue,
    onInputChange,
    validateInput,
}) => {
    const [validationError, setValidationError] = useState<string | null>(null);

    const handleValidationError = (e: {
        target: {
            value: any;
        };
    }) => {
        const { isValid, errorMessage } = validateInput(e.target.value);
        if (!isValid) {
            setValidationError(errorMessage);
        } else {
            setValidationError(null);
        }
    };

    const defaultValue = "year"; // Set your desired default value here

    return (
        <div key={attribute.search_key} className="mt-2">
            <Form.Group controlId={attribute.search_key}>
                <Form.Label>{attribute.name}</Form.Label>
                {attribute.mandatory && <span className="text-danger">*</span>}
                <Row>
                    <div className="col-md-8">
                        {" "}
                        {/* Adjust the column width as needed */}
                        <FormControl
                            type="number"
                            min={minValue}
                            max={maxValue}
                            onBlur={handleValidationError}
                            name={attribute.search_key}
                            value={
                                formData[attribute.search_key]?.[0]?.value || ""
                            }
                            onChange={(e) =>
                                onInputChange(attribute.search_key, [
                                    { value: e.target.value },
                                ])
                            }
                        />
                    </div>
                    <div className="col-md-4">
                        <Form.Select
                            value={formData["price_period"] || defaultValue}
                            onChange={(e) =>
                                onInputChange("price_period", e.target.value)
                            }
                            style={{ whiteSpace: "normal" }} // Add this style to wrap content
                        >
                            <option value="hour">Hour</option>
                            <option value="day">Day</option>
                            <option value="week">Week</option>
                            <option value="month">Month</option>
                            <option value="year">Year</option>
                        </Form.Select>
                    </div>
                </Row>
                {validationError && (
                    <div className="text-danger">{validationError}</div>
                )}
            </Form.Group>
        </div>
    );
};

export default CostInput;
