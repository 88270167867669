
import { PostData } from "@domain/models/post/PostResponseV1";
import React, { useState } from "react";
import { Col, Row, Spinner, Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import R from "@strings/R";
import FactoryFilterAtributes from "@utils/factories/FactoryFilterAtributes";
import Gallery from "../Gallery";
import Map from "../Map";
import { IProvider } from "@di/viewmodels/ViewModelProvider";
import TYPES from "@di/viewmodels/ViewModelTypes";
import UserViewModel from "@viewmodels/UserViewModel";
import { observer } from "mobx-react-lite";
import { useInjection } from "../../../../../ioc.react";
import { TimeUtils } from "@utils/TimeUtils";
import backarrow from '@assets/backarrow.svg';
import { useNavigate } from "react-router-dom";
import { Card, Placeholder } from "react-bootstrap";
import "./PostDetailsCardView.scss";

import brokenImage from "@assets/link_broken.jpg";

export interface PostDetailsComponentProps {
  post?: PostData,
  isLoading?: boolean
}

interface UserViewProp {
  _userViewModel: UserViewModel
}

const PostDetailsCardView: React.FC<PostDetailsComponentProps> = ({ post, isLoading }: PostDetailsComponentProps): JSX.Element => {

  const { t } = useTranslation();
  const [showGallery, setShowGallery] = useState(false)
  const [showMap, setShowMap] = useState(false)
  const [isHoverBackButton, setIsHoverBackButton] = useState(false)

  const userViewModel = useInjection<IProvider<UserViewModel>>(TYPES.UserViewModel).provide();
  const navigate = useNavigate();

  const descriptionMaxLength = 200; // Max lenght to show full description
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };


  const PhoneNumberView = observer(({ _userViewModel }: UserViewProp) => {
    let phoneNumber = userViewModel.getUserData?.phone_number_code + "" + userViewModel.getUserData?.phone_number

    console.log("_userViewModel:" + JSON.stringify(_userViewModel));

    const handleClick = async (e: React.MouseEvent<HTMLParagraphElement>) => {
      await _userViewModel.handleGetUserAccount(post?.user_id as number);
    };

    return (
      <div >
        {_userViewModel.isLoadingPhoneNumber ? (
          <div className="col-auto no-padding-left style-spinner">
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Loading...
            </Button>
          </div>
          // <Spinner animation="border" variant="primary"/>
        ) : _userViewModel.getUserData?.phone_number == null ? (
          <p
            onClick={handleClick}
            className="col-auto link-primary no-padding-left"
          >
            {t(R.post_details_call_action_label)}
          </p>
        ) : (
          <a className="col-auto text-decoration-none link-primary style-phone-number" href={"tel:" + phoneNumber}>
            {phoneNumber}
          </a>
        )}
      </div>
    );

  })

  function createMarkup(description: string) {
    return { __html: description };
  }
  function handleClickBacktoHome() {
    navigate(-1);
  }

  return isLoading ? (
    <Row className="ms-5">
      <Col md={8} sm={12} xs={8}>
        <Card className="postdet_image_placeholder" />
        <Placeholder xs={4} className="placeholder_postdetail mt-3" />
        <Placeholder xs={12} className="placeholder_postdetail mt-5" />

        <Card className="post_atributtes_placeholder" />
        <Placeholder xs={2} className="placeholder_postdetail mt-5" />

        <Card className="post_description_placeholder" />
        <Placeholder xs={3} className="placeholder_postdetail mt-5" />

        <Card className="map_placeholder" />
      </Col>

      <Col md={4} sm={12}>
        <Card className="phone_number_placeholder" />
      </Col>
    </Row>
  ) : (
    <Row className="justify-content-md-center ms-5">
      <Col md={8} sm={12} xs={8}>
        <div
          className="mt-4 mb-4 btn "
          onClick={handleClickBacktoHome}
          onMouseEnter={(e: React.MouseEvent<HTMLElement>) => {
            setIsHoverBackButton(true);
          }}
          onMouseLeave={(e: React.MouseEvent<HTMLElement>) => {
            setIsHoverBackButton(false);
          }}
        >
          <img src={backarrow}></img>
          <strong
            className={
              isHoverBackButton
                ? "border-bottom border-3 border-primary  mx-2 mx-3"
                : "  mx-2 mx-3"
            }
          >
            {t(R.postDetails_backToHomeButton)}
          </strong>
        </div>
        <div>
          <img
            className="d-block w-100 border"
            height="500px"
            src={post?.images[0].medium}
            role="button"
            style={{ objectFit: "cover" }}

            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
              e.currentTarget.src = brokenImage;
              e.currentTarget.classList.add('border-secondary'); // Add class border-secondary when the image not found
            }
            }

            onClick={(e: React.MouseEvent<HTMLElement>) => {
              setShowGallery(true);
            }}

          />
          <div className="row gx-0">
            <p className="bi bi-image p-3 border border-secondary  justify-content-md-center mt-2 col-2 ">
              {" "}
              {post?.images.length} {t(R.postDetails_images_photoNumber)}
            </p>
            <p className="col-auto justify-content-md-center mt-3 h2">
              <strong>
                <i className="bi bi-dot"></i>
                {FactoryFilterAtributes.createFixCost(post as PostData)}{" "}
              </strong>{" "}
            </p>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-8 clamp-2-lines" style={{ fontSize: "2em" }}>
            <strong>{post?.title}</strong>
          </div>
        </div>

        <div className="row mt-3">
          <p className="col-auto h5">
            {FactoryFilterAtributes.createDescriptionMeta(post as PostData)}{" "}
          </p>
        </div>

        {/* <div className="mt-5">
          <h4>{t(R.postDescription_title_attrbs)}</h4>
          {FactoryFilterAtributes.createPostAttributes(post as PostData)}
        </div> */}

        <div className="mt-3">
          <h4>{t(R.postDescription_descriptionTitle_attrbs)}</h4>
          <div className="mt-4" style={{ fontSize: "1em" }}>
            {showFullDescription ? (
              <p dangerouslySetInnerHTML={createMarkup(post!!.description)}></p>
            ) : (
              <p dangerouslySetInnerHTML={createMarkup(post!!.description.substring(0, descriptionMaxLength) + "...")}></p>
            )}
            {post!!.description.length > descriptionMaxLength && (
              <button className={`toggle-button ${showFullDescription ? 'expanded' : 'collapsed'}`} onClick={toggleDescription}>
                {showFullDescription ? <i className="bi bi-chevron-up"></i> : <i className="bi bi-chevron-down"></i>}
                &nbsp;<u><strong>{showFullDescription ? 'Hide full description' : 'See full description'}</strong></u>
              </button>
            )}
          </div>
        </div>

        <div className="mt-5">
          <h4>{t(R.postDescription_locationTitle)}</h4>
          <div className="row" style={{ fontSize: "1em" }}>
            <img
              className="mt-4"
              src={
                "https://maps.googleapis.com/maps/api/staticmap?zoom=13&size=432x198&scale=2&maptype=roadmap&markers=color:red%7Clabel:C%7C" +
                post?.place?.lat +
                "," +
                post?.place?.lon +
                "&key=AIzaSyBJ7D4HfECzadpIXCEmT1lqYsKctnI9gSw"
              }
              role="button"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                setShowMap(true);
              }}
            />
          </div>
        </div>
      </Col>

      <Col md={4} sm={12}>
        <div className="phone_number">
          <div className="row " role="button">
            <i className="col-auto bi bi-telephone"></i>
            {
              <PhoneNumberView
                _userViewModel={userViewModel}
              ></PhoneNumberView>
            }
          </div>
          <p className="mt-2">{t(R.postDetails_detailsAdvertiser_adId)} </p>
          <p>
            {" "}
            <strong> {post?.id} </strong>
          </p>
          <p className="mt-2">
            {t(R.postDetails_detailsAdvertiser_createdAt)}{" "}
          </p>
          <p>
            {" "}
            <strong>
              {" "}
              {TimeUtils.convertTimeToPrettyTime(post?.created_at)}{" "}
            </strong>
          </p>
          <p className="mt-2">
            {t(R.postDetails_detailsAdvertiser_createdBy)}{" "}
          </p>
          <p>
            {" "}
            <strong>{post?.created_by}</strong>
          </p>
        </div>
      </Col>

      <Gallery
        post={post as PostData}
        showGallery={showGallery}
        callback={function (show: boolean): void {
          setShowGallery(show);
        }}
      />

      <Map
        post={post as PostData}
        showMap={showMap}
        callback={function (show: boolean): void {
          setShowMap(show);
        }}
      />
    </Row>
  );
}

export default PostDetailsCardView