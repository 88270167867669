import { Value } from "@domain/models/catalog/CatalogModel";
import { Concurrency } from "@domain/models/enums/Concurrency";
import { FilterInputTypes } from "@domain/models/enums/FilterInputTypes";
import { PriceKeyword } from "@domain/models/enums/PricePerTypes";
import { CatalogAttribute, PostData } from "@domain/models/post/PostResponseV1";
import getAttrbIconsMap from "@utils/remote_config/AttrbIconsConfig";
import { t } from "i18next";
import React from "react";
import { Form } from "react-bootstrap";

class FactoryFilterAtributes {


    static createAttributeRepresentation(catalogAtrrb: CatalogAttribute): JSX.Element {
        switch (catalogAtrrb.type) {
            case FilterInputTypes.BINARY_VALUE: {
                return getBinaryRepresentation(catalogAtrrb)
            }
            case FilterInputTypes.VALUE: {
                return <strong>{catalogAtrrb.values[0].value}</strong>
            }

            case FilterInputTypes.SIGNED_INT_VALUE: {
                return <strong>{catalogAtrrb.values[0].value}</strong>
            }
            case FilterInputTypes.CHECKED_LIST: {
                return getCheckListRepresentation(catalogAtrrb.values)
            }
            case FilterInputTypes.COST: {
                return <strong>{catalogAtrrb.search_key}</strong>
            }
            case FilterInputTypes.SEARCH_RADIO_LIST: {
               return getRadioListRepresentation(catalogAtrrb.values)
            }

            default: {
                return <strong></strong>
            }
        }
    }


    static createPostAttributes(post: PostData) {
        let attrbIcons = getAttrbIconsMap()
        return <div className="row mt-4"> {post.post_meta?.catalog_attributes?.filter(it => attrbIcons?.icons?.[it.search_key] != undefined)?.map(attr => {
            return <div className="col-6">{FactoryFilterAtributes.createAttributeRepresentation(attr)} </div>
        })}</div>
    }


    static createDescriptionMeta(post: PostData) {
        const filteredAttributes = post.post_meta?.catalog_attributes?.filter(
          it =>
            it.search_key === "sk_yacht_type" ||
            it.search_key === "sk_bedrooms" ||
            it.search_key === "sk_number_of_guests" ||
            it.search_key === "sk_beds" ||
            it.search_key === "sk_badthrooms"
        );
      
        if (!filteredAttributes) return null;
      
        const attributesWithText = filteredAttributes.map((attr, index) => (
          <React.Fragment key={attr.search_key}>
            <div className="col-auto">
              {FactoryFilterAtributes.createAttributeRepresentation(attr)} {t(attr.name_dict_key)}
            </div>
            {index !== filteredAttributes.length - 1 && <div className="col-auto">|</div>}
          </React.Fragment>
        ));
      
        return <div className="row">{attributesWithText}</div>;
    }


    static createFixCost(post: PostData) {
        return post.post_meta?.catalog_attributes?.map(attr => {
            if (attr.type == FilterInputTypes.COST) {

                return <>{Concurrency[post?.post_meta?.currency_iso_code!!]} {Number(attr.values[0].value).toLocaleString()} {post?.post_meta?.price_period ? PriceKeyword[post?.post_meta?.price_period] : ""} </>
            }

        })
    }



}

export default FactoryFilterAtributes



function getRadioListRepresentation(value: Value[]): JSX.Element {
    return <> {value.map(it => {
        // return <Form.Check  >
        //     <Form.Check.Input disabled={true} checked={true} />
        //     <Form.Check.Label>{it.value}</Form.Check.Label>
        // </Form.Check>
        return it.value;
    })}</>
}

function getBinaryRepresentation(catalogAttrb: CatalogAttribute): JSX.Element {
    let attrbIcons = getAttrbIconsMap()

    let label = <p><p><strong> <i className={attrbIcons?.icons!![catalogAttrb.search_key]} style={{ fontSize: '1.5em' }}></i>  </strong> {t(catalogAttrb?.name_dict_key)} </p> </p>
    if (catalogAttrb.values[0].value == "true") {
        return label
    }
    return <del>{label}</del>
}


function getCheckListRepresentation(value: Value[]): JSX.Element {
    return <> {value.map(it => {
        return <Form.Check  >
            <Form.Check.Input disabled={true} checked={true} />
            <Form.Check.Label>{value}</Form.Check.Label>
        </Form.Check>
    })}</>

}

