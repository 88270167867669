// import './App.css';
import React, { useEffect } from 'react';
import './i18n';
import container from './di/viewmodels/Container';

import AppRouter from './framework/presentation/views/components/routes/AppRouter';
import { Provider } from './ioc.react';
import OnMaintenance from "@presentation/views/pages/OnMaintenance";


export const App = () => {
	const isMaintenanceMode = process.env.REACT_APP_MAINTAINCE_MODE === 'true';

	useEffect(() => {
		if(!isMaintenanceMode){
			const script = document.createElement('script');
			script.src = 'https://cmp.osano.com/16A6d0ToFukE24eTM/21ad8395-713d-4a4f-9f81-db58807dc693/osano.js';
			document.head.insertBefore(script, document.head.firstChild);

			return () => {
				document.head.removeChild(script);
			};
		}
	  }, []);

	return (
		<>
			{isMaintenanceMode ? (
				<OnMaintenance />
			) : (
				<Provider container={container}>
					<AppRouter />
					<OnMaintenance />
				</Provider>
			)}
		</>
	);
}

