import DataState from "@domain/models/state/DataState";
import { RailState } from "@domain/models/state/RailState";
import GetRailUseCase from "@domain/usecases/rail/GetRailUseCase";
import { action, makeObservable, observable } from "mobx";

class RailViewModel {
    public isLoadingRequest: boolean = false;

    public error: Error | undefined
    public stateLocation: DataState<RailState> = new DataState({ railData: undefined})

    public getRailUseCase: GetRailUseCase
    constructor(getRailUseCase: GetRailUseCase) {
        this.getRailUseCase = getRailUseCase
         makeObservable(this, {
            isLoadingRequest: observable,
            stateLocation: observable,
            error: observable,
            getRail: action
        })
    }

    getRailData() {
        return this.stateLocation.content.railData
    }

    getRail(railId: String) {
        this.isLoadingRequest = true
        this.getRailUseCase.getRail(railId)
            .then(response => { 
                this.stateLocation = new DataState({ railData: response})
                this.isLoadingRequest = false
                console.log("response in rails")
            }).catch(error => {
                console.log("error in rails " + error)

                this.error = error
                this.isLoadingRequest = false
            })
    }


    
}


export default RailViewModel