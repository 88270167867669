
import {PostData, PostMeta} from "@domain/models/post/PostResponseV1";
import {CATALOG_PROPERTY_ID_KEY} from "@utils/Constants";
import R from "@strings/R";

class CatalogUtils {


     static getPrettyType( postMeta:  PostMeta | undefined ): string  {
         if (!postMeta) return '';
          for (const catalogAttribute of postMeta.catalog_attributes) {
             if (postMeta.catalog_id === 'CAT-1' && catalogAttribute.search_key === 'sk_real_estate_type') {
                  return catalogAttribute.values[0].name_dict_key; // Assuming value holds the pretty name like "apartment"
             } else if (postMeta.catalog_id === 'CAT2' && catalogAttribute.search_key === 'some_other_key') {
                 return "missing"; // Assuming value holds the pretty name for CAT2
             }
         }

         return '';

     }


    static getPrettyCategory( postMeta:  PostData | undefined ): string  {

         if(postMeta?.post_meta?.catalog_id == CATALOG_PROPERTY_ID_KEY){
             return R.catalog_name_properties
         }

        return 'unknown';

    }


}

export default CatalogUtils