import { DateFilter } from "./DateFilter";
import { LocationFilter } from "./LocationFilter";
import { PriceFilter } from "./PriceFilter";
import {SortOrder} from "@domain/models/filter/SortOrder";

export class FilterOption {
    locationFilter?: LocationFilter = {}
    dateFilter?: DateFilter  = {from: "", to: ""}
    priceFilter?: PriceFilter  = {}
    sortOrder?: SortOrder = {}
} 

