export enum FilterInputTypes {
    SEARCH_LOCATION_INPUT ='SEARCH_LOCATION_INPUT',
	BINARY_VALUE = 'BINARY_VALUE',
    //radio list is a list with single selection
    SEARCH_RADIO_LIST ='RADIO_LIST',
    SORT_RESULT_LIST ='SORT_RESULT_LIST',
    ITEMS_PER_PAGE ='ITEMS_PER_PAGE',

    COLOR_HEX ='COLOR_HEX',
    VALUE='VALUE',
    SIGNED_INT_VALUE='SIGNED_INT_VALUE',
    COST='COST',
    COST_PERIOD='COST_PERIOD',
    //checkbox list is a set of checkbox with multiple selection
    CHECKED_LIST='CHECKED_LIST',
    TWO_VALUES_RANGE='TWO_VALUES_RANGE'

}