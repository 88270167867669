import {Link, Meta} from "@domain/models/post/PostResponseV1";
import React from "react";
import ApiUtils from "@utils/ApiUtils";
import {useNavigate} from "react-router-dom";
import NavigationUtils, {NavigationParams} from "@utils/NavigationUtils";

interface PaginationViewProps {
    meta: Meta | undefined
}

export const PaginationView: React.FC<PaginationViewProps> = ({meta}: PaginationViewProps) => {
    const navigate = useNavigate()

    const handlePageClick = (link: Link) => {
        const page = ApiUtils.getPageFromUrl(link.url);
        const params: NavigationParams = {
            ["page"]: page.toString(),
        }
        const updatedSearchParams = NavigationUtils.buildSearchParams(params);

        navigate(updatedSearchParams, {replace: true});
    };

    return <nav>
        <ul className="pagination  pagination-lg  justify-content-start   mt-5 col-sm-12  ">
            {
                meta?.links?.map(link => {
                    if (link.label != '') {
                        if (link.active) {
                            return <li className="page-item active" aria-current="page">
                                <button className="btn btn-primary">{link.label}</button>
                            </li>

                        }
                        return <li className="page-item">
                            <button onClick={() => handlePageClick(link)}
                                    className="btn btn-light me-2 ms-2"
                                    dangerouslySetInnerHTML={{__html: link.label}}
                            ></button>
                        </li>
                    }
                })
            }

        </ul>
    </nav>


}

export default PaginationView;