import {useTranslation} from "react-i18next";
import R from "@strings/R";
import React from 'react';
import heart from "@assets/ic_heart_outline.svg";
import "./EmptyCities.scss"


const EmptyCities: React.FC = () => {

    const { t } = useTranslation();

    return (
        <div className="empty_cities_list container">
            <img className="empty_cities_list heart" src={heart} alt="empty_icon"/>
            <p className="empty_cities_list message">{t(R.searchResults_searchCities_not_location_found)}</p>
         </div>
    );
};


export default EmptyCities;