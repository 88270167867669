import React, {useEffect, useState} from "react";
import {Button, Card, Container, Form, Modal} from "react-bootstrap";
import UserViewModel from "@viewmodels/UserViewModel";
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import {UserV1} from "@domain/models/user/UserV1";
import FirebaseApp from "../../../../../firebaseApp";

export interface MyDetailsProps {
    userViewModel: UserViewModel
}

const MyDetails: React.FC<MyDetailsProps>  = observer(({userViewModel}: MyDetailsProps) => {


    const [userDetails, setUserDetails] = useState<UserV1| undefined>(undefined);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [editedDetails, setEditedDetails] = useState({
        fullName: '',
        phoneNumber: '',
    });
    const handleSaveEdit = () => {
        // Save the edited details here (e.g., make an API request)
        // Then close the dialog
        setShowEditDialog(false);
    };

    const handleEditClick = () => {
        setEditedDetails({
            fullName: userDetails?.full_name || '',
            phoneNumber: userDetails?.phone_number || '',
        });
        setShowEditDialog(true);
    };

    useEffect(() => {
        userViewModel.handleGetMyUserAccount()

    }, []);

    useEffect(() => {
        const userAccount : UserV1| undefined=  userViewModel.getUserData

        setUserDetails( userAccount)

        setEditedDetails({
            fullName: userAccount?.full_name || '',
            phoneNumber: userAccount?.phone_number || '',
        });
    }, [userViewModel.getUserData]);

    return (
        <Container className="d-flex justify-content-center align-items-center vh-80">
            <Card style={{ width: '25rem', padding: '1rem' }}>
                <Card.Title className="text-center">User Details</Card.Title>
                <Card.Body>
                    <h5>Contact number</h5>
                    {userDetails?.phone_number ? (
                        <>
                            <p>{userDetails.phone_number}</p>
                            <hr />
                        </>
                    ) : (
                        <p>Add a phone number</p>
                    )}

                    <h5>Login email</h5>
                    {FirebaseApp.getInstance()?.getFirebaseAuth()?.currentUser?.email ? (
                        <p>{FirebaseApp.getInstance()?.getFirebaseAuth()?.currentUser?.email}</p>
                    ) : (
                        <p>Email not provided</p>
                    )}

                    <h5>Password</h5>
                    <p>*********</p>


                    <Button variant="primary" onClick={handleEditClick} >Edit Contact Details</Button>
                </Card.Body>
            </Card>

            {/* Edit Details Dialog */}
            <Modal show={showEditDialog} onHide={() => setShowEditDialog(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Contact Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="fullName">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter full name"
                                value={editedDetails.fullName}
                                onChange={(e) =>
                                    setEditedDetails({
                                        ...editedDetails,
                                        fullName: e.target.value,
                                    })
                                }
                            />
                        </Form.Group>

                        <Form.Group controlId="phoneNumber">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter phone number"
                                value={editedDetails.phoneNumber}
                                onChange={(e) =>
                                    setEditedDetails({
                                        ...editedDetails,
                                        phoneNumber: e.target.value,
                                    })
                                }
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditDialog(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSaveEdit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>

    );
});

export default MyDetails;