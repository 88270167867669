import {PostData} from "@domain/models/post/PostResponseV1";
import {useCallback, useState} from "react";
import {GetPostsInHistoryUseCase} from "@domain/usecases/search/GetPostsInHistoryUseCase";


export interface SearchPostHistoryViewState {
    postResultData?: PostData[] | null,
    isLoading?: boolean;
    error?: string | null;
}

export const useGetPostsFromHistoryViewModel = (getPostsFromHistory: GetPostsInHistoryUseCase) => {
    const [statePostHistory, setStatePostHistory] = useState<SearchPostHistoryViewState>({ isLoading: false });

    const getPostFromHistory = useCallback(() => {
        setStatePostHistory({ isLoading: true });
        const postResultData = getPostsFromHistory.execute();
        setStatePostHistory({ postResultData: postResultData, isLoading: false, error: null });
    }, []);

    return {
        statePostHistory,
        getPostFromHistory,
        setStatePostHistory
    };
};