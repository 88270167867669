import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import playStoreImg from "@assets/playStore.svg";
import iosStoreImg from "@assets/ios_storage_icon.svg";
import bgImage from "@assets/bg_reviews.svg";

import "./AccessApp.scss";

const AccessApp: React.FC = (): JSX.Element => {
    return (
        <div className="access-app">
            <h5 className="access-app__title">Access anything on the go</h5>

            <div
                className="access-app__content"
                style={{
                    backgroundImage: `url(${bgImage})`,
                }}
            >
                <Row className="justify-content-between align-items-center">
                    <Col md={12}>
                        <h6 className="access-app__content__title">
                            Message and rent at the tap of a button.
                        </h6>

                        <p className="access-app__content__desc">
                            The Rentallo app is the easiest way to find what you
                            need, manage your rentals and purchases and get
                            instant updates. Get it now on Android.
                        </p>
                    </Col>
                    <Col className="mt-3 ">
                        <Image
                            src={playStoreImg}
                            width="160px"
                            height="48px"
                            alt="logo"
                        />
                        <Image
                            src={iosStoreImg}
                            className="ms-4"
                            width="144px"
                            height="48px"
                            alt="logo"
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default AccessApp;
