import SearchRepository from "@domain/repository/search/ISearchRepository";
import {PostData} from "@domain/models/post/PostResponseV1";

export class GetPostsInHistoryUseCase {

    private searchRepository: SearchRepository

    public constructor(searchRepository: SearchRepository) {
        this.searchRepository = searchRepository
    }

    execute() : PostData[] {
        return this.searchRepository.getSearchHistory();
    }
}