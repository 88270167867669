import ILocationRepository from "@domain/repository/location/ILocationRepository";
import {ApiUtilsUtils} from "@utils/ApitUtils";
import {PLACES_API_ID, POSTS_API_ID_KEY} from "@domain/models/startup/ServiceId";
import {Startup} from "@domain/models/startup/Startup";
import api from "@data/Api";

export default class LocationApi implements ILocationRepository {
    private startupConfig: Startup | undefined

    constructor(startupConfig: Startup | undefined) {
        this.startupConfig = startupConfig
    }

    getLocationSuggestions(query?: string): Promise<LocationData[] | undefined> {
        return new Promise((resolve, reject) => {
            // Get the service endpoint URL
            let url = ApiUtilsUtils.getServiceEndPointByServiceId(this.startupConfig, PLACES_API_ID);

            // Make the GET request with Axios
            api.get(`${url}?q=${query}`)
                .then(response => {
                    const locationResponse: LocationResponse = response.data; // Axios automatically parses JSON
                    resolve(locationResponse.data);
                })
                .catch(error => {
                    console.error("[getLocationSuggestions] Error in response", error);
                    reject(new Error(error));
                });
        });
    }


}