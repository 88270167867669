import {PostResultData} from "@domain/models/post/PostResponseV1";
import SearchUseCase from "@domain/usecases/search/SearchUseCase";
import {useCallback, useEffect, useState} from "react";


export interface SearchPostViewState {
    postResultData?: PostResultData | null,
    isLoading?: boolean;
    error?: string | null;
}

export const useSearchViewModel = (searchUserCase: SearchUseCase) => {
    const [state, setState] = useState<SearchPostViewState>({ isLoading: false });

    const searchPosts = useCallback((categoryId: string, params: Map<string, string>) => {
        setState({ isLoading: true });
            searchUserCase.searchPostResult(categoryId, params)
            .then(postResultData => {
                setState({ postResultData, isLoading: false, error: null });
            })
            .catch(error => {
                setState({ error: 'Error fetching posts', isLoading: false });
            });
    }, [searchUserCase]);

    return {
        stateSearch: state,
        searchPosts
    };
};