import React from "react";
import { Trans, useTranslation } from "react-i18next";
import R from "@strings/R";

import "./SearchParagraph.scss";

const SearchParagraph = () => {
    const { t } = useTranslation();

    return (
        <Trans
            defaults={t(R.landingPage_search_lb_title) as string} // optional defaultValue
            values={{ name: t("landing_anything") }}
            components={{
                normal: <span className="search-paragraph" />,
                h: <span className="search-paragraph search-paragraph--bold" />,
            }}
        />
    );
};

export default SearchParagraph;
