import { EventBus } from "@domain/models/eventBus/EventBus"
import React, { useEffect, useRef, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import InputSelect from "../InputSelect"
 import { useTranslation } from "react-i18next"
import R from "@strings/R"
import { Attribute } from "@domain/models/catalog/CatalogModel"

interface InputListRange{
  attribute: Attribute
}

export function InputListRange({attribute}: InputListRange): React.ReactElement {
  const [showInput, setShowInput] = useState(false)
  const [uuid, setUUID] = useState("")
  const {t} = useTranslation()

  const [filterSelected, setFilterSelected] = useState("Any")


  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let randomUUID = (Math.random() + 1).toString(36).substring(7);
    setUUID(randomUUID)

    const registry = EventBus.getInstance().register(randomUUID, (filterValue: string) => {
      setFilterSelected(filterValue)
    });


    function windowsClick(e: any) {
      if (ref.current && !ref.current.contains(e.target)) {
        setShowInput(false)
      }
    }
    if (window) {
       window.addEventListener('click', windowsClick, false);
    }
    return () => {
      registry.unregister()
      window.removeEventListener('click', windowsClick)
    }
  }, [])

  function handleClick(e: React.MouseEvent<HTMLLIElement>) {
    e.preventDefault();
    setShowInput(true)
  }

  return <Container ref={ref} onClick={(e: React.MouseEvent<HTMLLIElement>) => { handleClick(e) }}  >
    <Row className="form-select  gx-0 mx-0"  >
      {filterSelected}
    </Row>
    {showInput ? <Row className="mt-4  bg-white position-absolute gx-0 mx-0 mt-1 p-3 border border-secondary">
      <Row className="gx-0 mx-0"  >
        
        <Col md="auto">
          <span >{t(R.catalog_attribute_min_price)}</span>
          <InputSelect  attr={attribute}  />
        </Col>
        <Col className="gx-3" md="auto">
        <span >{t(R.catalog_attribute_max_price)}</span>
          <InputSelect    attr={attribute}  />
        </Col>

      </Row>
    </Row> : null}
  </Container>
}

export default InputListRange