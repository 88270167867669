import {Startup} from "@domain/models/startup/Startup";
export class ApiUtilsUtils {

    static getServiceEndPointByServiceId(startupConfig: Startup| undefined, serviceId: string): string | undefined {
        return process.env.REACT_APP_BASE_URL!! + startupConfig?.services?.find(it => it.apiServiceId === serviceId)?.serviceEndPoint;
    }

    static getServiceEndPointByServiceIdUsingElastic(startupConfig: Startup| undefined, serviceId: string): string | undefined {
        return "https://api.elastic.rentallo.app/"!! + startupConfig?.services?.find(it => it.apiServiceId === serviceId)?.serviceEndPoint;
    }
}