import React, { useEffect, useState } from "react";
import PostViewModel from "@viewmodels/PostViewModel";

import { observer } from "mobx-react";
import { Col, Row } from "react-bootstrap";
import CardView from "@presentation/views/components/CardView";
import { useInjection } from "../../../../../ioc.react";
import { IProvider } from "@di/viewmodels/ViewModelProvider";
import TYPES from "@di/viewmodels/ViewModelTypes";
import { PostData } from "@domain/models/post/PostResponseV1";

import "bootstrap-icons/font/bootstrap-icons.css";

interface PostsProps {
    _postViewModel: PostViewModel;
}

export const Posts: React.FC = (): JSX.Element => {
    const postViewModel = useInjection<IProvider<PostViewModel>>(
        TYPES.PostViewModel
    ).provide();

    useEffect(() => {
        postViewModel.getMyPosts();
    }, []);

    const handleDeleteClick = (post: PostData) => {
        postViewModel.deleteMyPost(post.id);
    };

    const MyPostView = observer(({ _postViewModel }: PostsProps) => {
        return (
            <Row className="gx-0 mt-4">
                <p
                    className="h4 font-weight-bold"
                    style={{
                        fontFamily: "Inter",
                        fontSize: "40px",
                        fontWeight: "700",
                        lineHeight: "36px",
                    }}
                >
                    My Posts
                </p>
                <Row className="gy-2 gx-0 pb-5">
                    {_postViewModel?.isLoadingRequest ? (
                        Array.from(new Array(4)).map((it, index) => (
                            <Col md={3} key={index}>
                                <CardView isLoading={true} />
                            </Col>
                        ))
                    ) : _postViewModel.getMyPostsData()?.meta?.total == 0 ? (
                        <div>0 results</div>
                    ) : (
                        _postViewModel.getMyPostsData()?.data?.map((post) => (
                            <Col
                                md={3}
                                style={{ paddingRight: "25px" }}
                                key={post.id}
                            >
                                <div style={{ position: "relative" }}>
                                    <CardView post={post} id="new rail" />
                                    <button
                                        onClick={() => handleDeleteClick(post)}
                                        className="btn btn-danger"
                                        style={{
                                            position: "absolute",
                                            top: "5px",
                                            right: "5px",
                                            zIndex: 1,
                                        }}
                                    >
                                        <i className="bi bi-trash"></i> Delete
                                    </button>
                                </div>
                            </Col>
                        ))
                    )}
                </Row>
            </Row>
        );
    });

    return (
        <>
            <MyPostView _postViewModel={postViewModel}></MyPostView>
        </>
    );
};
