import React, {useEffect} from "react";
import PostViewModel from "@viewmodels/PostViewModel";
import {observer} from "mobx-react";
import {Col, Row} from "react-bootstrap";
import CardView from "@presentation/views/components/CardView";
import {PostData} from "@domain/models/post/PostResponseV1";

interface PostsProps {
    postViewModel: PostViewModel
}

const Favourites: React.FC<PostsProps> = observer(({postViewModel}: PostsProps) => {


    useEffect(() => {
        postViewModel.getMyFavourites()
    }, [postViewModel.favouriteDelete]);

    const handleDeleteClick = (post: PostData) => {
        postViewModel.deleteMyFavourite(post.id)
    };


    return (
        <Row className="gx-0 mt-4">
            <p
                className="h4 font-weight-bold"
                style={{
                    fontFamily: 'Inter',
                    fontSize: '40px',
                    fontWeight: '700',
                    lineHeight: '36px',
                }}
            >
                My Favourites
            </p>
            <Row className="gy-2 gx-0 pb-5">
                {(postViewModel?.isLoadingRequest) ? (
                    Array.from(new Array(4)).map((it, index) => (
                        <Col md={3} key={index}>
                            <CardView isLoading={true}/>
                        </Col>
                    ))
                ) : (
                    (postViewModel.getFavouritesData()?.total == 0) ? (
                        <div>0 results</div>
                    ) : (

                        postViewModel.getFavouritesData()?.data?.map((favouriteData) => (
                            <Col md={3} style={{paddingRight: '25px'}} key={favouriteData.post.id}>
                                <div style={{position: 'relative'}}>
                                    <CardView post={favouriteData.post} id="new rail"/>
                                    <button
                                        onClick={() => handleDeleteClick(favouriteData.post)}
                                        className="btn btn-danger"
                                        style={{
                                            position: 'absolute',
                                            top: '5px',
                                            right: '5px',
                                            zIndex: 1,
                                        }}
                                    >
                                        <i className="bi bi-trash"></i> Delete
                                    </button>
                                </div>

                            </Col>
                        ))
                    )
                )}

            </Row>
        </Row>

    )


});
export default Favourites;