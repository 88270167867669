import {PostData, PostFavouritesResult, PostResultData} from "@domain/models/post/PostResponseV1";
import IPostRepository from "@domain/repository/post/IPostRepository";
import {Startup} from "@domain/models/startup/Startup";
import {FAVORITE_POSTS_API_ID, POSTS_API_ID_KEY, RAIL_KEY, SEARCHES_API_ID} from "@domain/models/startup/ServiceId";
import {TOKEN_STORE_KEY} from "@utils/Constants";
import {PostCreateV1} from "@domain/models/post/PostCreateV1";
import {ApiUtilsUtils} from "@utils/ApitUtils";
import api from "@data/Api";


export default class PostApi implements IPostRepository {

    private startupConfig: Startup | undefined

    constructor(startupConfig: Startup | undefined) {
        this.startupConfig = startupConfig
    }

    deletePost(postId: number): Promise<boolean> {

        const token = localStorage.getItem(TOKEN_STORE_KEY) ?? '';

        const url = ApiUtilsUtils.getServiceEndPointByServiceId(this.startupConfig, POSTS_API_ID_KEY) + `/${postId}`;

        return api.delete(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        })
            .then(response => {
                console.log("[deletePost] response success");
                return true;
            })
            .catch(error => {
                console.error("[deletePost] error in response", error);
                throw new Error(error);
            });
    }

    getMyPosts(): Promise<PostResultData> {
        const token = localStorage.getItem(TOKEN_STORE_KEY) ?? '';

        const url = ApiUtilsUtils.getServiceEndPointByServiceId(this.startupConfig, POSTS_API_ID_KEY) + '?page=1';

        return api.get(url, {
            headers: {
                 'Authorization': token
            }
        })
            .then(response => {
                console.log("[getMyPosts] response success");
                return response.data;  // Return the response data (which would be the JSON body)
            })
            .catch(error => {
                console.error("[getMyPosts] error in response", error);
                throw new Error(error);
            });
    }

    createFavourite(postId: string): Promise<boolean> {
        const token = localStorage.getItem(TOKEN_STORE_KEY) ?? '';

        const url = ApiUtilsUtils.getServiceEndPointByServiceId(this.startupConfig, FAVORITE_POSTS_API_ID);

        return api.post(url!!,
            {
                post_id: postId
            },
            {
                headers: {
                     'Authorization': token
                }
            }
        )
            .then(response => {
                console.log("[favoritePost] response success");
                return true;
            })
            .catch(error => {
                console.error("[favoritePost] error in response", error);
                throw new Error(error);
            });
    }

    deleteFavourite(postId: number): Promise<boolean> {
        const token = localStorage.getItem(TOKEN_STORE_KEY) ?? '';

        const url = ApiUtilsUtils.getServiceEndPointByServiceId(this.startupConfig, FAVORITE_POSTS_API_ID) + `/${postId}`;

        return api.delete(url, {
            headers: {
                 'Authorization': token
            }
        })
            .then(response => {
                console.log("[deleteFavoritePost] response success");
                return true;
            })
            .catch(error => {
                console.error("[deleteFavoritePost] error in response", error);
                throw new Error(error);
            });
    }

    getMyFavourites(): Promise<PostFavouritesResult> {
        const token = localStorage.getItem(TOKEN_STORE_KEY) ?? '';

        const url = ApiUtilsUtils.getServiceEndPointByServiceId(this.startupConfig, FAVORITE_POSTS_API_ID);

        return api.get(url!!, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        })
            .then(response => {
                console.log("[getFavoritePosts] response success");
                return response.data;  // Axios automatically parses JSON and puts it in response.data
            })
            .catch(error => {
                console.error("[getFavoritePosts] error in response", error);
                throw new Error(error);
            });
    }

    getPostDetails(postId: string): Promise<PostData> {

        const url = ApiUtilsUtils.getServiceEndPointByServiceId(this.startupConfig, POSTS_API_ID_KEY) + `/${postId}`;

        return api.get(url)
            .then(response => {
                console.log("[getPost] response success");
                return response.data;  // Axios automatically parses JSON and puts it in response.data
            })
            .catch(error => {
                console.error("[getPost] error in response", error);
                throw new Error(error);
            });
    }

    createPost(postData: PostCreateV1): Promise<number> {
        const token = localStorage.getItem(TOKEN_STORE_KEY) ?? '';
        const url = ApiUtilsUtils.getServiceEndPointByServiceId(this.startupConfig, POSTS_API_ID_KEY);

        return api.post(url!!, postData, {
            headers: {
                 'Authorization': token
            }
        })
            .then(response => {
                console.log("[createPost] response success");
                return response.data;  // Axios automatically parses JSON and puts it in response.data
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    // Axios error object contains a response property with error details
                    console.error("[createPost] error in response", error.response.data);
                    throw new Error(error.response.data);
                } else {
                    // For network errors or other issues
                    console.error("[createPost] error in response", error.message);
                    throw new Error(error.message);
                }
            });
    }

}