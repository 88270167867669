import React from "react";
import { Row } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import R from "@strings/R";
import SearchParagraph from "../SearchParagraph/SearchParagraph";

import "./SearchText.scss";

export function SearchText() {
    const { t, i18n } = useTranslation();

    return (
        <div className="search-text">
            <Row className="ms-0">
                <h2 className="search-text__subtitle">
                    {t(R.landingPage_search_lb_sub_title)}
                </h2>

                <p className="search-text__label">
                    <SearchParagraph />
                </p>
            </Row>
        </div>
    );
}
