import "./Cities.scss"
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import R from "@strings/R";
import SearchInput from "@presentation/views/components/Search/SearchExperience/SearchInput/SearchInput";
import {ListGroup} from "react-bootstrap";
import EmptyCities from "@presentation/views/components/Search/EmptyCities/EmptyCities";
import "./Cities.scss"
import point from "@assets/small_pin_ic.svg";

import {LocationDomain} from "@domain/models/search/SugestionType";
import {useInjection} from "@root/ioc.react";
import {IProvider} from "@di/viewmodels/ViewModelProvider";
import SearchLocationUseCase from "@domain/usecases/location/SearchLocationUseCase";
import TYPES from "@di/viewmodels/ViewModelTypes";
import {useSearchLocationViewModel} from "@root/framework/hooks/useSearchLocationViewModel";

export interface CitiesProp {
    onBackPressed: (event: React.MouseEvent) => void
    handleClickLocation: (isFromCities: boolean ,locationDomain: LocationDomain) => void
 }

const Cities: React.FC<CitiesProp> = ({onBackPressed , handleClickLocation}) => {
    const {t} = useTranslation();
    const searchLocationUseCase = useInjection<IProvider<SearchLocationUseCase>>(TYPES.SearchLocationUseCase).provide()
    const { stateLocation, searchLocation} = useSearchLocationViewModel(searchLocationUseCase);

    function onInputChange(query: string) {
        searchLocation(query)
    }

    useEffect(() => {
        searchLocation("")
    }, []);

    return <>
        <button onClick={onBackPressed} key="-2" className="se-button search-cities back-button">
            <div className="search-cities arrow"><span className="search-cities bt-text">Back</span></div>
        </button>
        <p className="search-cities  title">{t(R.searchResults_searchCities_title)}</p>
        <SearchInput onQueryChange={onInputChange} placeHolder={t(R.searchResults_searchCities_placeHolder)}/>

        {stateLocation?.locationData?.length != 0 ?
            (<ListGroup className="search-cities list-group">
                {stateLocation?.locationData?.slice(0, 8).map((location, index) => (
                    <ListGroup.Item key={index} onClick={(e) => {
                        e.stopPropagation();
                        handleClickLocation(true, location)
                    }
                    }>
                        <div className="search-cities row-result">
                            <img className="search-cities point-img" src={point} alt="point-icon"/><span
                            className="search-cities city-text">{location.name}, {location.level1} {location.level2}</span>
                        </div>
                    </ListGroup.Item>))
                }
            </ListGroup>) : <EmptyCities/>
        }


    </>
};

export default Cities;