import React, { useEffect, useRef, useState } from "react";
import "firebase/storage";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import DynamicForm from "@presentation/views/components/DynamicForm/DynamicForm";
import PostViewModel from "@viewmodels/PostViewModel";
import { ImageV1, PostCreateV1 } from "@domain/models/post/PostCreateV1";
import ImageUploader from "@utils/ImageUploader";
import GooglePlacesUtils, { Type, ValueType } from "@utils/GooglePlacesUtils";
import TextInput from "@presentation/views/components/TextInput/TextInput";
import TextAreaInput from "@presentation/views/components/TextAreaInput/TextAreaInput";
import { observer } from "mobx-react";
import { CircularProgress, Dialog } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Path } from "@enums/Path";
import { CatalogAttribute } from "@domain/models/post/PostResponseV1";
import PlaceResult = google.maps.places.PlaceResult;
import getStartupConfig from "@utils/remote_config/StartUpConfig";

export interface CreatePostProps {
    postViewModel: PostViewModel;
}

const CreatePost: React.FC<CreatePostProps> = observer(
    ({ postViewModel }: CreatePostProps) => {
        const [title, setTitle] = useState("");
        const [description, setDescription] = useState("");
        const [images, setImages] = useState<File[]>([]);
        const [catalogAttributes, setCatalogAttributes] = useState<string>("");
        const [contactByChat, setContactByChat] = useState(true);
        const [imagePreviews, setImagePreviews] = useState<string[]>([]);
        const [locationPlaceResult, setLocationPlaceResult] =
            useState<PlaceResult | null>(null);

        const [descriptionError, setDescriptionError] = useState<string | null>(
            null
        );
        const [titleError, setTitleError] = useState<string | null>(null);
        const [locationError, setLocationError] = useState<string | null>(null);

        const characterCount = description.length;
        const maxCharacters = 10000;

        const locationInputRef = useRef<HTMLInputElement | null>(null);
        const navigate = useNavigate();
        const [errorDialog, setErrorDialog] = useState(false);
        const [catalogValidation, setCatalogValidation] = useState(false);

        const [priceKey, setPriceKey] = useState("");

        useEffect(() => {
            const infoStartupConfig = getStartupConfig();
            console.log(
                "av countries " +
                    infoStartupConfig?.localization.availableCountries
            );

            // Initialize the Google Places Autocomplete
            const autocomplete = new window.google.maps.places.Autocomplete(
                locationInputRef.current!,
                {
                    types: ["geocode"],
                    componentRestrictions: {
                        country:
                            infoStartupConfig?.localization
                                .availableCountries ?? [],
                    }, // Specify the country code here
                }
            );

            // Listen for the "place_changed" event to get the selected locationAddress
            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                if (place && place.formatted_address) {
                    setLocationPlaceResult(place);
                }
            });

            if (postViewModel.postCreated()) {
                navigate(Path.POSTS);
            }
            if (postViewModel.error) {
                setErrorDialog(true);
            } else {
                setErrorDialog(false);
            }
        }, [postViewModel.postCreated(), postViewModel.error]);

        const handleImageUpload = async (
            e: React.ChangeEvent<HTMLInputElement>
        ) => {
            if (e.target.files) {
                const selectedImages = Array.from(e.target.files);
                setImages([...images, ...selectedImages]);

                const previewUrls = selectedImages.map((image) =>
                    URL.createObjectURL(image)
                );
                setImagePreviews([...imagePreviews, ...previewUrls]);
            }
        };

        const handleCheckboxChange = (
            event: React.ChangeEvent<HTMLInputElement>
        ) => {
            setContactByChat(event.target.checked);
        };

        const removeImage = (index: number) => {
            const updatedImages = [...images];
            const updatedPreviews = [...imagePreviews];

            updatedImages.splice(index, 1);
            updatedPreviews.splice(index, 1);

            setImages(updatedImages);
            setImagePreviews(updatedPreviews);
        };

        const onFormChange = (jsonString: string, priceKey: string) => {
            // Handle the updated JSON data here
            setCatalogAttributes(jsonString);
            setPriceKey(priceKey);
        };

        const onValidation = (failed: boolean) => {
            setCatalogValidation(failed);
        };

        const handleDescriptionValidation = (e: {
            target: {
                value: any;
            };
        }) => {
            const newValue = e.target.value;
            const isValidText =
                newValue.trim() !== "" && newValue.split(/\s+/).length > 11;

            if (!isValidText) {
                setDescriptionError(
                    "Description must not be empty and contain more than 12 words."
                );
            } else {
                setDescriptionError(null);
            }
        };

        const handleTitleValidation = (e: {
            target: {
                value: any;
            };
        }) => {
            const newValue = e.target.value;
            const isValidText =
                newValue.trim() !== "" && newValue.split(/\s+/).length > 3;
            if (!isValidText) {
                setTitleError(
                    "Title must not be empty and contain more than 4 words."
                );
            } else {
                setTitleError(null);
            }
        };
        const handleLocationValidation = (e: {
            target: {
                value: any;
            };
        }) => {
            const newValue = e.target.value;
            const isValidText = newValue.trim() !== "";
            if (!isValidText) {
                setLocationError("Location must not be empty.");
            } else {
                setLocationError(null);
            }
        };

        const convertToHtml = (text: string): string => {
            const lines = text.split(/\n/);
            const htmlText = lines
                .map((line, index) => {
                    if (line.trim() === "") {
                        return "<br>";
                    }
                    return line + "<br>";
                })
                .join("");
            return htmlText;
        };
        const handleSubmit = async (e: React.FormEvent) => {
            e.preventDefault();

            if (
                titleError ||
                descriptionError ||
                locationError ||
                catalogValidation ||
                images.length === 0
            ) {
                window.scrollTo(0, 0);
                return;
            }

            postViewModel.isLoadingRequest = true;

            const imageUrls: ImageV1[] = await ImageUploader.uploadImages(
                images
            );

            try {
                const descriptionInHtml = convertToHtml(description);

                const ctgAttributes: CatalogAttribute[] =
                    JSON.parse(catalogAttributes);
                const post: PostCreateV1 = {
                    title: title,
                    description: descriptionInHtml,
                    images: imageUrls,
                    place: {
                        address: locationPlaceResult?.formatted_address ?? "",
                        admin:
                            GooglePlacesUtils.getComponentValue(
                                locationPlaceResult,
                                Type.ADMIN_AREA
                            ) ?? "",
                        sub_admin:
                            GooglePlacesUtils.getComponentValue(
                                locationPlaceResult,
                                Type.ADMIN_AREA
                            ) ?? "",
                        country_iso_code:
                            GooglePlacesUtils.getComponentValue(
                                locationPlaceResult,
                                Type.COUNTRY,
                                ValueType.SHORT_NAME
                            ) ?? "",
                        country_name:
                            GooglePlacesUtils.getComponentValue(
                                locationPlaceResult,
                                Type.COUNTRY
                            ) ?? "",
                        postal_code:
                            GooglePlacesUtils.getComponentValue(
                                locationPlaceResult,
                                Type.POST_CODE
                            ) ?? "",
                        lat: locationPlaceResult?.geometry?.location.lat() ?? 0,
                        lon: locationPlaceResult?.geometry?.location.lng() ?? 0,
                    },

                    post_meta: {
                        catalog_id: "CAT-1",
                        sub_catalog_id: undefined,
                        category_path: [],
                        price_period: priceKey,
                        currency_iso_code: "AED",
                        catalog_attributes: ctgAttributes,
                    },
                    contact_way: {
                        by_chat: contactByChat,
                        by_phone: true,
                    },
                };

                postViewModel.createPost(post);
            } catch (error) {
                // Handle any errors from the API
                console.error(error);
            }
        };

        return (
            <Container>
                <Dialog open={postViewModel.isLoadingRequest}>
                    <CircularProgress className="m-2" />
                </Dialog>

                <Dialog
                    open={errorDialog}
                    onClose={() => {
                        setErrorDialog(false);
                    }}
                >
                    <div className="m-4">
                        Something went wrong, please try again later.
                    </div>
                </Dialog>

                <Row className="justify-content-center">
                    <Col md={6} className="mt-4 mx-auto">
                        <h1>Create a Post</h1>
                        <Form onSubmit={handleSubmit} className="mt-4">
                            <TextInput
                                mandatory={true}
                                label="Title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                onBlur={handleTitleValidation}
                                error={titleError}
                            />

                            <TextAreaInput
                                label="Description"
                                mandatory={true}
                                value={description}
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    // Check if the length of the input is within the desired limit (e.g., 10,000 characters)
                                    if (newValue.length <= 10000) {
                                        console.log("description" + newValue);

                                        setDescription(newValue);
                                    }
                                }}
                                onBlur={handleDescriptionValidation}
                                error={descriptionError}
                            />
                            <div className="text-muted">{`${characterCount}/${maxCharacters}`}</div>

                            <TextInput
                                label="Location"
                                mandatory={true}
                                inputRef={locationInputRef}
                                onBlur={handleLocationValidation}
                                error={locationError}
                            />

                            <Form.Group className="mt-2">
                                <div
                                    style={{
                                        backgroundColor: "#f0f0f0", // Gray background color
                                        padding: "10px",
                                        borderRadius: "5px",
                                        textAlign: "left", // Align content to the left
                                    }}
                                >
                                    <Form.Label>
                                        Images (Accepted formats: png, jpg):
                                    </Form.Label>
                                    <span className="text-danger">*</span>

                                    <input
                                        type="file"
                                        accept=".png, .jpg"
                                        multiple
                                        onChange={handleImageUpload}
                                        style={{ display: "none" }}
                                        id="image-upload-input"
                                    />
                                    <label
                                        htmlFor="image-upload-input"
                                        className="btn btn-primary ms-5"
                                    >
                                        Choose Images
                                    </label>

                                    {images && images.length === 0 && (
                                        <div
                                            className="text-danger"
                                            style={{ fontSize: "smaller" }}
                                        >
                                            You need to upload at least one
                                            image.
                                        </div>
                                    )}
                                </div>
                            </Form.Group>
                            <Form.Group className="mt-2">
                                <Row>
                                    {imagePreviews.map((preview, index) => (
                                        <Col
                                            key={index}
                                            xs={6}
                                            md={3}
                                            className="mb-3"
                                        >
                                            <Image
                                                src={preview}
                                                alt="Preview"
                                                fluid
                                            />
                                            <Button
                                                className="mt-2"
                                                variant="danger"
                                                onClick={() =>
                                                    removeImage(index)
                                                }
                                            >
                                                Remove
                                            </Button>
                                        </Col>
                                    ))}
                                </Row>
                            </Form.Group>

                            <Form.Group>
                                <Row md={8}>
                                    <DynamicForm
                                        onValidation={onValidation}
                                        onFormChange={onFormChange}
                                    />
                                </Row>
                            </Form.Group>
                            <Form.Group className="mt-2">
                                <Form.Label>Contact Way:</Form.Label>
                                <Form.Check
                                    type="checkbox"
                                    label="I would like to add chat (via app) as a contact option"
                                    checked={contactByChat}
                                    onChange={handleCheckboxChange}
                                />
                            </Form.Group>

                            <Form.Group className="mt-2">
                                <Row className="mt-4 ">
                                    <Col xs={12} md={6} className="mb-3">
                                        <div
                                            style={{
                                                backgroundColor: "#f0f0f0", // Gray background color
                                                padding: "10px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            By creating this post, you are
                                            agreeing to our{" "}
                                            <a
                                                href="/terms-of-service"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Terms of Service
                                            </a>{" "}
                                            and{" "}
                                            <a
                                                href="/privacy"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Privacy Policy
                                            </a>{" "}
                                            regarding the processing of your
                                            data.
                                        </div>
                                    </Col>
                                    <Col
                                        xs={2}
                                        md={6}
                                        className="text-center justify-content-end"
                                    >
                                        <Button
                                            onClick={handleSubmit}
                                            type="submit"
                                        >
                                            Create Post
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Container>
        );
    }
);

export default CreatePost;
