import React, {useEffect, useState} from 'react';
import Recommendations from "@presentation/views/components/Search/SearchExperience/Recommendations/Recommendations";
import {Trans, useTranslation} from "react-i18next";
import R from "@strings/R";
import "./SearchExperienceContainer.scss"
import SearchInput from "@presentation/views/components/Search/SearchExperience/SearchInput/SearchInput";
import ScrollableButtonGroup
    from "@presentation/views/components/Search/SearchExperience/ScrollableButtonGroup/ScrollableButtonGroup";
import RecentSearch from "@presentation/views/components/Search/SearchExperience/RecentSearch/RecentSearch";
import SearchResult from "@presentation/views/components/Search/SearchExperience/SearchResults/SearchResult";
import Cities from "@presentation/views/components/Search/SearchExperience/Cities/Cities";
import {LocationDomain} from "@domain/models/search/SugestionType";
import UrlUtils from "@utils/UrlUtils";
import {useLocation} from "react-router-dom";
import {SearchKeys} from "@enums/SearchKeys";
import {PostData} from "@domain/models/post/PostResponseV1";
import {useInjection} from "@root/ioc.react";
import {IProvider} from "@di/viewmodels/ViewModelProvider";
import SearchUseCase from "@domain/usecases/search/SearchUseCase";
import TYPES from "@di/viewmodels/ViewModelTypes";
import {useSearchViewModel} from "@root/framework/hooks/useSearchViewModel";
import SearchLocationUseCase from "@domain/usecases/location/SearchLocationUseCase";
import {useSearchLocationViewModel} from "@root/framework/hooks/useSearchLocationViewModel";
import {useSavePostInHistoryViewModel} from "@root/framework/hooks/useSavePostInHistoryViewModel";
import {SavePostInHistoryUseCase} from "@domain/usecases/search/SavePostInHistoryUseCase";
import {SaveCurrentLocationUseCase} from "@domain/usecases/search/SaveCurrentLocationUseCase";
import {GetCurrentLocationUseCase} from "@domain/usecases/search/GetCurrentLocationUseCase";
import {useSaveCurrentLocationViewModel} from "@root/framework/hooks/useSaveCurrentLocationViewModel";
import {useGetCurrentLocationViewModel} from "@root/framework/hooks/useGetCurrentLocationViewModel";


interface SearchResultsDialogProps {
    clazz?: string;
    show: boolean;
    onHide: () => void;
    updateQueryParam: (body: any) => void
}

function onPostClicked(onHide: () => void, updateQueryParam: (body: any) => void, postData: PostData) {
    onHide()
    updateQueryParam({
        [SearchKeys.QUERY_SEARCH_KEY]: postData.title,
        [SearchKeys.LAT_SEARCH_KEY]: postData.place?.lat,
        [SearchKeys.LON_SEARCH_KEY]: postData.place?.lon,
    })
}

const SearchExperienceContainer: React.FC<SearchResultsDialogProps> = ({
                                                                           show,
                                                                           onHide,
                                                                           clazz,
                                                                           updateQueryParam
                                                                       }) => {

    const inputSearchExperienceRef = React.useRef<HTMLInputElement>(null)
    const {t} = useTranslation();
    const [showSuggestionSearchList, setShowSuggestionSearchList] = useState(true)
    const [showSearchCitiesContainer, setShowSearchCitiesContainer] = useState(false)

    const [selectedCity, setSelectedCity] = useState<string | undefined>(undefined)
    const location = useLocation();

    const searchLocationUseCase = useInjection<IProvider<SearchLocationUseCase>>(TYPES.SearchLocationUseCase).provide()
    const searchUseCase = useInjection<IProvider<SearchUseCase>>(TYPES.SearchUseCase).provide()
    const savePostsInHistoryUseCase = useInjection<IProvider<SavePostInHistoryUseCase>>(TYPES.SavePostInHistoryUseCase).provide()

    const saveCurrentLocationUseCase = useInjection<IProvider<SaveCurrentLocationUseCase>>(TYPES.SaveCurrentLocationUseCase).provide()
    const getCurrentLocationUseCase = useInjection<IProvider<GetCurrentLocationUseCase>>(TYPES.GetCurrentLocationUseCase).provide()


    const {stateLocation, searchLocation} = useSearchLocationViewModel(searchLocationUseCase);

    const {stateSaveCurrentLocation, saveCurrentLocation} = useSaveCurrentLocationViewModel(saveCurrentLocationUseCase);

    const {stateGetCurrentLocation, getCurrentLocation} = useGetCurrentLocationViewModel(getCurrentLocationUseCase);


    const {stateSearch, searchPosts} = useSearchViewModel(searchUseCase);
    const {stateSavePostHistory, savePostInHistory} = useSavePostInHistoryViewModel(savePostsInHistoryUseCase);
    const catalogId = "CAT-1";


    const handleClickOutside = (event: MouseEvent) => {
        if (inputSearchExperienceRef.current && !inputSearchExperienceRef.current.contains(event.target as Node)) {
            onHide()
        }
    };


    const onInputChange = (query: string) => {
        const isEmpty = query.trim() == ""
        setShowSuggestionSearchList(isEmpty)
        const paramsMap = UrlUtils.getParamsMap(location.search)
        const currentLocation = stateGetCurrentLocation.locationDomain
        //Set the query
        paramsMap.set(SearchKeys.QUERY_SEARCH_KEY, query)

        if (currentLocation?.lat && currentLocation?.lon) {
            paramsMap.set(SearchKeys.LAT_SEARCH_KEY, currentLocation?.lat.toString())
            paramsMap.set(SearchKeys.LON_SEARCH_KEY, currentLocation?.lon.toString())
        }

        searchPosts(catalogId, paramsMap);
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        //Get main cities
        searchLocation("")

        //Update current location
        getCurrentLocation()


        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    function onAnyLocationButtonClick() {
        setShowSearchCitiesContainer(true)
    }

    function searchLocationOnList(locationDomain: LocationDomain) {
        const paramsMap = UrlUtils.getParamsMap(location.search)
        //Set the query
        paramsMap.set(SearchKeys.LON_SEARCH_KEY, locationDomain.lon.toString())
        paramsMap.set(SearchKeys.LAT_SEARCH_KEY, locationDomain.lat.toString())
        searchPosts(catalogId, paramsMap);
    }

    function onLocationClick(isFromAnyLocation: boolean, locationDomain: LocationDomain | undefined) {
        if (!locationDomain) {
            onAnyLocationButtonClick()
            return
        }
        searchLocationOnList(locationDomain);

        saveCurrentLocation(isFromAnyLocation, locationDomain)

        getCurrentLocation()

    }


    function onBackPressed(event: React.MouseEvent) {
        event.stopPropagation()
        setShowSearchCitiesContainer(false)
    }

    function handleClickLocation(isFromAnyLocation: boolean, locationDomain: LocationDomain) {
        setShowSearchCitiesContainer(false)
        setSelectedCity(locationDomain.name)
        saveCurrentLocation(isFromAnyLocation, locationDomain)
        searchLocationOnList(locationDomain);
        getCurrentLocation()
    }

    function handleRemoveCity() {
        setSelectedCity(undefined)
    }

    function savePostInHistoryAndRefresh(postData: PostData) {
        savePostInHistory(postData)
        onPostClicked(onHide, updateQueryParam, postData);
    }

    function onResentSearchClicked(postData: PostData) {
        onPostClicked(onHide, updateQueryParam, postData);
    }

    return show ? (
        <div className={"search-experience-container " + clazz} ref={inputSearchExperienceRef}>
            <div className="main-container">
                {!showSearchCitiesContainer ? (
                    <>
                        <Trans
                            defaults={t(R.search_experience_title) as string} // optional defaultValue
                            values={{name: t("search_experience_title")}}
                            components={{
                                normal: <span className="search-experience-container__item"/>,
                                h: (
                                    <span className="search-experience-container__item fw-bold"/>
                                )
                            }}
                        />

                        <SearchInput onQueryChange={onInputChange} placeHolder={t(R.searchResults_searchResult_title)}/>


                        {showSuggestionSearchList ? (
                                <>
                                    <RecentSearch onRecentSearchClick={onResentSearchClicked}/>
                                    <Recommendations onRecommendationClick={savePostInHistoryAndRefresh}
                                                     locationViewState={stateGetCurrentLocation}/>
                                </>) :
                            (
                                <>
                                    <ScrollableButtonGroup
                                        onLocationClick={onLocationClick}
                                        locations={stateLocation.locationData ?? []}
                                        selectedCity={selectedCity}
                                        handleRemoveCity={handleRemoveCity}
                                        stateGetCurrentLocation={stateGetCurrentLocation}
                                    />
                                    <SearchResult postData={stateSearch.postResultData?.data ?? []}
                                                  onSearchResultClick={savePostInHistoryAndRefresh}/>
                                </>
                            )

                        }
                    </>
                ) : <Cities onBackPressed={onBackPressed} handleClickLocation={handleClickLocation}
                />
                }

            </div>
        </div>
    ) : null
};

export default SearchExperienceContainer;