import React from "react";
import { Row, Col } from "react-bootstrap";

import "./RecentReviewItem.scss";

interface RecentReviewItemProps {
    authorImage: string;
    name: string;
    location: string;
    description: string;
}

const RecentReviewItem: React.FC<RecentReviewItemProps> = ({
    authorImage,
    name,
    location,
    description,
}): JSX.Element => {
    return (
        <div className="recent-review-item">
            <Row>
                <Col xs={2} xl={2}>
                    <img
                        src={authorImage}
                        className="recent-review-item__author"
                        alt="author"
                        width="59px"
                    />
                </Col>

                <Col>
                    <span className="recent-review-item__name">{name}</span>
                    <span className="recent-review-item__location">
                        {location}
                    </span>
                    <span className="recent-review-item__desc">
                        {description}
                    </span>
                </Col>
            </Row>
        </div>
    );
};

export default RecentReviewItem;
