import {TopCatalogModel} from "@domain/models/catalog/TopCatalogModel"
import ICatalogRepository from "@domain/repository/catalog/ICatalogRepository"
import IUserRepository from "@domain/repository/user/IUserRepository";
import {UserToken} from "@domain/models/user/UserToken";
import {TOKEN_STORE_KEY} from "@utils/Constants";

export default class SignOutUseCase {


    /**
     * SignOut user
     * @return boolean
     */
    public signOut(): boolean {
        localStorage.removeItem(TOKEN_STORE_KEY)
        return true
    }


}