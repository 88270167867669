export enum SearchKeys {
    SEARCH_DISTANCE = 'distance',
    ITEMS_PER_PAGE = 'per_page',
    SORT_LIST_RESULTS = 'sort_by',

    SEARCH_DISTANCE_UNIT_SEARCH_KEY = 'unit',

    LAT_SEARCH_KEY = "lat",
    LON_SEARCH_KEY = "lon",
    PLACE_SEARCH_KEY = "place",

    SEARCH_LOCATION = "location",
    SEARCH_LOCATION_RADIUS = "search_radios",
    PERIOD_SEARCH_KEY = "sk_price_period_key",
    MIN_PRICE_SEARCH_KEY = "from",
    MAX_PRICE_SEARCH_KEY = "to",
    QUERY_SEARCH_KEY = "q"
}
