import {Attribute} from "@domain/models/catalog/CatalogModel"
import React, {ChangeEvent} from "react"
import {Form} from "react-bootstrap"
import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router-dom";

import "./InputSelect.scss"
import NavigationUtils from "@utils/NavigationUtils";

interface InputSelectProps {
    attr: Attribute,
    onSelectChanged?: (event: ChangeEvent<HTMLSelectElement>) => void | undefined
}

export function InputSelect({attr, onSelectChanged}: InputSelectProps): React.ReactElement {
    const {t} = useTranslation()

    const navigate = useNavigate()

    let listComponent = attr?.values?.map(it => {
        return <option value={it.value}> {t(it.name_dict_key)} </option>
    })

    const onInputSelectChanged = (event: ChangeEvent<HTMLSelectElement>) => {
        if (onSelectChanged) {
            onSelectChanged(event)
        }
        const updatedSearchParams = NavigationUtils.buildSearchParams({
            [attr.search_key]: event.target.value,
        });
        navigate(updatedSearchParams);
    }


    return <Form.Select onChange={onInputSelectChanged} className="  align-self-center " role="button">
        {listComponent}
    </Form.Select>

}

export default InputSelect