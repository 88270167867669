import {Attribute} from "@domain/models/catalog/CatalogModel";
import React, { useEffect, useRef, useState} from "react"
import {Container, Form, Row} from "react-bootstrap"
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";

import "./InputCheckBox.scss"
import NavigationUtils, {NavigationParams} from "@utils/NavigationUtils";
import UrlUtils from "@utils/UrlUtils";
import FilterRequestBuilder from "@utils/filter/FilterRequestBuilder";


interface InputCheckBoxProps {
    attribute: Attribute;
}

export function InputCheckBox({attribute}: InputCheckBoxProps): React.ReactElement {
    const {t} = useTranslation()
    const ref = useRef<HTMLInputElement>(null);
    const checkBoxRef = useRef<HTMLInputElement>(null);

    const [filterSelected, setFilterSelected] = useState(["Any"])
    const [checkedValues, setCheckedValues] = useState<string[]>([]);
    const navigate = useNavigate()
    const location = useLocation();


    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        e.target.checked ? setFilterSelected(filterValue => [...filterValue, e.target.value]) :
            setFilterSelected(filterValue => filterValue.filter(filter => {
                return filter !== e.target.value
            }))

        const value = e.target.id;
        const isChecked = e.target.checked;

        // Update the checkedValues array based on whether the checkbox is checked or unchecked
        if (isChecked) {
            let newValue = [...checkedValues, value]
            setCheckedValues(newValue);
        } else {
            setCheckedValues(checkedValues.filter((val) => val !== value));
        }


    }


    useEffect(() => {


        const paramsMap = UrlUtils.getParamsMap(location.search)

        const currentSelectedValue = FilterRequestBuilder.getInstance().getInitialValue(paramsMap, attribute.search_key)


        attribute?.values?.map((at, index) => {
            if (currentSelectedValue?.includes(at.value)) {
                checkedValues.push(at.value)
            }
        })

        setCheckedValues(checkedValues);


    }, [])


    useEffect(() => {
        const paramsMap = UrlUtils.getParamsMap(location.search)

        const currentSelectedValue = FilterRequestBuilder.getInstance().getInitialValue(paramsMap, attribute.search_key)

        if (!currentSelectedValue && checkedValues.length ) {
            setCheckedValues([]);
        }

    }, [location])


    useEffect(() => {

        const newParams: NavigationParams = {
            [attribute.search_key]: checkedValues,
        };

        const updatedSearchParams = NavigationUtils.buildSearchParams(newParams);
        navigate(updatedSearchParams);


    }, [checkedValues])


    return <Container className="gx-0 mx-0">
        <Row
            ref={checkBoxRef}
            className="gx-0 mx-0 ">
            {
                attribute?.values?.map((at, index) => {
                    const key = `${at.name_dict_key}-${checkedValues.includes(at.value)}`;
                    return <Form.Check
                        type="checkbox"
                        className="pointer"
                        ref={checkBoxRef}
                        onChange={handleCheckboxChange}
                        checked={checkedValues.includes(at.value)}
                        value={t(at.name_dict_key)}
                        label={t(at.name_dict_key)}
                        id={at.value}
                        key={key}
                    />
                })
            }
        </Row>
    </Container>
}

export default InputCheckBox