import React from "react";
import { Accordion } from "react-bootstrap";
import "./Faqs.css";

const Faqs: React.FC = (): JSX.Element => {


  return (
    <>
      <p className="faq-title-style">Frequently Asked Questions</p>
      <Accordion className="m-5 faqs" defaultActiveKey="0" flush>
        <Accordion.Item id="1" eventKey="0">
          <Accordion.Header>What is rentallo?</Accordion.Header>
          <Accordion.Body>
            Rentallo is a property platform that enables individuals and businesses to list rental properties. 
            <br />
            Presently, property listings can only be created using Android devices, but support for iOS and web platforms is anticipated in the near future.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item  id="2" eventKey="1">
          <Accordion.Header>How I can use rentallo?</Accordion.Header>
          <Accordion.Body>
            To use Rentallo, you'll need to access it via its mobile app, which is currently only available for Android devices. 
            To get started, download the Rentallo app from the Google Play Store by clicking on this [link]. 
            Once installed, you can publish posts and explore rental properties. Stay tuned for upcoming support for additional platforms, such as iOS and web.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>How does rentallo handle my data?</Accordion.Header>
          <Accordion.Body>
            We strictly abide by what is said in the privacy policy. You can
            always request the deletion of your data by writing an email to: <a href = "mailto: rentalloapp@gmail.com">rentalloapp@gmail.com</a>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            In which countries does rentallo work?
          </Accordion.Header>
          <Accordion.Body>
            Rentallo currently operates exclusively in Dubai. As the platform expands, it may become available in additional countries in the future.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>How can I contact rentallo?</Accordion.Header>
          <Accordion.Body>
            To get in touch with Rentallo, you can contact them via their email support service at: <a href = "mailto: rentalloapp@gmail.com">rentalloapp@gmail.com</a>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default Faqs;
