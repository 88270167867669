import React, {useState} from 'react';
import "./SearchInput.scss"
import searchIcon from "@assets/search_icon.svg";
import clearIcon from "@assets/clear.svg";

export interface SearchInputProps {
    onQueryChange: (query: string) => void;
    placeHolder: string
}

const SearchInput: React.FC<SearchInputProps>  = ({onQueryChange, placeHolder}) => {
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
         onQueryChange(event.target.value)
    };

    const clearInput = (e: React.MouseEvent) => {
        e.stopPropagation()
        setInputValue('');
        onQueryChange('')

    };

    return (
        <div className='search-input  main'>
            <img src={searchIcon} className="search-bar-result-page  search-icon" alt="search-icon"/>

            <input
                type="text"
                className="search-input input-text"
                placeholder={placeHolder}
                value={inputValue}
                onChange={handleInputChange}
            />
            {(inputValue != "") ?
                (<img src={clearIcon} onClick={clearInput} className="search-input clear-icon"
                 alt="search-icon-close"/>) : null}

        </div>
    );
};

export default SearchInput;