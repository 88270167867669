import React from "react";
import "./OnMaintenance.scss"
import logo from "@assets/logo-rentallo.svg";
import {Trans, useTranslation} from "react-i18next";
import R from "@strings/R";
import {CopyRight} from "@presentation/views/components/CopyRight/CopyRight";

export function OnMaintenance(): React.ReactElement {
    const {t} = useTranslation()

    return <div className="maintenance-container">
        <img src={logo} className="maintenance-logo" alt="Rentallo logo"/>
        <div className="maintenance-text">{t(R.maintenanceDescriptionFirstLine)}</div>
        <div className="maintenance-text">{t(R.maintenanceDescriptionSecondLine)}</div>
        <div className="maintenance-footer"><CopyRight/></div>
    </div>
}
