import { TopCatalogModel } from "@domain/models/catalog/TopCatalogModel";
import {PLACES_API_ID, POST_MOST_VIEW_KEY} from "@domain/models/startup/ServiceId";
import { Startup } from "@domain/models/startup/Startup";
import ICatalogRepository from "@domain/repository/catalog/ICatalogRepository";
import {ApiUtilsUtils} from "@utils/ApitUtils";

export default class CatalogApi implements ICatalogRepository {

    private startupConfig: Startup | undefined


    constructor(startupConfig: Startup  | undefined){
      this.startupConfig = startupConfig
    }

    getTopCatalog(): Promise<Array<TopCatalogModel> | undefined> {

        let url = ApiUtilsUtils.getServiceEndPointByServiceId(this.startupConfig, POST_MOST_VIEW_KEY)!!

        return new Promise((resolve, reject) => {
            fetch(url)
                .then(response => {
                    resolve(response.json());
                }).catch(error => {
                    reject(new Error(error))
                })
        });
    }
}