import { getRemoteConfig, getString } from "firebase/remote-config";
import { REMOTE_CONFIG_TERM_OF_SERVICE_CONTENT_KEY } from "./RemoteConfigKeys";

export default function getTermOfService(): string  {

    const remoteConfig = getRemoteConfig();
  
    const temOfServiceString = getString(remoteConfig, REMOTE_CONFIG_TERM_OF_SERVICE_CONTENT_KEY)
  
    return temOfServiceString;
    
  }
  