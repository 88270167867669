import React, {ChangeEvent, useEffect, useRef, useState} from "react"
import {Col, Container, Row} from "react-bootstrap"
import {useTranslation} from "react-i18next"
import R from "@strings/R"
import {Attribute, Value} from "@domain/models/catalog/CatalogModel"
import "./PriceFilter.scss"
import UrlUtils from "@utils/UrlUtils";
import {SearchKeys} from "@enums/SearchKeys";
import {useLocation, useNavigate} from "react-router-dom";
import NavigationUtils from "@utils/NavigationUtils";
import FilterRequestBuilder from "@utils/filter/FilterRequestBuilder";


interface InputListRange {
    attribute: Attribute
}

export function PriceFilter({attribute}: InputListRange): React.ReactElement {
    const [minPrice, setMinPrice] = useState("")
    const [maxPrice, setMaxPrice] = useState("")
    const navigate = useNavigate()

    const {t} = useTranslation()

    const location = useLocation();

    useEffect(() => {
        const paramsMap = UrlUtils.getParamsMap(location.search)
        const minPrice = FilterRequestBuilder.getInstance()?.getInitialValue(paramsMap, SearchKeys.MIN_PRICE_SEARCH_KEY)
        const maxPrice = FilterRequestBuilder.getInstance().getInitialValue(paramsMap, SearchKeys.MAX_PRICE_SEARCH_KEY)

        if (minPrice != undefined) {
            setMinPrice(minPrice)
        }
        if (maxPrice != undefined) {
            setMaxPrice(maxPrice)
        }
    }, [])


    useEffect(() => {
        const paramsMap = UrlUtils.getParamsMap(location.search)
        const minPrice = FilterRequestBuilder.getInstance().getInitialValue(paramsMap, SearchKeys.MIN_PRICE_SEARCH_KEY)
        const maxPrice = FilterRequestBuilder.getInstance().getInitialValue(paramsMap, SearchKeys.MAX_PRICE_SEARCH_KEY)

        if(!minPrice &&  minPrice != "") {
            setMinPrice("")
        }
        if(!maxPrice &&  maxPrice != "") {
            setMaxPrice("")
        }


    }, [location])

    function navigateTo(key: string,e: React.ChangeEvent<HTMLInputElement>) {
        const updatedSearchParams = NavigationUtils.buildSearchParams({
            [key]: e.target.value,
        });

        navigate(updatedSearchParams, { replace: true });
    }

    const handleMinPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMinPrice(e.target.value);
        navigateTo(SearchKeys.MIN_PRICE_SEARCH_KEY, e);
    };

    const handleMaxPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMaxPrice(e.target.value);
        navigateTo(SearchKeys.MAX_PRICE_SEARCH_KEY, e);

    };

    return <Container className="gx-0 mx-0 " onClick={(e: React.MouseEvent<HTMLLIElement>) => {
    }}>
        <Row className="gx-0 mx-0 ">
            <Col md={5}>
                <input value={minPrice}
                       className="price-input no-border "
                       placeholder={t(R.catalog_attribute_min_price)}
                       onChange={handleMinPriceChange}
                />
            </Col>
            <Col md={5} className="ms-4 ">
                <input value={maxPrice}
                       className="price-input ms-1"
                       placeholder={t(R.catalog_attribute_max_price)}
                       onChange={handleMaxPriceChange}
                />
            </Col>

        </Row>
    </Container>
}

export default PriceFilter