import getTermOfService from "@utils/remote_config/TermService";
import React from "react";
import { Row } from "react-bootstrap";
const HtmlToReactParser = require('html-to-react').Parser;

const TermOfService: React.FC = (): JSX.Element => {
  let termOfServiceContent = getTermOfService()


  const htmlToReactParser = new HtmlToReactParser();

  const reactElement = htmlToReactParser.parse(termOfServiceContent);

  return <Row style = {{ overflow: 'auto', maxHeight: '700px' }}>
    {reactElement}
  </Row>
}

export default TermOfService; 