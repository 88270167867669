import { MIN_SEARCH_DEBOUNCE_TIME_MS } from "@utils/Constants";
import ILocationRepository from "@domain/repository/location/ILocationRepository";

export default class SearchLocationUseCase {

    private locationRepository: ILocationRepository


    public constructor(locationRepository: ILocationRepository) {
        this.locationRepository = locationRepository
    }


    /**
     * Seach location suggestion list
     * @param query 
     * @throws {Error} in case of getting non 2xx response or empty query 
     */
    public async searchLocationSuggestions(query: String) :  Promise<LocationData[] | undefined> {
        return new Promise((resolve, reject) => {
                  this.locationRepository.getLocationSuggestions(query).then(response => {
                     resolve(response)
               }).catch(error => {
                   reject(error)
                })

        })
    }

    /**
     * Seach main location suggestion list
     * @throws {Error} in case of getting non 2xx response or empty query
     */
     public async searchMainLocationSuggestions() :  Promise<LocationData[] | undefined> {
        return new Promise((resolve, reject) => {
                 this.locationRepository.getLocationSuggestions().then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
        })
    }

}