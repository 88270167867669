import React, { Component } from 'react';
import { Form } from 'react-bootstrap';

interface CustomInputProps {
    label: string;
    mandatory?: boolean;
    value?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void; // Make onBlur prop optional,
    inputRef?: React.RefObject<HTMLInputElement>;
    error: string | null;
}

class TextInput extends Component<CustomInputProps> {
    render() {
        const { label, value, mandatory, onChange, onBlur,inputRef, error } = this.props;
        return (
            <Form.Group className="mt-2">
                <Form.Label>{label}</Form.Label>
                {mandatory && <span className="text-danger">*</span>}

                <Form.Control
                    type="text"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={inputRef}
                />
                {error && <div className="text-danger">{error}</div>}
            </Form.Group>
        );
    }
}

export default TextInput;
