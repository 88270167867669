import {useCallback, useState} from "react";
import SearchLocationUseCase from "@domain/usecases/location/SearchLocationUseCase";
import {LocationDomain} from "@domain/models/search/SugestionType";


export interface SearchLocationViewState {
    locationData?: LocationDomain[] | undefined,
    isLoading?: boolean;
    error?: string | null;
}

export const useSearchLocationViewModel = (searchLocationUseCase: SearchLocationUseCase) => {
    const [state, setState] = useState<SearchLocationViewState>({isLoading: false});

    const searchLocation = useCallback((query: string) => {
        setState({isLoading: true});
        searchLocationUseCase.searchLocationSuggestions(query)
            .then(locationData => {

                const locationDataMapped = locationData?.map((item) => {
                    const level1 = item.hierarchy.length > 0 ? item.hierarchy[0].values['en'] : undefined;
                    const level2 = item.hierarchy.length > 1 ? item.hierarchy[item.hierarchy.length - 1].values['en'] : undefined;

                    return {
                        name: item.values['en'],
                        level1: level1,
                        level2: level2,
                        lat: item.lat,
                        lon: item.lon,
                        highlightResult: item.highlightResult,
                    } as LocationDomain;

                })

                setState({locationData: locationDataMapped, isLoading: false, error: null});

            })
            .catch(error => {
                setState({error: 'Error fetching posts', isLoading: false});
            });
    }, [searchLocationUseCase]);

    return {
        stateLocation: state,
        searchLocation
    };
};