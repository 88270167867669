export const DEFAULT_DISTANCE_KM_VALUE = 30;

export const MIN_SEARCH_DEBOUNCE_TIME_MS: number = 200;
export const DEFAULT_PERIOD = "year"
export const DEFAULT_UNIT = "km"


export const TOKEN_STORE_KEY: string = "TOKEN_STORE_KEY";

export const SEARCH_HISTORY_STORE_KEY: string = "SEARCH_HISTORY_STORE_KEY";
export const CURRENT_LOCATION_STORE_KEY: string = "CURRENT_LOCATION_STORE_KEY";

export const INVENTORY_IMAGE_FOLDER_PROD = 'inventory'

export const INVENTORY_IMAGE_FOLDER_TEST = 'inventory_test'

export const THUMBNAIL_RESOLUTION = '100x100'

export const SMALL_RESOLUTION = '400x400'

export const MEDIUM_RESOLUTION = '1000x1000'

export const CATALOG_PROPERTY_ID_KEY = "CAT-1"
