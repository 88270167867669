import React from "react";
import {useTranslation} from "react-i18next";
import {Form} from "react-bootstrap";
import {Attribute} from "@presentation/views/components/DynamicForm/DynamicForm";

interface DynamicInputProps {
    attribute: Attribute;
    formData: Record<string, any>;
    onInputChange: (attribute: string, value: any) => void;
    validateInput?: (value: string) => { isValid: boolean; errorMessage: string };
}



const CheckListInput: React.FC<DynamicInputProps> = ({attribute, formData, onInputChange}) => {
    const {t} = useTranslation(); // Use useTranslation hook here

    return <div key={attribute.search_key} className="mt-2">
        <Form.Group controlId={attribute.search_key}>
            <Form.Label>{attribute.name}</Form.Label>
            {attribute.mandatory && <span className="text-danger">*</span>}
            {attribute.values?.map((valueObj) => (
                <div key={valueObj.value}>
                    <Form.Check
                        type="checkbox"
                        name={attribute.search_key}
                        value={valueObj.value}
                        checked={formData[attribute.search_key]?.some((item: {
                            value: string;
                        }) => item.value === valueObj.value)}
                        onChange={(e) => {
                            let selectedValues = [...(formData[attribute.search_key] || [])];
                            const value = e.target.value;

                            if (e.target.checked) {
                                selectedValues.push({
                                    value: value,
                                    name_dict_key: attribute.values?.find((item) => item.value === value)?.name_dict_key
                                });
                            } else {
                                const index = selectedValues.findIndex((item) => item.value === value);
                                if (index !== -1) {
                                    selectedValues.splice(index, 1);
                                }
                            }
                            onInputChange(attribute.search_key, selectedValues);
                        }}
                        label={t(valueObj.name_dict_key)}
                    />
                </div>
            ))}
        </Form.Group>
    </div>
}
export default CheckListInput;
