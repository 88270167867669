import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Nav } from "react-bootstrap";
import R from "@strings/R";
import LoginRegisterIcon from "../Icons/LoginRegisterIcon";

interface AuthNavigationSingedOutProps {
    onLoginRegisterClick(): void;
}

const AuthNavigationSingedOut = ({
    onLoginRegisterClick,
}: AuthNavigationSingedOutProps) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);

    const baseItemClassname = "auth-navigation__item";


    return (
        <Nav.Link
            onMouseEnter={(e: React.MouseEvent<HTMLLIElement>) => setShow(true)}
            onMouseLeave={(e: React.MouseEvent<HTMLLIElement>) =>
                setShow(false)
            }
            onClick={onLoginRegisterClick}
        >
            {
                <span className={baseItemClassname}>
                    <LoginRegisterIcon />
                    <span className="auth-navigation__item__text">
                        {t(R.auth_login_title)}
                    </span>
                </span>
            }
        </Nav.Link>
    );
};

export default AuthNavigationSingedOut;
