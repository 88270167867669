import R from "@strings/R";
import React, {useEffect, useState} from "react";
import {Accordion, Card, Container, Form, Modal, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Attribute} from "@domain/models/catalog/CatalogModel";
import "./FilterMenu.scss"
import FactoryMetaFilter from "@utils/factories/FactoryMetaFilter";
import {FilterInputTypes} from "@enums/FilterInputTypes";
import SearchViewModel from "@viewmodels/SearchViewModel";
import MetaSearchUtils from "@utils/MetaSearchUtils";
import UrlUtils from "@utils/UrlUtils";
import {useLocation} from "react-router-dom";
import {SearchKeys} from "@enums/SearchKeys";

interface FilterMenuProps {
    showFilter: boolean,
    attributes: Attribute[] | undefined,
    searchViewModel: SearchViewModel,
    className: string,

    onHideChanged(show: boolean): void
}

type FilterItem = {
    title: string;
    key: string;
    component: JSX.Element | undefined;
    keyActive: string;
};

const FilterMenu: React.FC<FilterMenuProps> = ({
                                                   attributes,
                                                   onHideChanged: onHideChanged,
                                                   searchViewModel,
                                                   className
                                               }: FilterMenuProps): JSX.Element => {
    const {t} = useTranslation()
    const [activeKeys, setActiveKeys] = useState<string[]>(['0', '1', '2', '3', '4']);
    const [filter, setFilters] = useState<FilterItem[]>([]);

    const location = useLocation();


    const paramsMap = UrlUtils.getParamsMap(location.search)

    useEffect(() => {

        const filters: FilterItem[] = [
            {
                title: 'Location',
                key: SearchKeys.SEARCH_LOCATION,
                component: FactoryMetaFilter.createFixMetaFilter(FilterInputTypes.SEARCH_LOCATION_INPUT, searchViewModel),
                keyActive: '0'
            },
            {
                title: 'Search radius (km)',
                key: SearchKeys.SEARCH_LOCATION_RADIUS,
                component: FactoryMetaFilter.createFixMetaFilter(FilterInputTypes.SEARCH_RADIO_LIST, searchViewModel, MetaSearchUtils.getSearchRadiusAttribute()),
                keyActive: '1'
            }
        ]

        attributes?.map(
            attribute => {
                const keyActive = (filters.length + 1).toString()
                if (paramsMap.has(attribute.search_key) && !activeKeys.includes(keyActive))
                    activeKeys.push(filters.length.toString())
                filters.push(
                    {
                        title: t(attribute.name_dict_key),
                        key: attribute.name_dict_key,
                        component: FactoryMetaFilter.createMetaFilter(attribute),
                        keyActive: keyActive
                    }
                )
            }
        )

        setFilters(filters)
    }, [])


    return (
        <>
            <span className="filter-by"> {t(R.landingPage_filter_button)}</span>
            <Accordion className={className} defaultActiveKey={activeKeys} alwaysOpen>
                {filter.map((filter, index) => (
                    <Accordion.Item eventKey={String(index)}>
                        <Accordion.Header>
                            {filter.title}&nbsp;&nbsp;
                        </Accordion.Header>
                        <Accordion.Body>
                            <Form>
                                {
                                    filter?.component
                                }
                            </Form>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>    </>
    );


}

export default FilterMenu 