import {PostData} from "@domain/models/post/PostResponseV1";
import {useCallback, useState} from "react";
import {SavePostInHistoryUseCase} from "@domain/usecases/search/SavePostInHistoryUseCase";


export interface SearchPostHistoryViewState {
    isLoading?: boolean;
    error?: string | null;
}

export const useSavePostInHistoryViewModel = (savePostInHistoryUseCase: SavePostInHistoryUseCase) => {
    const [stateSavePostHistory, setState] = useState<SearchPostHistoryViewState>({isLoading: false});

    const savePostInHistory = useCallback((postData: PostData) => {
        setState({isLoading: true});
        savePostInHistoryUseCase.execute(postData)
        setState({isLoading: false, error: null});

    }, [savePostInHistoryUseCase]);

    return {
        stateSavePostHistory,
        savePostInHistory
    };
};