import DataState from "@domain/models/state/DataState";
import {UserV1} from "@domain/models/user/UserV1";
import GetUserAccountUseCase from "@domain/usecases/user/GetUserAccountUseCase";
import {action, makeObservable, observable} from "mobx";
import GetTokenUseCase from "@domain/usecases/auth/GetTokenUseCase";
import {UserToken} from "@domain/models/user/UserToken";
import SignOutUseCase from "@domain/usecases/auth/SignOutUseCase";

class UserViewModel {
    public isLoadingRequest: boolean = false;
    public isLoadingPhoneNumber: boolean = false;

    public stateLocation: DataState<{
        user: UserV1 | undefined,
        userToken: UserToken | undefined
    }> = new DataState({user: undefined, userToken: undefined})

    public error: Error | undefined

    public getUserAccount: GetUserAccountUseCase
    private getTokenUseCase: GetTokenUseCase
    private signOutUseCase: SignOutUseCase

    public constructor(getUserAccount: GetUserAccountUseCase, getTokenUseCase: GetTokenUseCase, signOutUseCase: SignOutUseCase) {
        this.getUserAccount = getUserAccount
        this.getTokenUseCase = getTokenUseCase
        this.signOutUseCase = signOutUseCase

        makeObservable(this, {
            isLoadingRequest: observable,
            isLoadingPhoneNumber: observable,
            stateLocation: observable,
            error: observable,
            handleGetUserAccount: action
        })
    }

    get getUserData() {
        return this.stateLocation.content.user
    }

    signOut() {
        this.signOutUseCase.signOut()
    }

    handleGetUserToken(firebaseTokenId: string) {
        this.isLoadingRequest = true;
        this.isLoadingPhoneNumber = true;
        this.getTokenUseCase.token(firebaseTokenId).then(response => {
            this.handleNewToken(response);
            if (this.getUserData?.phone_number) {
                this.isLoadingRequest = false;
                this.isLoadingPhoneNumber = false;
            }
        }).catch(error => {
            this.error = error;
            this.isLoadingRequest = false;
            this.isLoadingPhoneNumber = false;
        });
    }

    handleGetUserAccount(userId: number) {
        this.isLoadingRequest = true;
        this.isLoadingPhoneNumber = true;
        this.getUserAccount.getOtherUserAccount(userId).then(response => {
            this.handleNewUserResultData(response);
            this.isLoadingRequest = false;
            this.isLoadingPhoneNumber = false;
        }).catch(error => {
            this.error = error;
            this.isLoadingRequest = false;
            this.isLoadingPhoneNumber = false;
        });
    }

    handleGetMyUserAccount() {
        this.isLoadingRequest = true;
        this.getUserAccount.getMyUserAccount().then(response => {
            this.handleNewUserResultData(response);
            this.isLoadingRequest = false;
        }).catch(error => {
            this.error = error;
            this.isLoadingRequest = false;
        });
    }


    handleNewUserResultData(userV1: UserV1) {
        this.stateLocation = new DataState({user: userV1, userToken: this.stateLocation.content.userToken})
        this.isLoadingRequest = false
    }

    handleNewToken(userToken: UserToken | undefined) {
        this.stateLocation = new DataState({userToken: userToken, user: this.stateLocation.content.user})
        this.isLoadingRequest = false
    }


}

export default UserViewModel