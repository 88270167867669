import React from "react";
import RecentReviewItem from "./RecentReviewItem";

// @ts-ignore
import frankImg from "@assets/frank.png";
// @ts-ignore
import kristiImg from "@assets/kristi.png";

import "./RecentReviews.scss";

const recentReviewsData = [
    {
        id: 1,
        authorImage: frankImg,
        name: "Frank",
        location: "Liverpool",
        description: `Mark is my go-to for rentals, and this website makes
        it a breeze. Affordable, top-notch equipment, and
        service that's second to none. Highly recommend for
        anyone on the lookout for properties.`,
    },
    {
        id: 2,
        authorImage: kristiImg,
        name: "Kristi",
        location: "London",
        description: `Three rentals with James, all made effortless. Great
        gear, great prices, great service. The ultimate
        platform for property hunting.`,
    },
];

const RecentReviews: React.FC = (): JSX.Element => {
    return (
        <div className="recent-review">
            <h5 className="recent-review__title">Recent rental reviews</h5>

            <div className="recent-review__content">
                {recentReviewsData.map((review) => {
                    return (
                        <RecentReviewItem
                            key={review.id}
                            authorImage={review.authorImage}
                            name={review.name}
                            location={review.location}
                            description={review.description}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default RecentReviews;
