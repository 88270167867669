import React from 'react';
import {ListGroup} from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import R from "@strings/R";
import "./SearchResult.scss"
import {PostData} from "@domain/models/post/PostResponseV1";
import EmptySearchResult
    from "@presentation/views/components/Search/SearchExperience/EmptySearchResult/EmptySearchResult";
import PostInfoRow from "@presentation/views/components/Search/SearchExperience/PostInfoRow/PostInfoRow";


export interface SearchResultProps {
    postData: PostData[];
    onSearchResultClick: (p: PostData) => void
}

const SearchResult: React.FC<SearchResultProps> = ({postData, onSearchResultClick}) => {
    const {t} = useTranslation();
    return <>
        {
            postData.length > 0 ? (
                <>
                    <p className="search-result title">{t(R.searchResults_searchResult_title)}</p>
                    <ListGroup className="search-result group">
                        {postData.map((postData, index) => (
                            <ListGroup.Item key={index}
                                            onClick={(e: React.MouseEvent) => onSearchResultClick(postData)}>
                                <PostInfoRow postData={postData} />
                            </ListGroup.Item>))
                        }
                    </ListGroup>
                </>

            ) : <EmptySearchResult message={t(R.searchExperience_search_no_results)}/>
        }
    </>
};

export default SearchResult;