import DataState from "@domain/models/state/DataState";

abstract class BaseViewModel<T> {

    private _state: DataState<T> | null = null;

    private listeners: Array<() => void> = [];


    public getState(): DataState<T> {
        if (this._state === null) {
            this._state = this.createState();
        }
        return this._state;
    }

    subscribe(listener: () => void) {
        this.listeners.push(listener);
    }

    unsubscribe(listener: () => void) {
        this.listeners = this.listeners.filter(l => l !== listener);
    }

    protected notifyStateChanged() {
         this.listeners.forEach(listener => listener());
    }

    protected abstract createState(): DataState<T>;

}


export default BaseViewModel;