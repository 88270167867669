import {TopCatalogModel} from "@domain/models/catalog/TopCatalogModel"
import ICatalogRepository from "@domain/repository/catalog/ICatalogRepository"
import IUserRepository from "@domain/repository/user/IUserRepository";
import {UserToken} from "@domain/models/user/UserToken";
import {TOKEN_STORE_KEY} from "@utils/Constants";

export default class GetTokenUseCase {


    private userRepository: IUserRepository

    public constructor(userRepository: IUserRepository) {
        this.userRepository = userRepository
    }


    /**
     * Get auth user token
     * @param firebaseTokenId
     * @throws {Error} in case of getting non 2xx response or empty query
     */
    public async token(firebaseTokenId: string): Promise<UserToken | undefined> {
        return new Promise(async (resolve, reject) => {
            await this.userRepository.getUserToken(firebaseTokenId).then(response => {
                //save token in local store
                localStorage.setItem(TOKEN_STORE_KEY, response.token)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    }


}