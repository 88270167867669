import DataState from "@domain/models/state/DataState";
import {PostState} from "@domain/models/state/PostState";
import GetPostDetailsUseCase from "@domain/usecases/post/GetPostDetailsUseCase";
import {action, makeObservable, observable} from "mobx";
import GetMyPostsUseCase from "@domain/usecases/post/GetMyPostsUseCase";
import DeleteMyPostUseCase from "@domain/usecases/post/DeleteMyPostUseCase";
import GetMyFavouritesUseCase from "@domain/usecases/favourites/GetMyFavouritesUseCase";
import CreatePostUseCase from "@domain/usecases/post/CreatePostUseCase";
import {PostCreateV1} from "@domain/models/post/PostCreateV1";
import DeleteFavouriteUseCase from "@domain/usecases/favourites/DeleteFavouriteUseCase";

class PostViewModel {
    public isLoadingRequest: boolean = false;

    public error: Error | undefined
    public stateLocation: DataState<PostState> = new DataState({
        postData: undefined,
        myPosts: undefined,
        favourites: undefined,
        postDeleted: false,
        postCreated: undefined,
        favouriteDeleted: false
    })

    private getPostDetailsUseCase: GetPostDetailsUseCase
    private myPostsUseCase: GetMyPostsUseCase
    private deleteMyPostUseCase: DeleteMyPostUseCase
    private getMyFavouritesUseCase: GetMyFavouritesUseCase
    private deleteMyFavouritesUseCase: DeleteFavouriteUseCase
    private createPostUseCase: CreatePostUseCase

    constructor(getPostDetailsUseCase: GetPostDetailsUseCase,
                getMyPostsUseCase: GetMyPostsUseCase,
                deleteMyPostUseCase: DeleteMyPostUseCase,
                getMyFavouritesUseCase: GetMyFavouritesUseCase,
                createPostUseCase: CreatePostUseCase,
                deleteMyFavouriteUseCase: DeleteFavouriteUseCase
    ) {
        this.getPostDetailsUseCase = getPostDetailsUseCase
        this.myPostsUseCase = getMyPostsUseCase
        this.deleteMyPostUseCase = deleteMyPostUseCase
        this.getMyFavouritesUseCase = getMyFavouritesUseCase
        this.createPostUseCase = createPostUseCase;
        this.deleteMyFavouritesUseCase = deleteMyFavouriteUseCase;
        makeObservable(this, {
            isLoadingRequest: observable,
            stateLocation: observable,
            error: observable,
            getPostDetails: action,
            postCreated: action,
            favouriteDelete: action
        })
    }

    getFavouritesData() {
        return this.stateLocation.content.favourites
    }

    getPostData() {
        return this.stateLocation.content.postData
    }

    getMyPostsData() {
        return this.stateLocation.content.myPosts
    }


    favouriteDelete() {
        return this.stateLocation.content.favouriteDeleted

    }

    postDelete() {
        return this.stateLocation.content.postDeleted
    }

    postCreated() {
        return this.stateLocation.content.postCreated
    }

    createPost(postCreateV1: PostCreateV1) {
        this.isLoadingRequest = true
        this.createPostUseCase.createPost(postCreateV1)
            .then(response => {
                this.stateLocation = new DataState(
                    {
                        postData: this.stateLocation.content.postData,
                        myPosts: this.stateLocation.content.myPosts,
                        postDeleted: this.stateLocation.content.postDeleted,
                        favourites: this.stateLocation.content.favourites,
                        postCreated: response,
                        favouriteDeleted: this.stateLocation.content.favouriteDeleted
                    })
                this.isLoadingRequest = false
                console.log("post create  ")
            }).catch(error => {
            this.error = error
            this.isLoadingRequest = false
        })
    }


    getPostDetails(postId: string) {
        this.isLoadingRequest = true
        this.getPostDetailsUseCase.getPostDetails(postId)
            .then(response => {
                this.stateLocation = new DataState({
                    postData: response,
                    myPosts: this.stateLocation.content.myPosts,
                    postDeleted: this.stateLocation.content.postDeleted,
                    favourites: this.stateLocation.content.favourites,
                    postCreated: this.stateLocation.content.postCreated,
                    favouriteDeleted: this.stateLocation.content.favouriteDeleted
                })
                this.isLoadingRequest = false
                console.log("response in cate  " + response)
            }).catch(error => {
            this.error = error
            this.isLoadingRequest = false
        })
    }

    getMyPosts() {
        this.isLoadingRequest = true
        this.myPostsUseCase.getMyPosts()
            .then(response => {
                this.stateLocation = new DataState({
                    postData: this.stateLocation.content.postData,
                    myPosts: response,
                    postDeleted: this.stateLocation.content.postDeleted,
                    favourites: this.stateLocation.content.favourites,
                    postCreated: this.stateLocation.content.postCreated,
                    favouriteDeleted: this.stateLocation.content.favouriteDeleted

                })
                this.isLoadingRequest = false
            }).catch(error => {
            this.error = error
            this.isLoadingRequest = false
        })
    }

    deleteMyPost(postId: number) {
        this.isLoadingRequest = true
        this.deleteMyPostUseCase.deletePost(postId)
            .then(response => {

                this.stateLocation = new DataState({
                    postData: this.stateLocation.content.postData,
                    myPosts: {
                        ...this.stateLocation.content.myPosts!,
                        data: this.stateLocation.content.myPosts!.data.filter((post) => post.id !== postId),
                    },
                    postDeleted: response,
                    favourites: this.stateLocation.content.favourites,
                    postCreated: this.stateLocation.content.postCreated,
                    favouriteDeleted: this.stateLocation.content.favouriteDeleted

                })
                this.isLoadingRequest = false
            }).catch(error => {
            this.error = error
            this.isLoadingRequest = false
        })
    }

    deleteMyFavourite(postId: number) {
        this.isLoadingRequest = true
        this.deleteMyFavouritesUseCase.deleteFavourite(postId)
            .then(response => {
                if (this.stateLocation.content.favourites) {
                    this.stateLocation.content.favourites.data = this.stateLocation.content.favourites.data.filter(
                        (favouriteData) => favouriteData.post.id !== postId
                    );
                }
                this.stateLocation = new DataState({
                    postData: this.stateLocation.content.postData,
                    myPosts: {
                        ...this.stateLocation.content.myPosts!,
                        data: this.stateLocation.content.myPosts!.data.filter((post) => post.id !== postId),
                    },
                    postDeleted: this.stateLocation.content.postDeleted,
                    favourites: this.stateLocation.content.favourites,
                    postCreated: this.stateLocation.content.postCreated,
                    favouriteDeleted: true

                })
                this.isLoadingRequest = false
            }).catch(error => {
            this.error = error
            this.isLoadingRequest = false
        })
    }

    getMyFavourites() {
        this.isLoadingRequest = true
        this.getMyFavouritesUseCase.getMyFavouritePost()
            .then(response => {
                this.stateLocation = new DataState({
                    postData: this.stateLocation.content.postData,
                    myPosts: this.stateLocation.content.myPosts,
                    postDeleted: this.stateLocation.content.postDeleted,
                    postCreated: this.stateLocation.content.postCreated,
                    favourites: response,
                    favouriteDeleted: this.stateLocation.content.favouriteDeleted

                })
                this.isLoadingRequest = false
            }).catch(error => {
            this.error = error
            this.isLoadingRequest = false
        })
    }
}


export default PostViewModel