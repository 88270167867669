import {PostData, PostFavouritesResult, PostResultData} from "@domain/models/post/PostResponseV1"
import IPostRepository from "@domain/repository/post/IPostRepository"

export default class GetMyFavouritesUseCase {

    private postRepository: IPostRepository

    public constructor(postRepository: IPostRepository) {
        this.postRepository = postRepository
    }


    /**
     * Get my favourite posts
     * @throws {Error} in case of getting non 2xx response or empty query
     */
    public async getMyFavouritePost(): Promise<PostFavouritesResult> {
        return new Promise(async (resolve, reject) => {
            await this.postRepository.getMyFavourites().then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    }
}