import React from "react";
import {ListGroup} from "react-bootstrap";
import {LocationDomain} from "@domain/models/search/SugestionType";
import "./SearchSuggestionsList.scss"

interface SearchLocationListProps {
    location: LocationDomain[];

    onClick(location: LocationDomain): void;
}

const SearchSuggestionsList = ({
                                   location,
                                   onClick,
                               }: SearchLocationListProps) => {
    if (!location) {
        return null;
    }

    return (
        <div className="search-suggestions-list">
            <ListGroup className="mt-2 list-group-container">
                {location.map((location, index) => (


                    <ListGroup.Item
                        onClick={() => onClick(location)}
                        key={`search-suggestion-item-${index}`}
                    >
                         <span className="item-text">{location.level2 ? `${location.level2}, ` : ''}
                             <span dangerouslySetInnerHTML={{__html: location.highlightResult}}
                                   className="highlight-result"
                             /></span>
                        {location.level1 && location.level1 != location.name &&
                            <span className="level1">{location.level1}  </span>}

                    </ListGroup.Item>
                ))}
            </ListGroup>
        </div>
    );
};

export default SearchSuggestionsList;
