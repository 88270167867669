import SearchRepository from "@domain/repository/search/ISearchRepository";
import {PostData} from "@domain/models/post/PostResponseV1";

export class DeletePostFromHistoryUseCase {

    private searchRepository: SearchRepository

    public constructor(searchRepository: SearchRepository) {
        this.searchRepository = searchRepository
    }

    execute(postData : PostData): PostData[]   {
         return  this.searchRepository.deleteSavedSearchHistory(postData);
    }
}