import React, { useEffect } from "react";
import { Card, Placeholder } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { PostData, PostResultData } from "@domain/models/post/PostResponseV1";
import Slider from "../Slider";
import placeholder from "@assets/placeholdersq.svg";
import FactoryFilterAtributes from "@utils/factories/FactoryFilterAtributes";
import { TimeUtils } from "@utils/TimeUtils";
import clockIcon from "@assets/clock-union.svg";
import locationIcon from "@assets/location-union.svg";

import "bootstrap-icons/font/bootstrap-icons.css";
import "./CardView.scss";

export interface CardComponentProps {
    id?: string;
    post?: PostData;
    isLoading?: boolean;
}

const CardView: React.FC<CardComponentProps> = ({
    post,
    id,
    isLoading = false,
}: CardComponentProps): JSX.Element => {
    const { t } = useTranslation();

    let prettyCreationTime: [number, string] = TimeUtils.getPrettyTime(
        TimeUtils.convertDataToMs(post?.created_at)
    );

    let prettyPostTitle = function (title: string) {
        const MAX_LENGTH_TITLE = 34;
        let prettyTitle: string = title;
        if (title.length >= MAX_LENGTH_TITLE)
            prettyTitle = title.substring(0, MAX_LENGTH_TITLE) + "...";
        return prettyTitle;
    };

    return isLoading ? (
        <Card className="card">
            <img src={placeholder} className="card__placecholder-image" />

            <Card.Body className="ps-0">
                <Placeholder
                    as={Card.Title}
                    className="text-left"
                    animation="wave"
                >
                    <Placeholder xs={3} className="card__placeholder-style" />
                </Placeholder>
                <Placeholder
                    as={Card.Title}
                    className="text-left"
                    animation="wave"
                >
                    <Placeholder xs={12} className="card__placeholder-style" />
                </Placeholder>
                <Placeholder
                    as={Card.Title}
                    className="text-left"
                    animation="wave"
                >
                    <Placeholder xs={8} className="card__placeholder-style" />
                </Placeholder>
                <Placeholder
                    as={Card.Title}
                    className="text-left"
                    animation="wave"
                >
                    <Placeholder xs={6} className="card__placeholder-style" />
                </Placeholder>
            </Card.Body>
        </Card>
    ) : (
        <Card>
            <div className="card__image">
                <Slider
                    height="203"
                    width="322"
                    post={post!!}
                    id={id!!}
                    borderClass="border"
                />
            </div>

            <div className="card__content">
                <Card.Body className="ps-1">
                    <span className="card__price">
                        {" "}
                        {FactoryFilterAtributes.createFixCost(post!!)}{" "}
                    </span>

                    <Card.Title className="card__title">
                        {/* {prettyPostTitle(post!!.title)} */}
                        {post!!.title}
                    </Card.Title>

                    <Card.Text>
                        <p className="card__location">
                            <img
                                src={locationIcon}
                                className="card__location__icon"
                            />
                            <span className="card__location__label">
                                {"  "}
                                {post?.place?.country_name}{" "}
                                {post?.place?.sub_admin} {post?.place?.admin}
                                {post?.place?.postal_code &&
                                    `${post?.place?.postal_code}`}
                            </span>
                        </p>

                        <p className="card__creation-time">
                            <img
                                src={clockIcon}
                                className="card__creation-time__icon"
                            />
                            <span className="card__creation-time__label">
                                {" "}
                                {prettyCreationTime[0] !== -1
                                    ? prettyCreationTime[0]
                                    : null}{" "}
                                {t(prettyCreationTime[1])}{" "}
                            </span>
                        </p>
                    </Card.Text>
                </Card.Body>
            </div>
        </Card>
    );
};

export default CardView;
