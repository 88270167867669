import { FilterOption } from "@domain/models/filter/FilterOption";
import getStartupConfig from "@utils/remote_config/StartUpConfig";
import { SortByKeys } from "@enums/SortByKeys";
import { PerPageKeys } from "@enums/PerPageKeys";
import { SearchKeys } from "@enums/SearchKeys";
import { DEFAULT_DISTANCE_KM_VALUE, DEFAULT_PERIOD, DEFAULT_UNIT } from "@utils/Constants";
import { DefaultPreselectedPlaceSearch } from "@domain/models/startup/Startup";

interface SearchBody {
    filters: {
        place: {
            lat?: number | null;
            lon?: number | null;
            distance?: number | null;
            unit?: string | null;
        };
        equivalent_cost?: {
            type: string;
            from: number;
            to: number;
        };
        sort?: {
            sort_by: string;
            sort_order: string;
        };
        attributes?: { [key: string]: any }[]; // Array of objects
    };
}

class FilterRequestBuilder {
    private static instance: FilterRequestBuilder;
    query: string | undefined;
    private catalogId: string | undefined;
    private filterPrice: FilterOption | undefined;
    private filtererOrder: FilterOption | undefined;
    private perPage: number | undefined;
    private page: number | undefined;

    defaultPreselectedPlaceSearch: DefaultPreselectedPlaceSearch | undefined;

    private constructor() {
        this.init();
    }

    public static getInstance(): FilterRequestBuilder {
        if (!FilterRequestBuilder.instance) {
            FilterRequestBuilder.instance = new FilterRequestBuilder();
        }
        return FilterRequestBuilder.instance;
    }

    private init() {
        this.query = undefined;
        this.catalogId = "CAT-1";
        this.filterPrice = undefined;
        this.page = undefined;
        this.perPage = 18;
        this.filtererOrder = new FilterOption();
    }

    private getPreselectedPlaceSearch() {
        if (!this.defaultPreselectedPlaceSearch) {
            const infoStartupConfig = getStartupConfig();
            this.defaultPreselectedPlaceSearch = infoStartupConfig?.localization?.defaultPreselectedPlaceSearch;
        }
        return this.defaultPreselectedPlaceSearch;
    }


    public addPriceFilterOrder(sortBy: SortByKeys) {
        switch (sortBy) {
            case SortByKeys.HIGH_TO_LOW:
                this.filtererOrder = { sortOrder: { sort_by: "price", sort_order: "desc" } };
                break;
            case SortByKeys.LOW_TO_HIGH:
                this.filtererOrder = { sortOrder: { sort_by: "price", sort_order: "asc" } };
                break;
            default:
                this.filtererOrder = { sortOrder: { sort_by: "created_at", sort_order: "desc" } };
                break;
        }
        return this;
    }

    public setPerPageFilter(perPage: PerPageKeys) {
        switch (perPage) {
            case PerPageKeys.ITEMS_PER_PAGE_18:
                this.perPage = 18;
                break;
            case PerPageKeys.ITEMS_PER_PAGE_30:
                this.perPage = 30;
                break;
            default:
                this.perPage = 50;
                break;
        }

        return this;
    }

    public getInitialValue(paramsMap: Map<string, string>, search_key: string): string | undefined {
        switch (search_key) {
            case SearchKeys.MIN_PRICE_SEARCH_KEY:
                return paramsMap.has(SearchKeys.MIN_PRICE_SEARCH_KEY) ? paramsMap.get(SearchKeys.MIN_PRICE_SEARCH_KEY) : undefined;
            case SearchKeys.MAX_PRICE_SEARCH_KEY:
                return paramsMap.has(SearchKeys.MAX_PRICE_SEARCH_KEY) ? paramsMap.get(SearchKeys.MAX_PRICE_SEARCH_KEY) : undefined;
            case SearchKeys.SEARCH_DISTANCE:
                return paramsMap.has(SearchKeys.SEARCH_DISTANCE) ? paramsMap.get(SearchKeys.SEARCH_DISTANCE) : DEFAULT_DISTANCE_KM_VALUE.toString();
            case SearchKeys.SEARCH_LOCATION:
                return paramsMap.has(SearchKeys.PLACE_SEARCH_KEY) ? paramsMap.get(SearchKeys.PLACE_SEARCH_KEY) : undefined;
        }
        if (paramsMap.has(search_key)) return paramsMap.get(search_key);
        return undefined;
    }

    public addPage(page: number) {
        this.page = page;
        return this;
    }

    public addQuery(query: string) {
        this.query = query;
        return this;
    }

    public addCatalogId(catalogId: string) {
        this.catalogId = catalogId;
        return this;
    }

    public build(paramsMap: Map<string, string>): SearchBody {
        const lat = paramsMap?.has(SearchKeys.LAT_SEARCH_KEY) ? parseFloat(paramsMap.get(SearchKeys.LAT_SEARCH_KEY)!) : this.getPreselectedPlaceSearch()?.lat;
        const lon = paramsMap?.has(SearchKeys.LON_SEARCH_KEY) ? parseFloat(paramsMap.get(SearchKeys.LON_SEARCH_KEY)!) : this.getPreselectedPlaceSearch()?.lon;
        const distance = paramsMap?.has(SearchKeys.SEARCH_DISTANCE) ? parseFloat(paramsMap.get(SearchKeys.SEARCH_DISTANCE)!) : DEFAULT_DISTANCE_KM_VALUE;
        const unit = paramsMap?.has(SearchKeys.SEARCH_DISTANCE_UNIT_SEARCH_KEY) ? paramsMap?.get(SearchKeys.SEARCH_DISTANCE_UNIT_SEARCH_KEY) : DEFAULT_UNIT;

        const period = paramsMap?.has(SearchKeys.PERIOD_SEARCH_KEY) ? paramsMap.get(SearchKeys.PERIOD_SEARCH_KEY)?.toLowerCase() : DEFAULT_PERIOD;
        const from = paramsMap?.has(SearchKeys.MIN_PRICE_SEARCH_KEY) ? parseInt(paramsMap.get(SearchKeys.MIN_PRICE_SEARCH_KEY)!) : 0;
        const to = paramsMap?.has(SearchKeys.MAX_PRICE_SEARCH_KEY) ? parseInt(paramsMap.get(SearchKeys.MAX_PRICE_SEARCH_KEY)!) : 0;
        this.query = paramsMap?.has( SearchKeys.QUERY_SEARCH_KEY) ? paramsMap.get(SearchKeys.QUERY_SEARCH_KEY)! : "";



        let searchBody: SearchBody = {
            filters: {
                place: {
                    lat: lat,
                    lon: lon,
                    distance: distance,
                    unit: unit,
                },
            },
        };

        if (to > 0) {
            searchBody.filters.equivalent_cost = {
                type: period!!,
                from: from,
                to: to,
            };
        }

        if (this.filtererOrder) {
            const sortBy = this.filtererOrder.sortOrder?.sort_by ?? "id";
            const sortOrder = this.filtererOrder.sortOrder?.sort_order ?? "desc";

            searchBody.filters.sort = {
                sort_by: sortBy,
                sort_order: sortOrder,
            };
        }

        const keysToExclude = new Set<string>([
            SearchKeys.LAT_SEARCH_KEY,
            SearchKeys.LON_SEARCH_KEY,
            SearchKeys.SEARCH_DISTANCE,
            SearchKeys.SEARCH_DISTANCE_UNIT_SEARCH_KEY,
            SearchKeys.PERIOD_SEARCH_KEY,
            SearchKeys.MIN_PRICE_SEARCH_KEY,
            SearchKeys.MAX_PRICE_SEARCH_KEY,
            SearchKeys.QUERY_SEARCH_KEY
        ]);

        const otherKeys = new Map<string, string>(
            Array.from(paramsMap.entries()).filter(([key]) => !keysToExclude.has(key))
        );

        if (otherKeys.size > 0) searchBody.filters.attributes = [];

        for (const [key, value] of otherKeys) {
            searchBody.filters.attributes?.push({
                search_key: key,
                values: value.split(",").map((val) => val.trim()),
                logicalOperator:  value.includes(",") ? "or" : "and",
            });
        }

        return searchBody;
    }

    public convertToPrettyName(str: string | undefined): string {
        if (!str) {
            return "uae";
        }
        return str.toLowerCase().replace(/\s+/g, "-");
    }
}

export default FilterRequestBuilder;
