import React from "react";

interface LoginRegisterIconProps {
    className?: string;
}

const LoginRegisterIcon = ({ className }: LoginRegisterIconProps) => {
    return (
        <svg
            className={className}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.64062 16.9375C4.08498 16.4398 6.16611 14.1497 6.776 14.1497H13.2244C14.1082 14.1497 15.9123 16.0481 16.3594 16.7172M19.25 10C19.25 15.1086 15.1086 19.25 10 19.25C4.89137 19.25 0.75 15.1086 0.75 10C0.75 4.89137 4.89137 0.75 10 0.75C15.1086 0.75 19.25 4.89137 19.25 10ZM13.3135 6.84725C13.3135 5.08251 11.8237 3.64062 10.0003 3.64062C8.1769 3.64062 6.68709 5.08251 6.68709 6.84725C6.68709 8.61199 8.1769 10.0539 10.0003 10.0539C11.8237 10.0539 13.3135 8.61199 13.3135 6.84725Z"
                stroke="#001A1A"
                strokeWidth="1.5"
            />
        </svg>
    );
};

export default LoginRegisterIcon;
