import { RailData } from "@domain/models/rail/RailData";
import {RAIL_KEY, SEARCHES_API_ID} from "@domain/models/startup/ServiceId";
import { Startup } from "@domain/models/startup/Startup";
import { StartupBaseUrl } from "@domain/models/startup/StartupBaseUrl";
import IRailRepository from "@domain/repository/rail/IRailRepository";
import {ApiUtilsUtils} from "@utils/ApitUtils";
import api from "@data/Api";

export default class RailApi implements IRailRepository {

    private startupConfig: Startup | undefined


    constructor(startupConfig: Startup | undefined) {
        this.startupConfig = startupConfig
    }

    getRail(railId: String): Promise<RailData> {

        const url = `${ApiUtilsUtils.getServiceEndPointByServiceId(this.startupConfig, RAIL_KEY)}?id=${railId}`;

        return api.get(url)
            .then(response => {
                console.log("[getRailDetails] response success");
                return response.data;  // Axios automatically parses JSON and puts it in response.data
            })
            .catch(error => {
                console.error("[getRailDetails] error in response", error);
                throw new Error(error.message);  // Pass the error message
            });

    }



}