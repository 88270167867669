import React, {useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';
import {Attribute} from "@presentation/views/components/DynamicForm/DynamicForm";
import {useTranslation} from "react-i18next";

interface DynamicInputProps {
    attribute: Attribute;
    formData: Record<string, any>;
    onInputChange: (attribute: string, value: any) => void;
    validateInput?: (value: string) => {
        isValid: boolean;
        errorMessage: string
    };
}

const RadioListInput: React.FC<DynamicInputProps> = ({
                                                         attribute,
                                                         formData,
                                                         onInputChange,
                                                         validateInput,
                                                     }) => {



    const {t} = useTranslation(); // Use useTranslation hook here
    const defaultValue =attribute.values?.[0]?.value; // Set your desired default value here

    useEffect(() => {
        // Set the default value and call onInputChange when the component mounts
        if (!formData[attribute.search_key]) {
            onInputChange(attribute.search_key, [
                {
                    value: defaultValue,
                    name_dict_key: attribute?.values ? attribute?.values?.[0]?.name_dict_key: null,
                    qualifier: attribute?.values ? attribute?.values[0].qualifier : null
                },
            ]);
        }
    }, [attribute.search_key, formData]);

    return (
        <div key={attribute.search_key} className="mt-2">
            <Form.Group controlId={attribute.search_key}>
                <Form.Label>{attribute.name}</Form.Label>
                {attribute.mandatory && <span className="text-danger">*</span>}
                <Form.Select
                    value={formData[attribute.search_key]?.[0]?.value}
                    onChange={(e) =>
                        onInputChange(attribute.search_key, [
                            {
                                value: e.target.value,
                                name_dict_key: attribute?.values ? attribute?.values.find(it => it.value == e.target.value)?.name_dict_key : null,
                                qualifier: attribute?.values ? attribute?.values[0].qualifier : null
                            },
                        ])
                    }
                >
                    {attribute.values?.map((valueObj) => (
                        <option key={valueObj.value} value={valueObj.value}>
                            {t(valueObj.name_dict_key)}
                        </option>
                    ))}
                </Form.Select>
            </Form.Group>
        </div>
    );
};

export default RadioListInput;
