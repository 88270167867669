import PlaceResult = google.maps.places.PlaceResult;


export enum Type {
    POST_CODE = 'postal_code',
    COUNTRY = 'country',
    ADMIN_AREA ='administrative_area_level_1',
    SUB_ADMIN_AREA ='administrative_area_level_2'
}

export enum ValueType {
    SHORT_NAME = 'short_name',
    LONG_NAME = 'long_name'
}

class GooglePlacesUtils {

    static getComponentValue(placeResult: PlaceResult | null, type: Type, value: ValueType = ValueType.LONG_NAME): string | null {
        if (!placeResult || !placeResult.address_components) {
            return null
        }
        for (const component of placeResult.address_components) {
            for (const typeComponent of component.types) {
                if (typeComponent.includes(type)) {
                    if (value == ValueType.LONG_NAME) {
                        return component.long_name;
                    }
                    return component.short_name
                }
            }
        }
        return null; // Return null if postcode not found
    }
}

export default GooglePlacesUtils;
