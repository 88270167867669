class ApiUtils {
    static getPageFromUrl(apiUrl: string ): number {
       if(apiUrl == null){
           return 1;
       }
        const url = new URL(apiUrl);
        const pageParam = url.searchParams.get('page');
        const page = pageParam ? parseInt(pageParam, 10) : 1;

        return isNaN(page) ? 1 : page;
    }

    static createQueryString(inputString: string) {
        const keyValuePairs = inputString.split(',');
        let queryString = '';

        keyValuePairs.forEach((pair, index) => {
            const [key, value] = pair.split('=');

            if (value !== 'undefined' && value !== undefined && value !== '') {
                if (queryString === '') {
                    queryString += `?${key}=${value}`;
                } else {
                    queryString += `&${key}=${value}`;
                }
            }
        });

        return queryString;
    }
}

export default ApiUtils;