import React from "react";
import {Col, Row} from "react-bootstrap";
import copyright from "@assets/copyright.svg";

export function CopyRight(): React.ReactElement {
    return   <Row className="justify-content-center gx-0">
        <Col xs="auto" sm="auto" md="auto" className="mb-5">
            <div>
                {" "}
                <img
                    className="mb-1"
                    src={copyright}
                    width="14px"
                    alt="logo"
                />{" "}
                Rentallo Limited {new Date().getFullYear()}
            </div>
        </Col>
    </Row>
}