import SearchUseCase from "../../domain/usecases/search/SearchUseCase";
import DataState from "../../domain/models/state/DataState";
import SearchLocationUseCase from "@domain/usecases/location/SearchLocationUseCase";
import {SearchState} from "@domain/models/state/SearchState";
import {PostData, PostResultData} from "@domain/models/post/PostResponseV1";
import GetMySavedSearchesUseCase from "@domain/usecases/saved/GetMySavedSearchesUseCase";
import {SavedSearchResult} from "@domain/models/search/SavedSearch";
import {LocationDomain} from "@domain/models/search/SugestionType";
import SearchRecommendationsUseCase from "@domain/usecases/search/SearchRecommendationsUseCase";
import {GetPostsInHistoryUseCase} from "@domain/usecases/search/GetPostsInHistoryUseCase";
import {DeletePostFromHistoryUseCase} from "@domain/usecases/search/DeletePostFromHistoryUseCase";
import {SavePostInHistoryUseCase} from "@domain/usecases/search/SavePostInHistoryUseCase";
import BaseViewModel from "@viewmodels/BaseViewModel";

class SearchViewModel extends BaseViewModel<SearchState> {

    public isLoadingRequest: boolean = false;


    public error: Error | undefined

    public searchUserCase: SearchUseCase

    public searchLocationUseCase: SearchLocationUseCase

    private getMySavedSearchesUseCase: GetMySavedSearchesUseCase

    private searchRecommendationsUseCase: SearchRecommendationsUseCase

    private getPostsFromHistory: GetPostsInHistoryUseCase

    private deletePostFromHistory: DeletePostFromHistoryUseCase

    private savePostInHistory: SavePostInHistoryUseCase


    public constructor(searchUserCase: SearchUseCase,
                       searchLocationUseCase: SearchLocationUseCase,
                       getMySavedSearchesUseCase: GetMySavedSearchesUseCase,
                       getPostRecommendationsUseCase: SearchRecommendationsUseCase,
                       getPostsFromHistory: GetPostsInHistoryUseCase,
                       deletePostFromHistory: DeletePostFromHistoryUseCase,
                       savePostInHistory: SavePostInHistoryUseCase
    ) {
        super();
        this.searchUserCase = searchUserCase
        this.searchLocationUseCase = searchLocationUseCase
        this.getMySavedSearchesUseCase = getMySavedSearchesUseCase
        this.searchRecommendationsUseCase = getPostRecommendationsUseCase
        this.getPostsFromHistory = getPostsFromHistory
        this.deletePostFromHistory = deletePostFromHistory
        this.savePostInHistory = savePostInHistory
    }

    get getSearchSuggestionsData() {
        return this.getState().content.postSuggestions
    }

    get getSearchResultsData() {
        return this.getState().content.postResultData
    }

    get getPostRecommendations() {
        return this.getState().content.postRecommendations
    }

    getMySavedSearches() {
        return this.getState().content.savedSearches
    }

    get getLocationData() {
        return this.getState().content.locationData
    }

    getPostFromHistory() {
        return this.getState().content.postHistory
    }

    loadPostFromHistory() {
        this.getState().content.postHistory = this.getPostsFromHistory.execute()
    }

    savePostToHistory(postData: PostData) {
        this.savePostInHistory.execute(postData)
    }


    removePostToHistory(postData: PostData) {
        this.getState().content.postHistory = this.deletePostFromHistory.execute(postData)
        this.notifyStateChanged()
    }


    handlePostRecommendation() {
        const history = this.getPostsFromHistory.execute()

        this.searchRecommendationsUseCase.searchRecommendations(undefined).then(response => {
                this.getState().content.postHistory = history
                this.getState().content.postRecommendations = response
                this.notifyStateChanged()
            }
        ).catch(error => {
            this.error = error
        })
    }

    handleSavedSearch() {

        this.getMySavedSearchesUseCase.getMySavedSearches().then(response => {
                this.handleNewSaveSearches(response)
                this.notifyStateChanged()
            }
        ).catch(error => {
            this.error = error
            this.isLoadingRequest = false
        })
    }

    handleSearchExperienceResult(categoryId: string, params: Map<string, string>) {
        this.isLoadingRequest = true
        this.searchUserCase.searchPostResult(categoryId, params).then(response => {
                this.handleNewSearchExperienceResultData(response)
                this.notifyStateChanged()
            }
        ).catch(error => {
            this.error = error
            this.isLoadingRequest = false
        })
    }


    handleSearchResult(categoryId: string, params: Map<string, string>) {
        this.isLoadingRequest = true
        this.searchUserCase.searchPostResult(categoryId, params).then(response => {
                this.handleNewSearchResultData(response)
                this.notifyStateChanged()
            }
        ).catch(error => {
            this.error = error
            this.isLoadingRequest = false
        })
    }

    handleSearchChange(paramsMap: Map<string, string>) {
        this.isLoadingRequest = true

        this.searchUserCase.searchPostSuggestions(paramsMap).then(response => {
                this.handleNewSearchData(response)
            }
        ).catch(error => {
            this.error = error
            this.isLoadingRequest = false
        })
    }

    private handleNewSearchData(postData: PostResultData | undefined) {
        // let data = postData?.data.map((item) => {
        //     return {
        //         id: item.title,
        //         name: item.title,
        //         type: TypeSuggestion.SUGGESTION_SEARCH_POST,
        //
        //     } as SuggestionsType
        // })
        // this.state = new DataState({postSuggestions: data})
        //
        // this.isLoadingRequest = false
    }

    private handleNewSaveSearches(response: SavedSearchResult) {
        this.getState().content.savedSearches = response
        this.isLoadingRequest = false
    }

    private handleNewSearchResultData(postData: PostResultData | undefined) {
        this.getState().content.postResultData = postData
        this.isLoadingRequest = false
    }

    private handleNewSearchExperienceResultData(postData: PostResultData | undefined) {
        this.getState().content.searchExperienceResultData = postData
        this.isLoadingRequest = false
    }


    handleLocationChange(searchTerm: string) {
        this.searchLocationSuggestion(searchTerm)
    }

    private searchLocationSuggestion(query: string) {
        this.searchLocationUseCase.searchLocationSuggestions(query).then(response => {
                  this.handleNewLocationData(response)
                this.notifyStateChanged()
            }
        ).catch(error => {
            this.error = error
        })
    }


    private handleNewLocationData(locationData: LocationData[] | undefined) {


        this.getState().content.locationData = locationData?.map((item) => {
            const level1 = item.hierarchy.length > 0 ? item.hierarchy[0].values['en'] : undefined;
            const level2 = item.hierarchy.length > 1 ? item.hierarchy[item.hierarchy.length - 1].values['en'] : undefined;

            return {
                name: item.values['en'],
                level1: level1,
                level2: level2,
                lat: item.lat,
                lon: item.lon,
                highlightResult: item.highlightResult,
            } as LocationDomain;
        })

    }

    protected createState(): DataState<SearchState> {
        return new DataState({});
    }


}

export default SearchViewModel;