import {PostData, PostResultData} from "@domain/models/post/PostResponseV1"
import IPostRepository from "@domain/repository/post/IPostRepository"

export default class GetMyPostsUseCase {

    private postRepository: IPostRepository

    public constructor(postRepository: IPostRepository) {
        this.postRepository = postRepository
    }


    /**
     * Get list of my posts
     * @throws {Error} in case of getting non 2xx response or empty query
     */
    public async getMyPosts(): Promise<PostResultData> {
        return new Promise(async (resolve, reject) => {
            await this.postRepository.getMyPosts().then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    }
}