import {Path} from '../enums/Path';

import React, {FunctionComponent, PropsWithChildren, ReactNode} from 'react';
import Home from '../../../framework/presentation/views/pages/Home';
import PostDetails from '@presentation/views/pages/PostDetails';
import Privacy from '@presentation/views/pages/Privacy';
import TermOfService from '@presentation/views/pages/TermsService';
import How from '@presentation/views/pages/How';
import Faqs from '@presentation/views/pages/Faqs';
import SearchBar from 'framework/presentation/views/components/Search/SearchBarResultsPage';
import SearchResults from '@presentation/views/pages/Search/SearchResults';
import Login from "@presentation/views/pages/Login";
import Posts from "@presentation/views/pages/Posts";
import Favourites from "@presentation/views/pages/Favourites";
 import CreatePost from "../../../framework/presentation/views/components/CreatePost/CreatePost";
import MyDetails from "@presentation/views/pages/MyDetails";
import Saved from "@presentation/views/pages/Saved";
import NotFound from "@presentation/views/pages/NotFound";


export interface IRoute<T = any>  {
    component:  FunctionComponent<T>;
    footer?: React.JSXElementConstructor<PropsWithChildren<ReactNode>> | null;
    header?: React.JSXElementConstructor<PropsWithChildren<ReactNode>> | null;
    subRoutes?: Record<string, IRoute>;
}


export const Routes: Record<Path, IRoute> = {

    [Path.HOME]: {
        component: Home,
        footer: null,
        header: null,
    },
    [Path.ROOT]: {
        component: Home,
        footer: null,
        header: null,
    },
    [Path.LOGIN]: {
        component: Login,
        footer: null,
        header: null,
    },
    [Path.SEARCH]: {
        component: SearchResults,
        footer: null,
        header: null,
    },
    [Path.SEARCH_DETAILS]: {
        component: SearchResults,
        footer: null,
        header: null,
    },
    [Path.POST_DETAILS]: {
        component: PostDetails,
        footer: null,
        header: null,
    },
    [Path.PRIVACY]: {
        component: Privacy,
        footer: null,
        header: null
    },
    [Path.TERMS]: {
        component: TermOfService,
        footer: null,
        header: null
    },
    [Path.HOW]: {
        component: How,
        footer: null,
        header: null
    },
    [Path.FAQS]: {
        component: Faqs,
        footer: null,
        header: null
    },
    [Path.DASHBOARD]: {
        component: Home,
        footer: null,
        header: null
    },
    [Path.POSTS]: {
        component: Posts,
        footer: null,
        header: null
    },
    [Path.CREATE_POST]: {
        component: CreatePost,
        footer: null,
        header: null
    },
    [Path.FAVOURITES]: {
        component: Favourites,
        footer: null,
        header: null
    },
    [Path.SAVED]: {
        component: Saved,
        footer: null,
        header: null
    },
    [Path.MY_DETAILS]: {
        component: MyDetails,
        footer: null,
        header: null
    },
    [Path.NOT_FOUND]: {
        component: NotFound,
        footer: null,
        header: null
    }

}