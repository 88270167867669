import React, {Component} from 'react';
import {Form} from 'react-bootstrap';

interface CustomInputProps {
    label: string;
    value: string;
    mandatory:boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void; // Make onBlur prop optional
    error: string | null;
}

class TextAreaInput extends Component<CustomInputProps> {
    render() {
        const {label, value,mandatory, onChange, onBlur, error} = this.props;
        return (
            <Form.Group className="mt-2">
                <Form.Label>{label}</Form.Label>
                {mandatory && <span className="text-danger">*</span>}
                <Form.Control
                    as="textarea"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    style={{resize: 'none'}}
                    rows={5}
                    onKeyPress={(e) => {
                        // Prevent user input when the character limit is reached
                        if (value.length >= 10000) {
                            e.preventDefault();
                        }
                    }}
                />
                {error && <div className="text-danger">{error}</div>}
            </Form.Group>
        );
    }
}

export default TextAreaInput;
