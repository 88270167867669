import {Attribute, Catalog, Value} from "@domain/models/catalog/CatalogModel";
import {FilterInputTypes} from "@domain/models/enums/FilterInputTypes";
import {SearchKeys} from "@domain/models/enums/SearchKeys";
import R from "@strings/R";
import {SortByKeys} from "@enums/SortByKeys";
import {PerPageKeys} from "@enums/PerPageKeys";

class MetaSearchUtils {


    static getSortsRadiusAttribute(): Attribute {
        return {
            name: "Search radius",
            search_key: SearchKeys.SORT_LIST_RESULTS,
            type_for_search: FilterInputTypes.SEARCH_RADIO_LIST,
            name_dict_key: R.searchResults_sort_list_results,
            type: FilterInputTypes.SEARCH_RADIO_LIST,
            mandatory: false,
            values: [
                {
                    value: SortByKeys.LOW_TO_HIGH,
                    name_dict_key: "attribute_value_low_to_high",
                    pickedValue: false
                },
                {
                    value:  SortByKeys.HIGH_TO_LOW,
                    name_dict_key: "attribute_value_high_to_low",
                    pickedValue: false
                },
                {
                    value:  SortByKeys.NEWEST,
                    name_dict_key: "attribute_value_most_recent",
                    pickedValue: false
                }
            ]
        }
    }

    static getItemPerPageRadiusAttribute(): Attribute {
        return {
            name: "Items per page",
            search_key: SearchKeys.ITEMS_PER_PAGE,
            type_for_search: FilterInputTypes.ITEMS_PER_PAGE,
            name_dict_key: R.searchResults_items_per_page,
            type: FilterInputTypes.SEARCH_RADIO_LIST,
            mandatory: false,
            values: [
                {
                    value: PerPageKeys.ITEMS_PER_PAGE_18,
                    name_dict_key: "attribute_value_18_items_per_page",
                    pickedValue: false
                },
                {
                    value:  PerPageKeys.ITEMS_PER_PAGE_30,
                    name_dict_key: "attribute_value_30_items_per_page",
                    pickedValue: false
                },
                {
                    value:  PerPageKeys.ITEMS_PER_PAGE_50,
                    name_dict_key: "attribute_value_50_items_per_page",
                    pickedValue: false
                }
            ]
        }
    }

    static getSearchRadiusAttribute(): Attribute {
        return {
            name: "Search radius",
            search_key: SearchKeys.SEARCH_DISTANCE,
            type_for_search: FilterInputTypes.SEARCH_RADIO_LIST,
            name_dict_key: R.searchResults_search_radius,
            type: FilterInputTypes.SEARCH_RADIO_LIST,
            mandatory: false,
            values: [{
                value: "1",
                name_dict_key: "attribute_value_min_distance_one",
                pickedValue: false
            },
                {
                    value: "2",
                    name_dict_key: "attribute_value_min_distance_two",
                    pickedValue: false,
                 },
                {
                    value: "5",
                    name_dict_key: "attribute_value_min_distance_five",
                    pickedValue: false
                },
                {
                    value: "10",
                    name_dict_key: "attribute_value_min_distance_ten",
                    pickedValue: false
                },
                {
                    value: "20",
                    name_dict_key: "attribute_value_min_distance_twenty",
                    pickedValue: false
                },
                {
                    value: "30",
                    name_dict_key: "attribute_value_min_distance_thirty",
                    pickedValue: false
                },
                {
                    value: "40",
                    name_dict_key: "attribute_value_min_distance_forty",
                    pickedValue: false
                },
                {
                    value: "50",
                    name_dict_key: "attribute_value_min_distance_fifty",
                    pickedValue: false
                }
            ]
        }
    }


    static convertCatalogChildrenIntoAttributes(catalog: Catalog | undefined) {

        let listOfValues: Value[] | undefined = catalog?.children?.map(ch => {
            return {value: ch.name_dict_key, name_dict_key: ch.name_dict_key, pickedValue: false} as Value
        })

        return {
            name: "",
            search_key: "",
            name_dict_key: catalog?.name_dict_key!!,
            type: FilterInputTypes.CHECKED_LIST,
            mandatory: false,
            values: listOfValues,
            type_for_search: FilterInputTypes.CHECKED_LIST
        } as Attribute
    }


}

export default MetaSearchUtils
