import React, {ChangeEvent, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import SearchViewModel from "@viewmodels/SearchViewModel";
import PaginationView from "@presentation/views/components/Pagination";
import FilterMenu from "@presentation/views/components/FilterMenu";
import {Catalog, CatalogModel} from "@domain/models/catalog/CatalogModel";
import getCatalogConfig from "@utils/remote_config/CatalogConfig";
import FactoryMetaFilter from "@utils/factories/FactoryMetaFilter";
import {FilterInputTypes} from "@enums/FilterInputTypes";
import {Col, Container, Row} from "react-bootstrap";
import CardView from "@presentation/views/components/CardView";
import MetaSearchUtils from "@utils/MetaSearchUtils";
import {SortByKeys} from "@enums/SortByKeys";
import {PerPageKeys} from "@enums/PerPageKeys";
import "./SearchResults.scss"
import UrlUtils from "@utils/UrlUtils";
import EmptyListMessage from "@presentation/views/components/EmptyListMessage/EmptyListMessage";
import {Path} from "@enums/Path";
import SearchBar from "@presentation/views/components/Search/SearchBarResultsPage";
import FilterRequestBuilder from "@utils/filter/FilterRequestBuilder";
import {useSearchViewModel} from "@root/framework/hooks/useSearchViewModel";
import SearchUseCase from "@domain/usecases/search/SearchUseCase";

import TYPES from "@di/viewmodels/ViewModelTypes";
import {useInjection} from "@root/ioc.react";
import {IProvider} from "@di/viewmodels/ViewModelProvider";
import NavigationUtils, {NavigationParams} from "@utils/NavigationUtils";

interface SearchResultsProps {
    searchViewModel: SearchViewModel;
}



// Note: Not using React.FC here due to potential issues with `observer` from mobx-react
const SearchResults: React.FunctionComponent<SearchResultsProps> = ({searchViewModel}: SearchResultsProps) => {
    const catalogId = "CAT-1";

    const location = useLocation();

    let currentCatalog: Catalog | undefined;
    let catalogConfig: CatalogModel | undefined = getCatalogConfig();
    const navigate = useNavigate()

    const searchUseCase = useInjection<IProvider<SearchUseCase>>(TYPES.SearchUseCase).provide()
    const { stateSearch, searchPosts} = useSearchViewModel(searchUseCase);

    currentCatalog = catalogConfig?.catalog.find((ct) => {
        if (ct.id == catalogId) {
            return ct;
        }
    });

    const handleSearch = () => {
        const paramsMap = UrlUtils.getParamsMap(location.search)
        searchPosts(catalogId, paramsMap)
    };

    const onClickClearAllFilters = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        //TODO for now default is always dubai
        navigate(Path.SEARCH.replace(":params", "dubai"), {replace: true});
    }

    const onHideChanged = (show: boolean) => {
    }

    useEffect(() => {
        handleSearch();
    }, []);

    useEffect(() => {
        handleSearch();
    }, [location]);

    const searchResults = stateSearch?.postResultData;

    function updateQueryParam(body: any) {
        const newParams: NavigationParams = body
        const updatedSearchParams = NavigationUtils.buildSearchParams(newParams);
        navigate(updatedSearchParams);
    }

    return (
        <>
            <SearchBar updateQueryParam={updateQueryParam}/>
            <Container>
                <Row>
                    <div className="filter-menu desktop-view">
                        <FilterMenu className={"mt-4 search-results "} showFilter={true}
                                    attributes={currentCatalog?.attributes} onHideChanged={onHideChanged}
                                    searchViewModel={searchViewModel}/>
                    </div>
                    <Col md={9}>

                        <Row className="gx-0 ">
                            <Row className="mb-2 header-search-results">
                                <Col className="align-content-center" md={5}>
                                    {searchResults?.meta.total ?
                                        <span>{searchResults?.meta.total} results</span> : ""}
                                </Col>
                                {searchResults?.meta.total != 0 ? (
                                    <Col md={6} className="ms-auto d-flex justify-content-end  ">

                                        <Col className="me-3 mb-2">
                                            {FactoryMetaFilter.createFixMetaFilter(
                                                FilterInputTypes.SORT_RESULT_LIST,
                                                searchViewModel,
                                                MetaSearchUtils.getSortsRadiusAttribute(),
                                                (event: ChangeEvent<HTMLSelectElement>) => {
                                                    FilterRequestBuilder.getInstance().addPriceFilterOrder(event.target.value as SortByKeys)
                                                }
                                            )}
                                        </Col>
                                        <Col>
                                            {
                                                FactoryMetaFilter.createFixMetaFilter(FilterInputTypes.ITEMS_PER_PAGE,
                                                    searchViewModel,
                                                    MetaSearchUtils.getItemPerPageRadiusAttribute(),
                                                    (event: ChangeEvent<HTMLSelectElement>) => {
                                                        FilterRequestBuilder.getInstance().setPerPageFilter(event.target.value as PerPageKeys)
                                                    })
                                            }
                                        </Col>
                                    </Col>) : null}
                            </Row>

                            {searchResults?.meta.total != 0 ? (
                                <Row className="mb-2">
                                    {
                                        searchResults?.data?.map((post) => (
                                            <div className="custom-sm custom-md custom-lg" key={post.id}>
                                                <CardView isLoading={searchViewModel.isLoadingRequest}
                                                          post={post}
                                                          id={post.post_meta?.catalog_id}
                                                />
                                            </div>
                                        ))
                                    }
                                </Row>) : <EmptyListMessage onClickEmpty={onClickClearAllFilters}/>}
                        </Row>

                        {searchResults?.data?.length !== 0 && (
                            <PaginationView meta={searchResults?.meta}/>
                        )}
                    </Col>

                </Row>
            </Container>
        </>
    );
}

export default SearchResults;
