import React from "react";
import { useNavigate } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import "firebase/auth";

import FirebaseApp from "../../../../../firebaseApp";
import { Path } from "@enums/Path";
import AuthNavigationSignedIn from "../AuthNavigationSignedIn/AuthNavigationSignedIn";
import AuthNavigationSingedOut from "./AuthNavigationSingedOut";

import "bootstrap/dist/css/bootstrap.min.css";
import "./AuthNavigation.scss";

interface AuthNavigationProps {
    isSignedIn: boolean;
}

const AuthNavigation: React.FC<AuthNavigationProps> = ({ isSignedIn }) => {
    const navigate = useNavigate();

    const handleSignOut = () => {
        FirebaseApp.getInstance().getFirebaseAuth().signOut();
        navigate(Path.LOGIN);
    };

    function handleLoginRegisterClick() {
        navigate(Path.LOGIN);
    }

    return (
        <div className="auth-navigation">
            <Navbar bg="white" expand="lg">
                {isSignedIn ? (
                    <AuthNavigationSignedIn onSignOut={handleSignOut} />
                ) : (
                    <AuthNavigationSingedOut
                        onLoginRegisterClick={handleLoginRegisterClick}
                    />
                )}
            </Navbar>
        </div>
    );
};

export default AuthNavigation;
