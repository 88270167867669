import SearchRepository from "@domain/repository/search/ISearchRepository";
import {LocationDomain} from "@domain/models/search/SugestionType";

export class SaveCurrentLocationUseCase {

    private searchRepository: SearchRepository

    public constructor(searchRepository: SearchRepository) {
        this.searchRepository = searchRepository
    }

    execute(location: LocationDomain) {
        this.searchRepository.saveCurrentLocation(location);
    }
}