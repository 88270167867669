import {PostResultData} from "@domain/models/post/PostResponseV1";
import SearchUseCase from "@domain/usecases/search/SearchUseCase";
import {useCallback, useEffect, useState} from "react";
import SearchRecommendationsUseCase from "@domain/usecases/search/SearchRecommendationsUseCase";
import {LocationDomain} from "@domain/models/search/SugestionType";


export interface SearchRecommendationPostViewState {
    postResultData?: PostResultData | null,
    isLoading?: boolean;
    error?: string | null;
}

export const useSearchRecommendationViewModel = (searchRecommendationsUseCase: SearchRecommendationsUseCase) => {
    const [state, setState] = useState<SearchRecommendationPostViewState>({ isLoading: false });

    const searchRecommendationPosts = useCallback((locationDomain? : LocationDomain) => {
        setState({ isLoading: true });
        searchRecommendationsUseCase.searchRecommendations(locationDomain)
            .then(postResultData => {
                setState({ postResultData : postResultData, isLoading: false, error: null });
            })
            .catch(error => {

                setState({ error: 'Error fetching posts', isLoading: false });
            });
    }, [searchRecommendationsUseCase]);

    return {
        stateRecommendationPosts: state,
        searchRecommendationPosts: searchRecommendationPosts
    };
};