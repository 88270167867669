import React, {useRef, useState} from 'react';
import {Button, ButtonGroup} from 'react-bootstrap';
import "./ScrollableButtonGroup.scss"
import nextButton from "@assets/next-button-search-experience.svg";
import arrow from "@assets/arrow_dropdown_ic.svg";
import cross from "@assets/cross_ic.svg";
import {LocationDomain} from "@domain/models/search/SugestionType";
import {CurrentLocationViewStateViewState} from "@root/framework/hooks/useGetCurrentLocationViewModel";


export interface ScrollableButtonGroupProp {
    onLocationClick: (isFromCities: boolean ,locationDomain: LocationDomain | undefined) => void,
    handleRemoveCity: () => void,
    selectedCity?: string,
    locations: LocationDomain[],
    stateGetCurrentLocation: CurrentLocationViewStateViewState;
}

const ScrollableButtonGroup: React.FC<ScrollableButtonGroupProp> = ({
                                                                        onLocationClick,
                                                                        selectedCity,
                                                                        handleRemoveCity,
                                                                        locations,
                                                                        stateGetCurrentLocation
                                                                    }) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);


    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({left: 100, behavior: 'smooth'});
        }
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        if (scrollContainerRef.current) {
            setIsDragging(true);
            setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
            setScrollLeft(scrollContainerRef.current.scrollLeft);
        }
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (!isDragging || !scrollContainerRef.current) return;
        e.preventDefault();
        const x = e.pageX - scrollContainerRef.current.offsetLeft;
        const walk = (x - startX) * 2; // Multiply by 2 to increase the scroll speed
        scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    };
    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
    };


    const isOnAny = stateGetCurrentLocation.locationDomain && !locations.includes(stateGetCurrentLocation.locationDomain)
    return (
        <div className="scrollable-button-group main"
             onMouseDown={handleMouseDown}
             onMouseMove={handleMouseMove}
             onMouseUp={handleMouseUp}
             onMouseLeave={handleMouseLeave}
             ref={scrollContainerRef}>
            <ButtonGroup className="scrollable-button-group button-group">
                <button key="-1" className={isOnAny  && stateGetCurrentLocation.locationDomain?.isFromCities ? "se-button selected" : "se-button" }
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation()
                            onLocationClick(false,undefined)
                        }}>
                    <span
                        className={"se-button-text"}>{isOnAny && stateGetCurrentLocation.locationDomain?.isFromCities ? stateGetCurrentLocation.locationDomain?.name : "Any location"}  </span> {
                    isOnAny && stateGetCurrentLocation.locationDomain?.isFromCities ?
                    <img className="scrollable-button-group arrow" onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        handleRemoveCity()
                    }} src={cross}
                         alt="remove-icon"/> :
                    <img className="scrollable-button-group arrow" src={arrow} alt="arrow-icon"/>}</button>
                {/*TODO do not implemented yet */}
                {/*<button key="-2" className="se-button">Nearby</button>*/}
                {locations.map((location, index) => (
                    <button onClick={(e: React.MouseEvent) => {
                        e.preventDefault()
                        onLocationClick(false, location)
                    }} key={index}
                            className={stateGetCurrentLocation.locationDomain?.name == location.name&&   stateGetCurrentLocation.locationDomain?.isFromCities == false ? "se-button selected" : "se-button"}>
                        {location.name}
                    </button>
                ))}
            </ButtonGroup>
            <img className="scrollable-button-group next-button" src={nextButton} alt="next-icon" onClick={scrollRight}
                 style={{cursor: 'pointer'}}/>
        </div>
    );
};

export default ScrollableButtonGroup;
