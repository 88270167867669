import {SaveCurrentLocationUseCaseProvider} from "@di/viewmodels/ViewModelProvider";
import {GetCurrentLocationUseCase} from "@domain/usecases/search/GetCurrentLocationUseCase";
import {SaveCurrentLocationUseCase} from "@domain/usecases/search/SaveCurrentLocationUseCase";


let TYPES = {
    CatalogViewModel: Symbol("CatalogViewModel"),
    PostViewModel: Symbol("PostViewModel"),
    RailViewModel: Symbol("RailViewModel"),
    SearchViewModel: Symbol("SearchViewModel"),
    UserViewModel: Symbol("UserViewModel"),

    //Use cases
    GetPostDetailsUseCase: Symbol("GetPostDetailsUseCase"),
    GetMyPostsUseCase: Symbol("GetMyPostsUseCase"),
    DeleteMyPostUseCase: Symbol("DeleteMyPostUseCase"),
    GetMyFavouritesUseCase: Symbol("GetMyFavouritesUseCase"),
    CreatePostUseCase: Symbol("CreatePostUseCase"),
    DeleteFavouriteUseCase: Symbol("DeleteFavouriteUseCase"),

    GetUserAccountUseCase: Symbol("GetUserAccountUseCase"),
    GetTokenUseCase: Symbol("GetTokenUseCase"),
    SignOutUseCase: Symbol("SignOutUseCase"),

    SearchUseCase: Symbol("SearchUseCase"),
    SearchLocationUseCase: Symbol("SearchLocationUseCase"),
    GetMySavedSearchesUseCase: Symbol("GetMySavedSearchesUseCase"),
    SearchRecommendationsUseCase: Symbol("SearchRecommendationsUseCase"),
    GetPostsInHistoryUseCase: Symbol("GetPostsInHistoryUseCase"),
    DeletePostFromHistoryUseCase: Symbol("DeletePostFromHistoryUseCase"),
    SavePostInHistoryUseCase: Symbol("SavePostInHistoryUseCase"),
    GetCurrentLocationUseCase: Symbol("GetCurrentLocationUseCase"),
    SaveCurrentLocationUseCase: Symbol("SaveCurrentLocationUseCase"),

};

export default TYPES;


