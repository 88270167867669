import { RailHeaderData } from "@domain/models/rail/RailHeaderData";
import { getRemoteConfig, getString } from "firebase/remote-config";
import { REMOTE_CONFIG_RAILS_KEY } from "./RemoteConfigKeys";

export default function getRailConfig(): RailHeaderData | undefined {
    
    const remoteConfig = getRemoteConfig();

    const railsInString = getString(remoteConfig, REMOTE_CONFIG_RAILS_KEY)
    let railsHeaderRcConfig: RailHeaderData | undefined = undefined

    if (railsInString && railsInString.length > 0) {
        railsHeaderRcConfig = JSON.parse(railsInString)
    }
    
    return railsHeaderRcConfig; 
  }
  
  
  