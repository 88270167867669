import {useCallback, useState} from "react";
import {SaveCurrentLocationUseCase} from "@domain/usecases/search/SaveCurrentLocationUseCase";
import {LocationDomain} from "@domain/models/search/SugestionType";


export interface SaveCurrentLocationViewState {
    isLoading?: boolean;
    error?: string | null;
}

export const useSaveCurrentLocationViewModel = (saveCurrentLocationUseCase: SaveCurrentLocationUseCase) => {
    const [stateSaveCurrentLocation, setStateSaveCurrentLocation] = useState<SaveCurrentLocationViewState>({isLoading: false});

    const saveCurrentLocation = useCallback((isFromCities: boolean , locationDomain: LocationDomain) => {
        setStateSaveCurrentLocation({isLoading: true});
        locationDomain.isFromCities = isFromCities
        saveCurrentLocationUseCase.execute(locationDomain)
        setStateSaveCurrentLocation({isLoading: false, error: null});

    }, [saveCurrentLocationUseCase]);

    return {
        stateSaveCurrentLocation: stateSaveCurrentLocation,
        saveCurrentLocation: saveCurrentLocation
    };
};