import RailGroup from "@presentation/views/components/RailGroup/RailGroup";

import AboutUs from "@presentation/views/components/AboutUs/AboutUs";
import React from "react";
import {SearchContainer} from "@presentation/views/components/Search/SearchContainer/SearchContainer";
import SearchViewModel from "@viewmodels/SearchViewModel";
import SearchMobile from "@presentation/views/components/Search/SearchExperience/SearchMobile/SearchMobile";
import {useMediaQuery} from "@mui/material";

export interface HomeProps {
    searchViewModel: SearchViewModel;
}

export function Home({searchViewModel}: HomeProps): React.ReactElement {

    const isTabletOrMobile = useMediaQuery('(max-width: 846px)'); // Matches the tablet breakpoint

    return (
        <>
            {/*Todo only for mobile*/}
            { isTabletOrMobile? (<SearchMobile/>) : null }

            <SearchContainer searchViewModel={searchViewModel}/>
            <RailGroup/>
            <AboutUs/>



        </>
    );
}
