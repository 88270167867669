import React, {useRef, useState} from "react";
import {ListGroup, Modal, Nav, NavDropdown} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import FirebaseApp from "../../../../../firebaseApp";
import R from "@strings/R";
import "./AuthNavigationSignedIn.scss"
import {useMediaQuery} from "@mui/material";
import BottomSheetModalCustom, {
    BottomSheetModalCustomHandles
} from "@presentation/views/components/BottomSheetModal/BottomSheetModalCustom";
import PostInfoRow from "@presentation/views/components/Search/SearchExperience/PostInfoRow/PostInfoRow";
import {useNavigate} from "react-router-dom";

interface AuthNavigationSignedInProps {
    onSignOut(): void;
}

type NavItem = {
    id: string;
    path: string;
    label: string;
};


const AuthNavigationSignedIn = ({onSignOut}: AuthNavigationSignedInProps) => {
    const navigate = useNavigate()

    const {t} = useTranslation();
    const isTabletOrMobile = useMediaQuery('(max-width: 846px)');

    const modalRef = useRef<BottomSheetModalCustomHandles>(null);

    const navDropdownMenu: Array<NavItem> = [
        {id: "posts", path: "/posts", label: "My posts"},
        {id: "details", path: "/details", label: "My Details"},
        {id: "favorites", path: "/favorites", label: "My favourites"},
        {id: "create_post", path: "/posts/create", label: t(R.createPostAction)},
        {id: "save_action", path: "/saved", label: t(R.savedPostAction)},
    ];

    const onItemClicked = (navItem: NavItem) => () => {
        navigate(navItem.path)
        modalRef.current?.close()
    };

    return (
        <>
            {isTabletOrMobile ? (
                <>

                    <BottomSheetModalCustom
                        ref={modalRef} // Attach the ref
                        headerContent={
                            <div className="position-end nav-dropdown-desktop nav-item dropdown">
                                <a id="basic-nav-dropdown"
                                   aria-expanded="false"
                                   role="button"
                                   className="dropdown-toggle nav-link"
                                   href="#">Account</a>
                            </div>
                        }>

                        <ListGroup className="search-result group">
                            {navDropdownMenu.map((navItem, index) => (
                                <ListGroup.Item key={index} onClick={onItemClicked(navItem)}>
                                    {navItem.label}
                                </ListGroup.Item>))
                            }
                            <ListGroup.Item key={100} onClick={onSignOut}>
                                {t(R.auth_sign_out)}
                            </ListGroup.Item>
                        </ListGroup>

                    </BottomSheetModalCustom>


                </>
            ) : (
                <NavDropdown
                    title={"Account"}
                    id="basic-nav-dropdown"
                    className="position-end nav-dropdown-desktop"
                >
                    <NavDropdown.ItemText>
                        {FirebaseApp?.getInstance()?.getFirebaseAuth()?.currentUser?.email}
                    </NavDropdown.ItemText>
                    {navDropdownMenu.map((navItem) => (
                        <NavDropdown.Item href={navItem.path} key={navItem.id}>
                            {navItem.label}
                        </NavDropdown.Item>
                    ))}
                    <Nav.Link href="/posts/create"> {t(R.createPostAction)} </Nav.Link>
                    <Nav.Link href="/saved">{t(R.savedPostAction)}</Nav.Link>
                    <NavDropdown.Item onClick={onSignOut}>Sign Out</NavDropdown.Item>
                </NavDropdown>
            )}
        </>
    );
};


export default AuthNavigationSignedIn;
