import { getRemoteConfig, getString } from "firebase/remote-config";
import {REMOTE_CONFIG_PRICE_IMAGES_KEY, REMOTE_CONFIG_PRIVACY_POLICY_CONTENT_KEY} from "./RemoteConfigKeys";
import {Images} from "@domain/models/images/ImagesConfig";

export default function getImagesConfig(): Images | undefined  {

    const remoteConfig = getRemoteConfig();
  
    const imagesString = getString(remoteConfig, REMOTE_CONFIG_PRICE_IMAGES_KEY)

    let images: Images | undefined = undefined

    if (imagesString ) {
        images = JSON.parse(imagesString)
    }

    return images;
    
  }
  