export const POST_MOST_VIEW_KEY = "POST_MOST_VIEW_ID";

export const POSTS_API_ID_KEY = "POSTS_API_ID";



export const RAIL_KEY = "RAIL_ID";

export const USER_ACCOUNT_KEY = "USER_ACCOUNT_ID";


export const FIREBASE_TOKEN_ID_KEY = "FIREBASE_TOKEN_ID";

export const AUTH_TOKEN_API = "AUTH_TOKEN_API_ID";

export const USER_INFO_API_ID = "USER_INFO_API_ID"

export const SEARCH_POSTS_API_ID = "SEARCH_POSTS_API_ID"

export const SEARCHES_API_ID = "SEARCHES_API_ID"

export const POST_RECOMMENDATIONS_API_ID = "POST_RECOMMENDATIONS_API_ID"

export const FAVORITE_POSTS_API_ID = "FAVORITE_POSTS_API_ID"

export const PLACES_API_ID = "SEARCH_CITIES"


