import React, {useEffect, useState} from 'react';
import {ListGroup} from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import R from "@strings/R";
import "./RecentSearch.scss"
import {PostData} from "@domain/models/post/PostResponseV1";
import clear from "@assets/clear.svg"
import {useGetPostsFromHistoryViewModel} from "@root/framework/hooks/useGetPostsFromHistoryViewModel";
import {useInjection} from "@root/ioc.react";
import {IProvider} from "@di/viewmodels/ViewModelProvider";
import {GetPostsInHistoryUseCase} from "@domain/usecases/search/GetPostsInHistoryUseCase";
import TYPES from "@di/viewmodels/ViewModelTypes";
import {useDeletePostFromHistoryViewModel} from "@root/framework/hooks/useDeletePostFromHistory";
import {DeletePostFromHistoryUseCase} from "@domain/usecases/search/DeletePostFromHistoryUseCase";
import EmptySearchResult
    from "@presentation/views/components/Search/SearchExperience/EmptySearchResult/EmptySearchResult";
import PostInfoRow from "@presentation/views/components/Search/SearchExperience/PostInfoRow/PostInfoRow";


export interface RecentSearchProps {
    onRecentSearchClick: (postData: PostData) => void
}

const RecentSearch: React.FC<RecentSearchProps> = ({onRecentSearchClick}) => {
    const {t} = useTranslation();
    const getPostsInHistoryUseCase = useInjection<IProvider<GetPostsInHistoryUseCase>>(TYPES.GetPostsInHistoryUseCase).provide()
    const deletePostsInHistoryUseCase = useInjection<IProvider<DeletePostFromHistoryUseCase>>(TYPES.DeletePostFromHistoryUseCase).provide()

    const {
        statePostHistory,
        getPostFromHistory,
        setStatePostHistory
    } = useGetPostsFromHistoryViewModel(getPostsInHistoryUseCase);
    const {
        stateDeletePostHistory,
        deletePostInHistory
    } = useDeletePostFromHistoryViewModel(deletePostsInHistoryUseCase);

    useEffect(() => {
        // Fetch posts from history when component mounts
        getPostFromHistory();
    }, [getPostFromHistory]);

    function onClickListGroup(e: React.MouseEvent, postData: PostData) {
        e.stopPropagation();
        deletePostInHistory(postData);

        // Update the local state to remove the deleted post
        setStatePostHistory(prevState => ({
            ...prevState,
            postResultData: prevState.postResultData?.filter(post => post !== postData)
        }));
    }

    return (
        <div className="recent-search main">
            <p className="recent-search title">{t(R.searchResults_recent_search_title)}</p>
            {statePostHistory?.postResultData && statePostHistory?.postResultData?.length > 0 ?
                (<ListGroup   >
                    {statePostHistory.postResultData?.slice(0, 4).map((recentSearch, index) => (
                        <div className="recent-search list-row">
                            <div className="recent-search list-item-text">
                                <ListGroup.Item onClick={() => onRecentSearchClick(recentSearch)}
                                                className="recent-search list-group-item" key={index}>
                                    <PostInfoRow postData={recentSearch} />
                                </ListGroup.Item>
                            </div>
                            <img onClick={(e: React.MouseEvent) => onClickListGroup(e, recentSearch)}
                                 className="recent-search clear-icon" src={clear} alt="clear-icon"/>
                        </div>
                    ))}
                </ListGroup>) : <EmptySearchResult message={t(R.searchExperience_recent_results_no_results)}/>
            }
        </div>)
};

export default RecentSearch;