import { Startup } from "@domain/models/startup/Startup";
import { getRemoteConfig, getString } from "firebase/remote-config";
import { STARTUP_CONFIG_KEY } from "./RemoteConfigKeys";
 
export default function getStartupConfig(): Startup | undefined {

  const remoteConfig = getRemoteConfig();

  const startupString = getString(remoteConfig, STARTUP_CONFIG_KEY)
  let startupModel: Startup | undefined = undefined


  if (startupString && startupString.length > 0) {
    startupModel = JSON.parse(startupString)
  }

  return startupModel

}