import { PostData } from "@domain/models/post/PostResponseV1";
import React from "react";
import { Modal, Carousel } from "react-bootstrap";
import "./Gallery.scss";
import camera from "@assets/camera.svg";

interface GalleryProps {
  showGallery: boolean;
  callback(show: boolean): void;
  post: PostData;
}

export const Gallery: React.FC<GalleryProps> = ({
  showGallery,
  post,
  callback,
}: GalleryProps): JSX.Element => {

  return (
    <>
      <Modal show={showGallery} onHide={() => { callback(false) }} style={{left: "0"}}>
        <Modal.Header closeButton>
          {/* <Modal.Title>{post.title}</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Carousel 
            slide={false}
            interval={null}
            indicators={false}
            nextIcon={
              post.images.length > 1 && (
                <span
                  aria-hidden="true"
                  className="gallery-control-next-icon"
                />
              )
            }
            prevIcon={
              post.images.length > 1 && (
                <span
                  aria-hidden="true"
                  className="gallery-control-prev-icon"
                />
              )
            }
          >
            {post.images.map((image, index) => {
              return (
                <Carousel.Item>
                  <img className="d-block m-2 mx-auto" style={{width: "967px", height: "625px", objectFit: "cover" }} src={image.medium} role="button"/>

                  <div className="item-multimedia-gallery">
                    <img src={camera} className="camera-style" />
                    <span>
                      {index + 1}/{post.images.length}
                    </span>
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </Modal.Body>
      </Modal>
    </>
  );
};
