import React from 'react';
import {Button} from 'react-bootstrap';
import heart from "@assets/ic_heart_.svg";
import "./EmptyListMessage.scss"
import {useTranslation} from "react-i18next";
import R from "@strings/R";


interface EmptyListMessageProps {
    onClickEmpty: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const EmptyListMessage: React.FC<EmptyListMessageProps> = ({ onClickEmpty }) => {

    const { t } = useTranslation();

    return (
        <div className="empty_message_list container">
            <img src={heart} alt="empty_icon"/>
            <p className="empty_message_list message">{t(R.global_no_matches_found)}<br/>{t(R.global_please_try_again)}
            </p>
            <Button onClick={onClickEmpty} variant="outline-primary" className="empty_message_list button">{t(R.global_reset_filter_action)}</Button>
        </div>
    );
};


export default EmptyListMessage;