import { getRemoteConfig, getString } from "firebase/remote-config";
import { STARTUP_CONFIG_KEY } from "./RemoteConfigKeys";

const DEFAULT_CURRENCY = "AED";
const MIN_CURRENCY_FALLBACK = 1;
const MAX_CURRENCY_FALLBACK = 1000000;

export default function getCurrencyConfig(): Record<string, number> {
    const remoteConfig = getRemoteConfig();
    const startupConfig = getString(remoteConfig, STARTUP_CONFIG_KEY);
    const parsedConfig = JSON.parse(startupConfig);
    let currencyObj = {
        minValue: MIN_CURRENCY_FALLBACK,
        maxValue: MAX_CURRENCY_FALLBACK,
    };

    if (parsedConfig) {
        const currency = parsedConfig?.localization?.defaultCurrencies?.find(
            (c: Record<string, any>) => c.currencyIso === DEFAULT_CURRENCY
        );

        if (currency?.minValue) {
            currencyObj.minValue = currency.minValue;
        }

        if (currency?.maxValue) {
            currencyObj.maxValue = currency.maxValue;
        }
    }

    return currencyObj;
}
