import SearchViewModel from "@viewmodels/SearchViewModel";
import {observer} from "mobx-react";
import React, {useCallback, useEffect, useState} from "react"
import {FormControl, Row} from "react-bootstrap"
import {useLocation, useNavigate} from "react-router-dom";
import "./SearchLocationInput.scss"
import SearchSuggestionsList from "@presentation/views/components/Search/SearchSuggestionsList/SearchSuggestionsList";
import {debounce} from "@mui/material";
import {MIN_SEARCH_DEBOUNCE_TIME_MS} from "@utils/Constants";
import NavigationUtils from "@utils/NavigationUtils";
import UrlUtils from "@utils/UrlUtils";
import {SearchKeys} from "@enums/SearchKeys";
import FilterRequestBuilder from "@utils/filter/FilterRequestBuilder";


export interface SearchSuggestionInputProps {
    searchViewModel: SearchViewModel,
}

const SearchLocationInput: React.FC<SearchSuggestionInputProps> = observer(({searchViewModel}: SearchSuggestionInputProps): JSX.Element => {

    const [searchTerm, setSearchTerm] = useState("");
    const inputSearchLocationField = React.useRef<HTMLInputElement>(null)
    const navigate = useNavigate()
    const [showSuggestions, setShowSuggestions] = useState(false);
    const location = useLocation();

    const handleSuggestionReset = () => {
        setSearchTerm('');
    };

    const sendRequest = (query: string) => {
        searchViewModel.handleLocationChange(query);
    };

    // Debounce sendRequest with 500ms delay
    const debouncedSendRequest = useCallback(
        debounce((query) => sendRequest(query), MIN_SEARCH_DEBOUNCE_TIME_MS),
        []
    );
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const query = e.target.value;
        setSearchTerm(query)
        debouncedSendRequest(query);
        if (!showSuggestions) {
            setShowSuggestions(true)
        }
    };

    useEffect(() => {
        const paramsMap = UrlUtils.getParamsMap(location.search)
        const initialValue = FilterRequestBuilder.getInstance().getInitialValue(paramsMap, SearchKeys.SEARCH_LOCATION)

        if (initialValue) {
            setSearchTerm(initialValue)
        }
        const handleClickOutside = (event: MouseEvent) => {
            if (inputSearchLocationField.current && !inputSearchLocationField.current.contains(event.target as Node)) {
                setShowSuggestions(false);
            }
        };
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [location])

    const handleSuggestionClick = (location: any) => {
        setShowSuggestions(false)
        if (location) {

            inputSearchLocationField!!.current!!.value = location.name
            setSearchTerm(inputSearchLocationField!!.current!!.value)

            const updatedSearchParams = NavigationUtils.buildSearchParams({
                [SearchKeys.LAT_SEARCH_KEY]: location.lat,
                [SearchKeys.LON_SEARCH_KEY]: location.lon,
                [SearchKeys.PLACE_SEARCH_KEY]: location.name,
            });

            navigate(updatedSearchParams, {
                    replace: true
                }
            );
        }

    }


    return <>
        <div style={{position: "relative", width: "-webkit-fill-available"}} className="ml-auto w-100 h-100 ">
            <FormControl aria-describedby="basic-addon1"
                         placeholder="Dubai | Abu Dhabi.."
                         type="search"
                         className="p-2"
                         ref={inputSearchLocationField}
                         value={searchTerm}
                         onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                             handleSearchChange(e);
                         }}
            />

            {
                searchTerm !== "" && (<button onClick={handleSuggestionReset} type="button"
                                              style={{position: "absolute", top: 2, right: 0}}
                                              className="btn bg-transparent">
                    <i className="bi bi-x-lg"></i>
                </button>)
            }
        </div>


        {showSuggestions && (
            <SearchSuggestionsList
                location={searchViewModel.getLocationData!!}
                onClick={handleSuggestionClick}
            />

        )}
    </>
})

export default SearchLocationInput