import RailViewModel from "framework/viewmodels/RailViewModel";

import React from "react";
import Rail from "../Rail/Rail";

import { useInjection } from "../../../../../ioc.react";
import { IProvider } from "@di/viewmodels/ViewModelProvider";
import TYPES from "@di/viewmodels/ViewModelTypes";
import getRailConfig from "@utils/remote_config/RailGroupRemoteConfig";
import ContentBox from "../ContentBox/ContentBox";

const RailGroup: React.FC = (): JSX.Element => {
    const railViewModelProvider = useInjection<IProvider<RailViewModel>>(
        TYPES.RailViewModel
    );

    return (
        <ContentBox>
            <div className="rail-group">
                {getRailConfig()?.rails?.map((rail) => {
                    return (
                        <Rail
                            key={rail.railId}
                            railData={{
                                rail_id: rail.railId,
                                railTitle: rail.railTitle,
                                posts: undefined,
                            }}
                            railViewModel={railViewModelProvider.provide()}
                        />
                    );
                })}
            </div>
        </ContentBox>
    );
};

export default RailGroup;
