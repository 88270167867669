import getPrivacyRemoteConfig from "@utils/remote_config/PrivacyRemoteConfig";
import React from "react";
import { Row } from "react-bootstrap";
const HtmlToReactParser = require('html-to-react').Parser;

const Privacy: React.FC = (): JSX.Element => {
    let privacyContent = getPrivacyRemoteConfig() 

      
    const htmlToReactParser = new HtmlToReactParser();
 
    const reactElement = htmlToReactParser.parse(privacyContent);

    return <Row style={{ overflow: 'auto', maxHeight: '700px' }}>
             { reactElement }
           </Row>
}

export default Privacy; 