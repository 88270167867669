import {ImageV1} from "@domain/models/post/PostCreateV1";
import {getStorage, ref, updateMetadata, uploadBytes, getDownloadURL} from "firebase/storage";
import {v4 as uuidv4} from 'uuid';
import {compressAccurately, EImageType} from 'image-conversion';
import {
    INVENTORY_IMAGE_FOLDER_PROD,
    INVENTORY_IMAGE_FOLDER_TEST,
    MEDIUM_RESOLUTION, SMALL_RESOLUTION,
    THUMBNAIL_RESOLUTION
} from "@utils/Constants";

class ImageUploader {


    static async uploadImages(images: Blob[]): Promise<ImageV1[]> {
        const storage = getStorage();
        const imageUrls: ImageV1[] = [];
        console.log('uploading image')

        await Promise.all(
            images.map(async (image) => {
                const inventoryImageFolder = process.env.REACT_APP_BASE_URL?.includes('test') ? INVENTORY_IMAGE_FOLDER_TEST : INVENTORY_IMAGE_FOLDER_PROD

                const imageId = uuidv4()
                const storageRef = ref(storage, inventoryImageFolder + '/' + imageId); // You can generate a unique filename here.

                const jpegImage = await compressAccurately(image, {
                    size: 90, // Specify the desired compressed size in Kb
                    accuracy: 0.9, // Set the desired accuracy
                    type: EImageType.JPEG, // Specify the desired image type
                    scale: 1.0, // Set the scale factor
                    orientation: 1, // Set the image orientation
                });

                try {
                    const snapshot = await uploadBytes(storageRef, jpegImage);
                    const downloadURL = await getDownloadURL(ref(storage, snapshot.metadata.fullPath));

                    console.log('Uploaded a blob or file!', snapshot.metadata);

                    console.log('IMAGE!',  this.modifyDownloadURL(inventoryImageFolder, downloadURL, SMALL_RESOLUTION));

                    // Add the uploaded image URL to your array
                    imageUrls.push({
                        id: imageId, // You can set the actual ID if needed
                        medium:  this.modifyDownloadURL(inventoryImageFolder, downloadURL, MEDIUM_RESOLUTION),
                        small:  this.modifyDownloadURL(inventoryImageFolder, downloadURL, SMALL_RESOLUTION),
                        thumbnail:  this.modifyDownloadURL(inventoryImageFolder, downloadURL, THUMBNAIL_RESOLUTION),
                    });
                } catch (error) {
                    console.error('Error uploading image:', error);
                }
            })
        );

        return imageUrls;
    };

    static modifyDownloadURL(pathFolder: string, originalURL: string, resolution: string) {
        const pathToAdd = 'thumbs%2F';
        const resolutionSuffix = `_${resolution}`; // Adjust the resolution suffix as needed
        const url = new URL(originalURL);
        url.pathname = url.pathname.replace(`/o/${pathFolder}%2F`, `/o/${pathFolder}%2F${pathToAdd}`);
        url.pathname += resolutionSuffix;
        return url.toString();
    }

}

export default ImageUploader;
