import { CategoryState } from "@domain/models/state/CategorieState";
import DataState from "@domain/models/state/DataState";
import GetTopCategoriesUseCase from "@domain/usecases/categories/GetTopCategoriesUseCase";
import { action, makeObservable, observable } from "mobx";

class CategoriesViewModel {
    public isLoadingRequest: boolean = false;

    public error: Error | undefined
    public stateLocation: DataState<CategoryState> = new DataState({ topCategories: undefined})

     public getTopCategories: GetTopCategoriesUseCase
     
    constructor(getTopCategories: GetTopCategoriesUseCase) {
        this.getTopCategories = getTopCategories
        makeObservable(this, {
            isLoadingRequest: observable,
            stateLocation: observable,
            error: observable,
            getTopCagories: action
        })
    }

    getTopCategoriesData() {
        return this.stateLocation.content.topCategories
    }

 

    getTopCagories(){
        this.isLoadingRequest = true
        this.getTopCategories.getTopCategories()
            .then(response => { 
                this.stateLocation = new DataState({ topCategories: response})
                this.isLoadingRequest = false
                console.log("response in cate  " + response)
            }).catch(error => {
                this.error = error
                this.isLoadingRequest = false
            })
    }
}


export default CategoriesViewModel