import React from "react";
import { Col, Row } from "react-bootstrap";
import AccessApp from "../AccessApp/AccessApp";
import RecentReviews from "../RecentReviews/RecentReviews";
import ContentBox from "../ContentBox/ContentBox";

const AboutUs: React.FC = (): JSX.Element => {
    return (
        <section className="about-us-section">
            <ContentBox>
                <Row className="justify-content-center">
                    <Col md={6}>
                        <AccessApp />
                    </Col>
                    <Col md={6}>
                        <RecentReviews />
                    </Col>
                </Row>
            </ContentBox>
        </section>
    );
};

export default AboutUs;
