import AttrbIcons from "@domain/models/catalog/AttrbIcons";
import { getRemoteConfig, getString } from "firebase/remote-config";
import { REMOTE_CONFIG_ATTRB_ICONS_CONFIG_KEY } from "./RemoteConfigKeys";

export default function getAttrbIconsMap(): AttrbIcons | undefined {

  const remoteConfig = getRemoteConfig();

  const attrbIconsString = getString(remoteConfig, REMOTE_CONFIG_ATTRB_ICONS_CONFIG_KEY)
  let attrbIconsConfig: AttrbIcons | undefined = undefined


  if (attrbIconsString && attrbIconsString.length > 0) {
    attrbIconsConfig = JSON.parse(attrbIconsString)
  }


  return attrbIconsConfig

}