import {SavedSearch, SavedSearchResult} from "@domain/models/search/SavedSearch";
import ISearchRepository from "@domain/repository/search/ISearchRepository";

export default class GetMySavedSearchesUseCase {


    private searchRepository: ISearchRepository

    public constructor(searchRepository: ISearchRepository) {
        this.searchRepository = searchRepository
    }



    /**
     * Get list of my saved searches
     * @throws {Error} in case of getting non 2xx response or empty query
     */
    public async getMySavedSearches(): Promise<SavedSearchResult> {
        return new Promise(async (resolve, reject) => {
            await this.searchRepository.getMySavedSearches().then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    }
}