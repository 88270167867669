import React, {useState} from "react";
import { Col, Row } from "react-bootstrap";
import SearchViewModel from "@viewmodels/SearchViewModel";
import getImagesConfig from "@utils/remote_config/ImagesConfig";
import SearchText from "@presentation/views/components/Search/SearchText";
import SearchBarHomePage from "@presentation/views/components/Search/SearchBarHomePage/SearchBarHomePage";
import ContentBox from "../../ContentBox/ContentBox";
import logo from "@assets/logo_bg.svg";

import "./SearchContainer.scss";
import SearchExperienceContainer
    from "@presentation/views/components/Search/SearchExperience/SearchExperienceContainer/SearchExperienceContainer";

export interface SearchComponentProps {
    searchViewModel: SearchViewModel;
}

export function SearchContainer({ searchViewModel }: SearchComponentProps) {
    let landingImage = getImagesConfig()?.landingImage;


    const onSearchInputClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
    };

    return (
        <div className="search-container">
            <div
                className="search-container__gradient"
                style={{
                    background:
                        'linear-gradient(to bottom, rgba(0, 26, 26, 0.5) 0%, rgba(0, 26, 26, 0.5) 100%), url("' +
                        landingImage +
                        '")',
                    backgroundRepeat: "round"
                }}
            ></div>

            <div className="search-container__content">
                <ContentBox>
                    <div>
                        <Row>
                            <Col xl={4}>
                                <SearchText />
                            </Col>
                            <Col xl={8}  onClick={onSearchInputClick}>
                                <SearchBarHomePage />

                            </Col>
                        </Row>
                    </div>
                </ContentBox>
            </div>
        </div>
    );
}
