import React from "react";
import "./ContentBox.scss";

interface ContentBoxProps {
    children: React.ReactNode;
}

const ContentBox: React.FC<ContentBoxProps> = ({ children }) => {
    return (
        <div className="content-box">
            <div className="content-box__inner">{children}</div>
        </div>
    );
};

export default ContentBox;
