import React from 'react';
import {useTranslation} from "react-i18next";
import R from "@strings/R";
import "./NotFound.scss"

import NavigationUtils, {NavigationParams} from "@utils/NavigationUtils";
import {Path} from "@enums/Path";
import {useNavigate} from "react-router-dom";
import SearchBar from "@presentation/views/components/Search/SearchBarResultsPage";

export function NotFound(): React.ReactElement {
    const {t} = useTranslation()
    const navigate = useNavigate()

    function updateQueryParam(body: any) {
        const newParams: NavigationParams = body
        const updatedSearchParams = NavigationUtils.buildSearchParams(newParams);
        navigate(Path.SEARCH + "/" + updatedSearchParams);
    }

    return (
        <>
            <SearchBar updateQueryParam={updateQueryParam}/>
            <div className="not-found-container">
                <div className="not-found-content">
                    <p className="not-found-title">{t(R.pageNotFound_404_title)}</p>
                    <p className="not-found-message">{t(R.pageNotFound_404_message)}</p>
                    <a href="/" className="not-found-button">{t(R.goToHomePageAction)}</a>
                </div>
            </div>
        </>
    );
}
