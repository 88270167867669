import { PostData } from "@domain/models/post/PostResponseV1"
import IPostRepository from "@domain/repository/post/IPostRepository"

export default class GetPostDetailsUseCase {

    private postRepository: IPostRepository

    public constructor(postRepository: IPostRepository) {
        this.postRepository = postRepository
    }


    /**
     * Get post details
     * @param postId Id of post. 
     * @throws {Error} in case of getting non 2xx response or empty query 
     */
    public async getPostDetails(postId: string): Promise<PostData> {
        return new Promise(async (resolve, reject) => {
            await this.postRepository.getPostDetails(postId).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    }
}