import React from 'react';
import {useTranslation} from "react-i18next";
import { InputGroup } from 'react-bootstrap';
import R from "@strings/R";
import './SearchInputGroup.scss';
import searchIcon from "@assets/search_icon.svg";

interface SearchInputGroupProps {
    onSearchInputClick: (event: React.MouseEvent<HTMLElement>) => void;
 }

export function SearchInputGroup({ onSearchInputClick }: SearchInputGroupProps): React.ReactElement {
    const { t } = useTranslation();

    return (
        <InputGroup className="gx-0 search-input-group__input-group-wrapper" onClick={onSearchInputClick}>
            <div className="search-input-group__input-wrapper">
                <p className="search-bar__input-text">{t(R.landingPage_search_hint)}</p>
                <img src={searchIcon} className="search-input-group search-icon" alt="search-icon" />
            </div>
        </InputGroup>
    );
}
