import { UserV1 } from "@domain/models/user/UserV1";
import IUserRepository from "@domain/repository/user/IUserRepository";

export default class GetUserAccountUseCase {
    private userRepository: IUserRepository

    public constructor(userRepository: IUserRepository) {
        this.userRepository = userRepository
    }

    /**
       * Get public user account info from a particular user
       * @param userId
       * @throws {Error} in case of getting non 2xx response or empty query 
       */
    public async getOtherUserAccount(userId: number): Promise<UserV1> {
        return new Promise(async (resolve, reject) => {
            await this.userRepository.getOtherUserAccount(userId).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    }
    /**
     * Get public user account info (from current user)
     * @throws {Error} in case of getting non 2xx response or empty query
     */
    public async getMyUserAccount(): Promise<UserV1> {
        return new Promise(async (resolve, reject) => {
            await this.userRepository.getMyUserAccount().then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    }
}