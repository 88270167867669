import React, { useState} from "react";

import SearchParagraph from "../SearchParagraph/SearchParagraph";
import SearchExperienceContainer from "@presentation/views/components/Search/SearchExperience/SearchExperienceContainer/SearchExperienceContainer";
import NavigationUtils, {NavigationParams} from "@utils/NavigationUtils";
import {useNavigate} from "react-router-dom";
import {Path} from "@enums/Path";
import {SearchInputGroup} from "@presentation/views/components/SearchInputGroup/SearchInputGroup";
import "./SearchBarHomePage.scss";

export interface SearchComponentProps {
    //Do nothing
}

const SearchBarHomePage: React.FC<SearchComponentProps> =
    ({}: SearchComponentProps): JSX.Element => {

        const navigate = useNavigate()

        const [showSearchExperience, setShowSearchExperience] = useState(false)
        const onHideSearchExperience = () => {
            setShowSearchExperience(false)
        }

        const onSearchInputClick = (event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            setShowSearchExperience(true);
        };

        function updateQueryParam(body: any) {
            const newParams: NavigationParams = body
            const updatedSearchParams = NavigationUtils.buildSearchParams(newParams);
            navigate(Path.SEARCH + "/" + updatedSearchParams);
        }

        return (
            <div className="search-bar" >
                <p className="search-bar__label">
                    <SearchParagraph/>
                </p>

                <div className={"search-bar__search-experience-container-main"}>
                    <SearchExperienceContainer
                        updateQueryParam={updateQueryParam}
                        show={showSearchExperience}
                        onHide={onHideSearchExperience}/>
                </div>

                <SearchInputGroup onSearchInputClick={onSearchInputClick}/>
            </div>
        );
    }


export default SearchBarHomePage;
