import R from "@strings/R";
import getTermOfService from "@utils/remote_config/TermService";
import React from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
const HtmlToReactParser = require('html-to-react').Parser;

const How: React.FC = (): JSX.Element => {
    const {t} = useTranslation()
    let termOfServiceContent = getTermOfService()


    const htmlToReactParser = new HtmlToReactParser();

    const reactElement = htmlToReactParser.parse(termOfServiceContent);

    return <Accordion className="m-5   accordion-flush" defaultActiveKey="0" flush>
        <Accordion.Item eventKey="0">
            <Accordion.Header >{t(R.howItWorks_first_item_title)}</Accordion.Header>
            <Accordion.Body>
               {t(R.howItWorks_first_item_description)}
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
            <Accordion.Header>{t(R.howItWorks_second_item_title)}</Accordion.Header>
            <Accordion.Body>
                {t(R.howItWorks_second_item_description)}
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
            <Accordion.Header>{t(R.howItWorks_third_item_title)}</Accordion.Header>
            <Accordion.Body>
                {t(R.howItWorks_third_item_description)}
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
            <Accordion.Header>{t(R.howItWorks_four_item_title)}</Accordion.Header>
            <Accordion.Body>
                {t(R.howItWorks_four_item_description)}
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
            <Accordion.Header>{t(R.howItWorks_five_item_title)}</Accordion.Header>
            <Accordion.Body>
                {t(R.howItWorks_five_item_description)}
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
}

export default How; 