export const STARTUP_CONFIG_KEY = "startup_config";

export const STARTUP_BASE_URL_KEY = "startup_urls";

export const REMOTE_CONFIG_CATALOG_KEY = "catalog_structure";

export const REMOTE_CONFIG_RAILS_KEY = "rails";

export const REMOTE_CONFIG_FOOTER_EXTRA_INFO_KEY = "extra_info";

export const REMOTE_CONFIG_FOOTER_SOCIAL_NETWORKS_KEY = "social_networks";

export const REMOTE_CONFIG_PRIVACY_POLICY_CONTENT_KEY = "privacy_content";

export const REMOTE_CONFIG_TERM_OF_SERVICE_CONTENT_KEY = "terms_service_content";

export const REMOTE_CONFIG_PRICE_CONFIG_KEY = "cost_config";

export const REMOTE_CONFIG_PRICE_IMAGES_KEY = "images";

export const REMOTE_CONFIG_ATTRB_ICONS_CONFIG_KEY = "attribute_icons";

export const NAV_LINK_MENU_CATALOG_KEY = "navLinks";