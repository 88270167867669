import {CatalogModel, Value} from "@domain/models/catalog/CatalogModel";
import { getRemoteConfig, getString } from "firebase/remote-config";
import {  REMOTE_CONFIG_CATALOG_KEY } from "./RemoteConfigKeys";

export default function getCatalogConfig(): CatalogModel | undefined {

  const remoteConfig = getRemoteConfig();

  const catalogStructureInString = getString(remoteConfig, REMOTE_CONFIG_CATALOG_KEY)
  let catalogStructure: CatalogModel | undefined = undefined

  if (catalogStructureInString && catalogStructureInString.length > 0) {
    catalogStructure = JSON.parse(catalogStructureInString)
  }


  return catalogStructure;
}

