import { PostData } from "@domain/models/post/PostResponseV1"
import IPostRepository from "@domain/repository/post/IPostRepository"

export default class DeleteMyPostUseCase {

    private postRepository: IPostRepository

    public constructor(postRepository: IPostRepository) {
        this.postRepository = postRepository
    }


    /**
     * Delete a post
     * @param postId Id of post.
     * @throws {Error} in case of getting non 2xx response or empty query
     */
    public async deletePost(postId: number): Promise<Boolean> {
        return new Promise(async (resolve, reject) => {
            await this.postRepository.deletePost(postId).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    }
}