import React, {useEffect} from 'react';
import {ListGroup} from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import R from "@strings/R";
import {PostData} from "@domain/models/post/PostResponseV1";
import {useSearchRecommendationViewModel} from "@root/framework/hooks/useSearchRecommendationViewModel";
import {useInjection} from "@root/ioc.react";
import {IProvider} from "@di/viewmodels/ViewModelProvider";
import TYPES from "@di/viewmodels/ViewModelTypes";
import SearchRecommendationsUseCase from "@domain/usecases/search/SearchRecommendationsUseCase";
import "./Recommendations.scss"
import {CurrentLocationViewStateViewState} from "@root/framework/hooks/useGetCurrentLocationViewModel";
import PostInfoRow
    from "@presentation/views/components/Search/SearchExperience/PostInfoRow/PostInfoRow";

export interface RecommendationsProps {
    onRecommendationClick: (postData: PostData) => void,
    locationViewState?: CurrentLocationViewStateViewState
}

const Recommendations: React.FC<RecommendationsProps> = ({onRecommendationClick, locationViewState}) => {
    const {t} = useTranslation();

    const searchRecommendationsUseCase = useInjection<IProvider<SearchRecommendationsUseCase>>(TYPES.SearchRecommendationsUseCase).provide()
    const {
        stateRecommendationPosts,
        searchRecommendationPosts
    } = useSearchRecommendationViewModel(searchRecommendationsUseCase);

    useEffect(() => {
        //Get Post recommendations
        searchRecommendationPosts(locationViewState?.locationDomain)
    }, []);


    return <>
        <p className="recommendations title">{t(R.searchResults_recommendations_title)}</p>
        <ListGroup className="recommendations group">
            {
                stateRecommendationPosts?.postResultData?.data?.map((postData, index) => (
                        <ListGroup.Item key={index} onClick={() => onRecommendationClick(postData)}>
                            <PostInfoRow postData={postData}/>
                        </ListGroup.Item>
                    )
                )
            }
        </ListGroup>
    </>
}

export default Recommendations;